const DropdownArrowIconSvg = ({
	fill,
	className,
}: {
	fill: string;
	className?: string;
}) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M13.4623 5.30836C13.4244 5.21699 13.3604 5.13888 13.2782 5.08393C13.1959 5.02897 13.0993 4.99963 13.0004 4.99961H3.00039C2.90145 4.99953 2.8047 5.02881 2.7224 5.08375C2.64011 5.13868 2.57596 5.2168 2.53808 5.30821C2.50021 5.39962 2.4903 5.50022 2.50963 5.59726C2.52895 5.6943 2.57664 5.78343 2.64664 5.85336L7.64664 10.8534C7.69308 10.8998 7.74822 10.9367 7.80892 10.9619C7.86962 10.987 7.93469 11 8.00039 11C8.0661 11 8.13117 10.987 8.19186 10.9619C8.25256 10.9367 8.30771 10.8998 8.35414 10.8534L13.3541 5.85336C13.424 5.78339 13.4716 5.69427 13.4909 5.59726C13.5101 5.50025 13.5002 5.39971 13.4623 5.30836Z"
				fill={fill}
			/>
		</svg>
	);
};

export default DropdownArrowIconSvg;
