const DownloadIconSvg = ({
  fill,
  className,
}: {
  fill?: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.5 21.1067C3.83696 21.1067 3.20107 20.8433 2.73223 20.3745C2.26339 19.9057 2 19.2698 2 18.6067V15.0353C2 14.7511 2.11288 14.4786 2.31381 14.2777C2.51475 14.0768 2.78727 13.9639 3.07143 13.9639C3.35559 13.9639 3.62811 14.0768 3.82904 14.2777C4.02997 14.4786 4.14286 14.7511 4.14286 15.0353V18.6067C4.14286 18.8039 4.30286 18.9639 4.5 18.9639H19.5C19.5947 18.9639 19.6856 18.9262 19.7525 18.8593C19.8195 18.7923 19.8571 18.7014 19.8571 18.6067V15.0353C19.8571 14.7511 19.97 14.4786 20.171 14.2777C20.3719 14.0768 20.6444 13.9639 20.9286 13.9639C21.2127 13.9639 21.4853 14.0768 21.6862 14.2777C21.8871 14.4786 22 14.7511 22 15.0353V18.6067C22 19.2698 21.7366 19.9057 21.2678 20.3745C20.7989 20.8433 20.163 21.1067 19.5 21.1067H4.5Z"
        fill={fill}
      />
      <path
        d="M17.4007 10.7933C17.5002 10.6939 17.579 10.5758 17.6328 10.4459C17.6867 10.316 17.7143 10.1768 17.7143 10.0362C17.7143 9.89555 17.6867 9.75632 17.6328 9.62641C17.579 9.4965 17.5002 9.37845 17.4007 9.27903C17.3013 9.1796 17.1833 9.10072 17.0533 9.04691C16.9234 8.9931 16.7842 8.96541 16.6436 8.96541C16.503 8.96541 16.3637 8.9931 16.2338 9.04691C16.1039 9.10072 15.9859 9.1796 15.8864 9.27903L13.0722 12.0919V3.96474C13.0722 3.68058 12.9593 3.40806 12.7583 3.20713C12.5574 3.00619 12.2849 2.89331 12.0007 2.89331C11.7166 2.89331 11.444 3.00619 11.2431 3.20713C11.0422 3.40806 10.9293 3.68058 10.9293 3.96474V12.0919L8.11501 9.27903C8.01558 9.1796 7.89754 9.10072 7.76763 9.04691C7.63772 8.9931 7.49849 8.96541 7.35787 8.96541C7.21726 8.96541 7.07802 8.9931 6.94811 9.04691C6.8182 9.10072 6.70016 9.1796 6.60073 9.27903C6.5013 9.37845 6.42243 9.4965 6.36862 9.62641C6.31481 9.75632 6.28711 9.89555 6.28711 10.0362C6.28711 10.1768 6.31481 10.316 6.36862 10.4459C6.42243 10.5758 6.5013 10.6939 6.60073 10.7933L11.2436 15.4362C11.4445 15.6368 11.7168 15.7495 12.0007 15.7495C12.2847 15.7495 12.557 15.6368 12.7579 15.4362L17.4007 10.7933Z"
        fill={fill}
      />
    </svg>
  );
};

export default DownloadIconSvg;
