const SidebarSubmoduleIconSvg = ({
  fill = "#292d32",
  outline = "#292d32",
  className,
}: {
  fill?: string;
  outline?: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="12" cy="12" r="2.5" fill={outline} stroke={fill} />
    </svg>
  );
};

export default SidebarSubmoduleIconSvg;
