import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "src/lib/utils";

const labelVariants = cva(
	"peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
	{
		variants: {
			variant: {
				normal: "text-sm font-medium leading-none",
				form: "text-disabled-foreground text-[0.65rem] lg:text-sm tracking-tight",
				title: "text-start text-lg sm:text-xl lg:text-2xl",
				header: "text-2xl lg:text-4xl font-[500]",
				sub_header: "text-base lg:text-lg",
				description: "text-[0.65rem] lg:text-sm text-justify",
			},
		},
		defaultVariants: {
			variant: "normal",
		},
	}
);

const Label = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
		VariantProps<typeof labelVariants>
>(({ className, variant, ...props }, ref) => (
	<LabelPrimitive.Root
		ref={ref}
		className={cn(labelVariants({ variant }), className)}
		{...props}
	/>
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
