import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetSelectedStoreQuery } from "./slices/storeApiSlice";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Types
import { Store } from "./utils/types";
import { storeSchema } from "./utils/schema";

// Component
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import { Form, FormField } from "src/shared/components/form/Form";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import LongitudeSvg from "src/assets/svg/LongitudeSvg";
import LatitudeSvg from "src/assets/svg/LatitudeSvg";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import FormController from "src/shared/components/form/FormController";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import MapComponent from "src/shared/components/form-field/map/Map";
import Loading from "src/shared/components/loading-indicator/Loading";

const StoreInformationForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { storeId } = useParams<{ storeId: string }>();
	const { data: selectedStore, isFetching: isSelectedStoreFetching } =
		useGetSelectedStoreQuery({ id: Number(storeId) });

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

	// Initialize the form
	const form = useForm<Store>({
		resolver: zodResolver(storeSchema),
		defaultValues: {
			name: "",
			address: "",
			code: "",
			city: "",
			long: 0,
			lat: 0,
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const [searchTerm, setSearchTerm] = useState("");
	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		navigate("/store");

		form.reset();
	};

	const onCancelPress = () => {
		if (!isDirty) navigate("/store");

		setIsLeaveModalOpen(true);
	};

	const onSubmit = (values: Store) => {
		console.log({ values });
	};

	useEffect(() => {
		if (selectedStore) {
			form.reset({
				name: selectedStore.name,
				address: selectedStore.address,
				code: selectedStore.code,
				city: selectedStore.city,
				long: selectedStore.long,
				lat: selectedStore.lat,
			});
		}
	}, [selectedStore, form.reset]);

	// * Avoid loading/fetching state if the form is for adding
	if (isSelectedStoreFetching) {
		return <Loading />;
	}

	// * Reroute to UserPage/Table if id is invalid/no user
	if (!selectedStore && pathSegments[2] != "add") {
		navigate("/store", { replace: true });
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)}>
				<PageContainer>
					<FormHeader>
						<Label variant="title">New Store</Label>
						<div className="w-full h-fit sm:w-fit flex gap-2">
							<Button type="submit" disabled={!isDirty || !isValid}>
								<ButtonCheckIconSvg
									className="h-5 w-5"
									fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
								/>
								Save
							</Button>
							<Button
								variant="secondary"
								size="md"
								type="reset"
								onClick={onCancelPress}
							>
								Cancel
							</Button>
						</div>
					</FormHeader>

					<SectionContainer className="lg:grid-cols-3">
						<SectionHeader className="lg:col-span-3">
							<Label variant="header">Store Information</Label>
						</SectionHeader>
						<div className="lg:col-span-1">
							<FormField
								control={form.control}
								name="name"
								render={({ field }) => (
									<FormController label="Store Name">
										<Input {...field} />
									</FormController>
								)}
							/>
						</div>
						<div className="lg:col-span-2">
							<FormField
								control={form.control}
								name="address"
								render={({ field }) => (
									<FormController label="Detailed Address">
										<Input {...field} />
									</FormController>
								)}
							/>
						</div>

						<div className=" grid lg:col-span-1 lg:row-span-3">
							<MapComponent defaultCenter={{ lat: 14.22016, lng: 121.03616 }} />
						</div>
						<div>
							<FormField
								control={form.control}
								name="code"
								render={({ field }) => (
									<FormController label="Code">
										<Input {...field} />
									</FormController>
								)}
							/>
						</div>
						<div>
							<FormField
								control={form.control}
								name="city"
								render={({ field }) => (
									<FormController label="City/Municipality">
										<Input {...field} />
									</FormController>
								)}
							/>
						</div>
						<div>
							<div className="row items-center">
								<LongitudeSvg className="mr-2" fill={"#000"} />
								<label className="text-disabled-foreground text-[0.65rem] lg:text-sm tracking-tight">
									Longitude
								</label>
							</div>
							<FormField
								control={form.control}
								name="long"
								render={({ field }) => (
									<FormController label="">
										<Input {...field} type="number" step="any" />
									</FormController>
								)}
							/>
						</div>
						<div>
							<div className="row items-center">
								<LatitudeSvg className="mr-2" fill={"#000"} />
								<label className="text-disabled-foreground text-[0.65rem] lg:text-sm tracking-tight">
									Latitude
								</label>
							</div>
							<FormField
								control={form.control}
								name="lat"
								render={({ field }) => (
									<FormController label="">
										<Input {...field} type="number" step="any" />
									</FormController>
								)}
							/>
						</div>
					</SectionContainer>
				</PageContainer>
			</form>

			<ConfirmationDialog
				title="Leave page?"
				description="Changes are not yet saved."
				confirmButtonLabel="Leave"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>
		</Form>
	);
};
export default StoreInformationForm;
