import { LoaderCircle } from "lucide-react";

const ButtonLoading = () => {
	return (
		<>
			<LoaderCircle className="h-4 w-4 text-current animate-spin" />
			Please wait
		</>
	);
};

export default ButtonLoading;
