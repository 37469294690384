import { Outlet } from "react-router-dom";
import background from "src/assets/images/background.png";
import background2 from "src/assets/images/background2.png";

const AuthLayout = () => {
	return (
		<div className="flex flex-col min-h-screen h-screen relative bg-[#F4CC02]">
			<div className="h-full w-full max-w-[1080px] mx-auto overflow-hidden">
				<img
					src={background}
					alt="banner"
					className="w-full h-full object-cover lg:object-fill"
				/>
			</div>
			<div className="h-full w-full bg-upload overflow-hidden">
				<img
					src={background2}
					alt="banner"
					className="max-h-[600px] max-w-[1200px] h-full w-full mx-auto bg-contain bg-no-repeat"
				/>
			</div>
			<Outlet />
		</div>
	);
};

export default AuthLayout;
