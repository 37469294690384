const PlatformFacebookIconSvg = ({ className }: { className?: string }) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_1746_8748)">
				<path
					d="M27.9998 15.9258C27.9717 13.6249 27.2907 11.3799 26.037 9.45539C24.7833 7.53085 23.0091 6.00683 20.9234 5.06282C18.8377 4.11882 16.5273 3.79413 14.2644 4.12701C12.0014 4.45989 9.88011 5.43647 8.1503 6.94173C6.42048 8.447 5.15419 10.4183 4.50056 12.6234C3.84692 14.8285 3.83316 17.1756 4.46089 19.3883C5.08862 21.601 6.33171 23.5872 8.04375 25.1129C9.75579 26.6386 11.8655 27.6403 14.1244 28V19.4424H11.1243V15.9258H14.1244V13.2544C14.055 12.6332 14.1219 12.0043 14.3204 11.412C14.519 10.8197 14.8443 10.2784 15.2735 9.82648C15.7027 9.37451 16.2252 9.02284 16.8042 8.79627C17.3832 8.5697 18.0046 8.47376 18.6245 8.51523C19.5243 8.52808 20.4218 8.6088 21.3096 8.75671V11.7753H19.8095C19.5509 11.7424 19.2882 11.7685 19.0409 11.8516C18.7936 11.9346 18.568 12.0725 18.3808 12.2551C18.1936 12.4377 18.0495 12.6602 17.9593 12.9063C17.8691 13.1524 17.835 13.4158 17.8595 13.677V15.956H21.1896L20.6496 19.4726H17.8745V27.9245C20.7094 27.4734 23.2898 26.0148 25.1474 23.8134C27.0051 21.612 28.0171 18.8136 27.9998 15.9258Z"
					fill="#B6B6B6"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1746_8748">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default PlatformFacebookIconSvg;
