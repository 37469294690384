const ButtonPlusIconSvg = ({
	fill,
	className,
}: {
	fill: string;
	className?: string;
}) => {
	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M10.667 20.5C10.667 20.8978 10.825 21.2794 11.1063 21.5607C11.3876 21.842 11.7692 22 12.167 22C12.5648 22 12.9463 21.842 13.2277 21.5607C13.509 21.2794 13.667 20.8978 13.667 20.5V14H20.167C20.5648 14 20.9463 13.842 21.2277 13.5607C21.509 13.2794 21.667 12.8978 21.667 12.5C21.667 12.1022 21.509 11.7206 21.2277 11.4393C20.9463 11.158 20.5648 11 20.167 11H13.667V4.5C13.667 4.10218 13.509 3.72064 13.2277 3.43934C12.9463 3.15804 12.5648 3 12.167 3C11.7692 3 11.3876 3.15804 11.1063 3.43934C10.825 3.72064 10.667 4.10218 10.667 4.5V11H4.16699C3.76917 11 3.38764 11.158 3.10633 11.4393C2.82503 11.7206 2.66699 12.1022 2.66699 12.5C2.66699 12.8978 2.82503 13.2794 3.10633 13.5607C3.38764 13.842 3.76917 14 4.16699 14H10.667V20.5Z"
				fill={fill}
			/>
		</svg>
	);
};

export default ButtonPlusIconSvg;
