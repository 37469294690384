import { useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { GoogleMap, LoadScriptNext, MarkerF, Autocomplete } from "@react-google-maps/api";

// TODO: Might use rtk query, refactor
import { AppDispatch } from "../../../../redux/store";

// Icons
import { SearchOutlined } from "@ant-design/icons";

const libraries = ["places"];

const Map = ({
  
  defaultCenter = { lat: 0, lng: 0}
}) => {
  console.log("test21", defaultCenter)
  const mapContainerStyle = {
    height: "300px",
    width: "100%",
    border: "1px solid black",
    borderRadius: "20px",
  }
  const dispatch = useDispatch<AppDispatch>();

  const [center, setCenter] = useState(defaultCenter);
  const [marker, setMarker] = useState(defaultCenter);

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const [code, setZipCode] = useState("");
  const [city, setCity] = useState("");

  const onMapClick = useCallback((event: any) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarker({ lat, lng });
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK") {
        if (results && results[0]) {
          const addressComponents = results[0].address_components;
          let city = "";
          let zipCode = "";

          for (let component of addressComponents) {
            if (component.types.includes("locality")) {
              city = component.long_name;
            }
            if (component.types.includes("postal_code")) {
              zipCode = component.long_name;
            }
          }
        }
      }
    });
  }, []);

  const onPlaceChanged = useCallback(async () => {
    const place = autocompleteRef.current!.getPlace();

    if (place.geometry) {
      const location = place.geometry.location;
      if (location) {
        const lat = location.lat();
        const lng = location.lng();
        const zipCode = place.address_components?.find((component) =>
          component.types.includes("postal_code")
        )?.long_name;
        const city = place.address_components?.find((component) =>
          component.types.includes("locality")
        )?.long_name;
        setCenter({ lat, lng });
        setMarker({ lat, lng });
        setZipCode(code);
      }
    } else {
      console.log("No geometry property found for this place.");
    }
  }, [dispatch, setCenter, setMarker, setZipCode, setCity]);

  return (
    <LoadScriptNext
      googleMapsApiKey="AIzaSyDXvOCHRm_z2QymU889ZxPbxXqMmqIZ1vw"
      libraries={["places"]}
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={13}
        center={center}
        onClick={onMapClick}
      >
        <Autocomplete
          onLoad={(autocomplete) => {
            autocomplete.setFields(["geometry", "address_components"]);
            autocompleteRef.current = autocomplete;
          }}
          onPlaceChanged={onPlaceChanged}
        >
          <div style={{ position: "relative" }}>
            <input
              type="text"
              placeholder="Search location"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `100%`,
                height: `42px`,
                padding: `0 40px 0 32px`,
                borderRadius: `3px`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipsis`,
              }}
            />
            <SearchOutlined
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: 12,
                color: "#888",
              }}
            />
          </div>
        </Autocomplete>
        <MarkerF 
         position={marker}
        />
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default Map;
