import React, { HTMLProps } from "react";
import { PropsWithChildren } from "react";
import { cn } from "src/lib/utils";

type PageContainerProps = HTMLProps<HTMLDivElement> & {
  className?: string;
};

const PageContainer = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<PageContainerProps>
>(({ children, className, ...props }, ref) => {
  return (
    <div
      id="page-container"
      ref={ref}
      className={cn(
        "flex flex-col p-8 xl:py-10 xl:px-14 w-full h-full",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
});

export default PageContainer;
