const BackIconSvg = ({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20 34C27.7322 34 34 27.7322 34 20C34 12.2678 27.7322 6 20 6C12.2678 6 6 12.2678 6 20C6 27.7322 12.2678 34 20 34Z"
        stroke={fill}
        strokeWidth="2.8"
        strokeLinejoin="round"
      />
      <path
        d="M22.1 26.3001L15.8 20.0001L22.1 13.7001"
        stroke={fill}
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BackIconSvg;
