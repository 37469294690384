import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { Label } from "src/shared/components/ui/label";
import SidebarToggleButton from "../../button/SidebarToggleButton";
import ProfileAvatar from "../avatar/ProfileAvatar";
import { RxHamburgerMenu } from "react-icons/rx";

const NavBar = () => {
	const isLargeScreenNavCollapsed = useSelector(
		(state: RootState) => state.sidebar.isLargeScreenNavCollapsed
	);

	return (
		<div className="hidden xl:flex xl:flex-row xl:justify-between w-full h-[5.25em] border-b-[2px] border-gray-100 sticky top-0 z-10 bg-white">
			<SidebarToggleButton
				icon={RxHamburgerMenu}
				screen="Large"
				className="ml-6 border-none my-auto"
			/>

			<ProfileAvatar isLargeScreenNavCollapsed={isLargeScreenNavCollapsed} />
		</div>
	);
};

export default NavBar;
