import { HTMLProps, PropsWithChildren } from "react";
import {
	FormItem,
	FormLabel,
	FormControl,
	FormDescription,
	FormMessage,
} from "./Form";
import { Label } from "../ui/label";

type FormControllerType = HTMLProps<HTMLElement> & {
	label?: string;
	description?: string;
	hasValidationMessage?: boolean;
};

const FormController = ({
	children,
	label,
	description,
	hasValidationMessage = true,
}: PropsWithChildren<FormControllerType>) => {
	return (
		<FormItem>
			<FormLabel className="text-disabled-foreground text-[0.65rem] lg:text-sm tracking-tight">
				{label}
			</FormLabel>
			<FormControl>{children}</FormControl>
			<FormDescription className="leading-tight tracking-tight text-secondary">
				<Label variant="description">{description}</Label>
			</FormDescription>
			{hasValidationMessage && <FormMessage />}
		</FormItem>
	);
};

export default FormController;
