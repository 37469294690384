import { fetchUsers } from "../utils/login";
import accounts from "../../data/accounts/accounts.json";

export const loginUser = async (email: string, password: string) => {
  const response = await fetchUsers({
    email: email,
    password: password,
  });
  return response;
};

export const getLoggedInUser = async (id: number) => {
  if (!id) return null;
  const response = accounts.filter((user) => user.id === id);

  return response[0];
};
