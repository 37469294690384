import React, { forwardRef, PropsWithChildren } from "react";
import { cn } from "src/lib/utils";

type PageHeaderProps = React.HTMLProps<HTMLDivElement> & {
  className?: string;
};

const PageHeader = forwardRef<
  HTMLDivElement,
  PropsWithChildren<PageHeaderProps>
>(({ children, className, ...props }, ref) => {
  return (
    <div
      className={cn(
        "w-full mb-6 sm:mb-8 flex flex-row justify-between items-center",
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
});

export default PageHeader;
