import React, { forwardRef, PropsWithChildren } from "react";
import { cn } from "src/lib/utils";

type SectionHeaderProps = React.HTMLProps<HTMLDivElement> & {
  className?: string;
};

const SectionHeader = forwardRef<
  HTMLDivElement,
  PropsWithChildren<SectionHeaderProps>
>(({ children, className, ...props }, ref) => {
  return (
    <div
      className={cn(
        "w-full my-1 sm:my-4 lg:my-6 flex flex-row justify-start sm:justify-between items-start",
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
});

export default SectionHeader;
