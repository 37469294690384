const AuthLockIconSvg = ({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11 17.2381C11.5304 17.2381 12.0391 17.0374 12.4142 16.6802C12.7893 16.323 13 15.8385 13 15.3333C13 14.8282 12.7893 14.3437 12.4142 13.9865C12.0391 13.6293 11.5304 13.4286 11 13.4286C10.4696 13.4286 9.96086 13.6293 9.58579 13.9865C9.21071 14.3437 9 14.8282 9 15.3333C9 15.8385 9.21071 16.323 9.58579 16.6802C9.96086 17.0374 10.4696 17.2381 11 17.2381ZM17 8.66667C17.5304 8.66667 18.0391 8.86735 18.4142 9.22456C18.7893 9.58177 19 10.0663 19 10.5714V20.0952C19 20.6004 18.7893 21.0849 18.4142 21.4421C18.0391 21.7993 17.5304 22 17 22H5C4.46957 22 3.96086 21.7993 3.58579 21.4421C3.21071 21.0849 3 20.6004 3 20.0952V10.5714C3 10.0663 3.21071 9.58177 3.58579 9.22456C3.96086 8.86735 4.46957 8.66667 5 8.66667H6V6.7619C6 5.49897 6.52678 4.28776 7.46447 3.39473C8.40215 2.5017 9.67392 2 11 2C11.6566 2 12.3068 2.12317 12.9134 2.36248C13.52 2.60179 14.0712 2.95255 14.5355 3.39473C14.9998 3.83691 15.3681 4.36186 15.6194 4.9396C15.8707 5.51734 16 6.13656 16 6.7619V8.66667H17ZM11 3.90476C10.2044 3.90476 9.44129 4.20578 8.87868 4.7416C8.31607 5.27742 8 6.00414 8 6.7619V8.66667H14V6.7619C14 6.00414 13.6839 5.27742 13.1213 4.7416C12.5587 4.20578 11.7956 3.90476 11 3.90476Z"
        fill={fill}
      />
    </svg>
  );
};

export default AuthLockIconSvg;
