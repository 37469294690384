const AuthShowIconSvg = ({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 8.7C11.2767 8.7 10.583 8.995 10.0715 9.5201C9.56006 10.0452 9.27273 10.7574 9.27273 11.5C9.27273 12.2426 9.56006 12.9548 10.0715 13.4799C10.583 14.005 11.2767 14.3 12 14.3C12.7233 14.3 13.417 14.005 13.9285 13.4799C14.4399 12.9548 14.7273 12.2426 14.7273 11.5C14.7273 10.7574 14.4399 10.0452 13.9285 9.5201C13.417 8.995 12.7233 8.7 12 8.7ZM12 16.1667C10.7945 16.1667 9.63832 15.675 8.78588 14.7998C7.93344 13.9247 7.45455 12.7377 7.45455 11.5C7.45455 10.2623 7.93344 9.07534 8.78588 8.20017C9.63832 7.325 10.7945 6.83333 12 6.83333C13.2055 6.83333 14.3617 7.325 15.2141 8.20017C16.0666 9.07534 16.5455 10.2623 16.5455 11.5C16.5455 12.7377 16.0666 13.9247 15.2141 14.7998C14.3617 15.675 13.2055 16.1667 12 16.1667ZM12 4.5C7.45455 4.5 3.57273 7.40267 2 11.5C3.57273 15.5973 7.45455 18.5 12 18.5C16.5455 18.5 20.4273 15.5973 22 11.5C20.4273 7.40267 16.5455 4.5 12 4.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default AuthShowIconSvg;
