const ImportDataIconSvg = ({
	fill,
	className,
}: {
	fill: string;
	className?: string;
}) => {
	return (
		<svg
			width="52"
			height="64"
			viewBox="0 0 52 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M32.4 0H6.80002C5.10264 0 3.47477 0.674284 2.27454 1.87452C1.07431 3.07475 0.400024 4.70261 0.400024 6.4V57.6C0.400024 59.2974 1.07431 60.9253 2.27454 62.1255C3.47477 63.3257 5.10264 64 6.80002 64H45.2C46.8974 64 48.5253 63.3257 49.7255 62.1255C50.9257 60.9253 51.6 59.2974 51.6 57.6V19.2L32.4 0ZM45.2 57.6H6.80002V6.4H29.2V22.4H45.2V57.6ZM19.6 35.2H10V28.8H19.6V35.2ZM32.4 35.2H22.8V28.8H32.4V35.2ZM19.6 44.8H10V38.4H19.6V44.8ZM32.4 44.8H22.8V38.4H32.4V44.8ZM19.6 54.4H10V48H19.6V54.4ZM32.4 54.4H22.8V48H32.4V54.4Z"
				fill={fill}
			/>
		</svg>
	);
};

export default ImportDataIconSvg;
