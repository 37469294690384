const FilterIconSvg = ({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        strokeWidth="1.8"
        clipRule="evenodd"
        d="M4 2C2.89543 2 2 2.89543 2 4V5.5C2 5.77614 2.22386 6 2.5 6H21.5C21.7761 6 22 5.77614 22 5.5V4C22 2.89543 21.1046 2 20 2H4ZM3.02373 8.87747C2.67214 8.57611 2.88856 8 3.35162 8H20.6484C21.1114 8 21.3279 8.57611 20.9763 8.87747L20.9738 8.87964L15.1746 13.8503C15.0638 13.9453 15 14.084 15 14.23V19.1667C15 19.3685 14.8786 19.5506 14.6923 19.6282L9.69231 21.7115C9.36296 21.8488 9 21.6068 9 21.25V14.23C9 14.084 8.93622 13.9453 8.82539 13.8503L3.02623 8.87964L3.02373 8.87747Z"
        fill={fill}
      />
    </svg>
  );
};

export default FilterIconSvg;
