import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
	useGetLibraryQuery,
	useUpdateLibraryMutation,
} from "src/redux/library/apiSlice";

// Library
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { Library } from "src/redux/library/types";
import { LibraryFormSchema } from "src/redux/library/schema";

// Components
import { Form } from "src/shared/components/form/Form";
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";
import Loading from "src/shared/components/loading-indicator/Loading";
import SocialMediaForm from "./fragments/SocialMediaForm";
import ContactInfoForm from "./fragments/ContactInfoForm";
import AboutUsForm from "./fragments/AboutUsForm";

const LibraryForm = () => {
	const navigate = useNavigate();

	const [updateLibrary, { isLoading }] = useUpdateLibraryMutation();
	// ! Remove me on API Integration and use isLoading by rtk query
	const [showLoading, setShowLoading] = useState(false);

	const { data: library, isFetching: isFetchingLibrary } = useGetLibraryQuery();
	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

	const form = useForm<Library>({
		resolver: zodResolver(LibraryFormSchema),
		mode: "onTouched",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		form.reset();
		setIsLeaveModalOpen(false);
	};

	const _onCancelPress = () => {
		if (!form.formState.isDirty) navigate("/account");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = (value: Library) => {
		setShowLoading(true);
		setTimeout(async () => {
			await updateLibrary(value);

			form.reset(value, {
				keepDirty: false,
				keepTouched: false,
				keepIsValid: false,
				keepErrors: false,
			});
			setShowLoading(false);
		}, 2000);

		setIsSaveModalOpen(false);
	};

	useEffect(() => {
		if (library) {
			form.reset(library, {
				keepDirty: false,
				keepTouched: false,
				keepIsValid: false,
				keepErrors: false,
			});
		}
	}, [library, form]);

	if (isFetchingLibrary) {
		return <Loading />;
	}

	return (
		<Form {...form}>
			<div className="w-auto h-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer>
						<PageHeader>
							<Label variant="title">Library</Label>
						</PageHeader>

						{/* TODO Page Content? */}
						<div className="flex flex-col gap-6">
							<Label variant="description">
								Changes will reflect on the app. Last edited on{" "}
								<span className="font-bold">15/08/2022</span> at{" "}
								<span className="font-bold">16:40:51</span>
							</Label>

							<SectionContainer>
								<SectionHeader className="flex-col-reverse sm:flex-row gap-4">
									<div className="flex flex-col gap-2">
										<Label variant="header">About Us</Label>
										<Label variant="description">
											Displayed on the About MR.DIY page of the app.
										</Label>
									</div>
									<div className="grid grid-cols-2 sm:grid-cols-3 gap-2 w-full sm:w-[40%] lg:w-[30%]">
										<Button
											className="col-span-1 sm:col-span-2 xl:w-[80%] xl:ml-auto"
											size="sm"
											disabled={!isDirty || !isValid || showLoading}
											onClick={_onSavePress}
										>
											{showLoading ? <ButtonLoading /> : "Save"}
										</Button>
										<Button
											className="col-span-1"
											variant="secondary"
											size="sm"
											disabled={!isDirty || showLoading}
											onClick={_onCancelPress}
										>
											Cancel
										</Button>
									</div>
								</SectionHeader>
								<AboutUsForm form={form} />
							</SectionContainer>

							<ContactInfoForm form={form} />
							<SocialMediaForm form={form} />
						</div>
					</PageContainer>
				</form>
			</div>

			<ConfirmationDialog
				title="Save Changes?"
				description="Saving will apply all changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isSaveModalOpen}
				_onCancel={() => setIsSaveModalOpen(false)}
				_onConfirm={_onConfirmSave}
				confirmButtonVariant="default"
			/>

			<ConfirmationDialog
				title="Confirm Cancel?"
				description="Canceling will discard unsaved changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>
		</Form>
	);
};

export default LibraryForm;
