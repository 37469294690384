const PlatformGlobeIconSvg = ({ className }: { className?: string }) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_1746_8722)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.642 4.47422C10.3133 5.1498 8.24306 6.5087 6.70145 8.37362C5.15985 10.2385 4.21842 12.5229 4 14.9287H9.50774C9.8288 11.2677 10.8946 7.71015 12.6403 4.47251L12.642 4.47422ZM9.50774 17.0714H4C4.21797 19.4772 5.15899 21.7618 6.70029 23.627C8.2416 25.4923 10.3116 26.8516 12.6403 27.5275C10.8946 24.2899 9.8288 20.7323 9.50774 17.0714ZM15.401 27.9852C13.3142 24.6891 12.0348 20.9506 11.6661 17.0714H20.3322C19.9634 20.9506 18.684 24.6891 16.5972 27.9852C16.1988 28.0049 15.7995 28.0049 15.401 27.9852ZM19.3597 27.5258C21.6881 26.8499 23.758 25.4909 25.2992 23.626C26.8405 21.7611 27.7817 19.4769 28 17.0714H22.4923C22.1712 20.7323 21.1054 24.2899 19.3597 27.5275V27.5258ZM22.4923 14.9287H28C27.782 12.5228 26.841 10.2382 25.2997 8.373C23.7584 6.50777 21.6884 5.14849 19.3597 4.47251C21.1054 7.71015 22.1712 11.2677 22.4923 14.9287ZM15.401 4.01484C15.8001 3.99505 16.1999 3.99505 16.599 4.01484C18.6851 7.31108 19.964 11.0496 20.3322 14.9287H11.6678C12.0413 11.0256 13.3236 7.28368 15.401 4.01484Z"
					fill="#B6B6B6"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1746_8722">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default PlatformGlobeIconSvg;
