import { useDispatch } from "react-redux";
import { useEffect, type ElementType } from "react";
import {
  toggleSmallScreenNav,
  toggleLargeScreenNav,
} from "src/redux/slices/sidebarSlice";
import { Button } from "../ui/button";
import { useWindowResize } from "src/shared/hooks/useWindowResize";
import { cn } from "src/lib/utils";

type SidebarToggleButton = {
  icon: ElementType;
  screen: string;
  className?: string;
};

const SidebarToggleButton = ({
  icon: Icon,
  screen = "Large",
  className,
}: SidebarToggleButton) => {
  const dispatch = useDispatch();

  const handleLargeScreenNavToggle = () => {
    dispatch(toggleLargeScreenNav());
  };

  const handleSmallScreenNavToggle = () => {
    dispatch(toggleSmallScreenNav());
  };

  return (
    <Button
      variant="icon"
      size="icon"
      className={cn(
        "flex items-center justify-center w-10 h-10 rounded-full border-[1px] border-black hover:bg-transparent",
        className
      )}
      onClick={
        screen == "Large"
          ? handleLargeScreenNavToggle
          : handleSmallScreenNavToggle
      }
    >
      <Icon className="h-5 w-5 text-secondary text-sm" strokeWidth={0.5} />
    </Button>
  );
};

export default SidebarToggleButton;
