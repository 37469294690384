const PlatformWikiIconSvg = ({ className }: { className?: string }) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_1746_8793)">
				<path
					d="M28 8.06587L27.9888 8.5386C26.9352 8.5696 26.3015 9.15082 25.8966 10.1001C24.9592 12.3398 22.0234 19.3997 20.0737 23.9952H19.5638L16.493 16.5129C15.2745 18.9773 13.9322 21.5502 12.7736 23.9952C12.7624 24.0068 12.2112 23.9952 12.2112 23.9836C10.4527 19.7329 8.60803 15.5132 6.84581 11.251C6.41837 10.2164 4.99359 8.5386 4.01125 8.55022C4.01125 8.4301 4 8.16274 4 8H10.0703V8.5386C9.35041 8.58122 8.09061 9.05395 8.44681 9.86378C9.26793 11.7896 12.3312 19.1749 13.1561 21.0465C13.7185 19.8956 15.3232 16.8152 15.9794 15.5132C15.4582 14.4167 13.7822 10.3249 13.2498 9.31356C12.8861 8.62384 11.8963 8.56185 11.1576 8.55022V8.01162L16.5005 8.02325V8.53085C15.7732 8.5541 15.072 8.83308 15.3982 9.54217C16.1069 11.0921 16.5455 12.1809 17.2017 13.5991C17.4117 13.1806 18.5027 10.91 19.0052 9.69329C19.3389 8.89508 18.8589 8.5851 17.5579 8.5541C17.5691 8.4146 17.5579 8.15499 17.5691 8.02712C19.2339 8.0155 21.7347 8.0155 22.1847 8.00387V8.53085C21.341 8.56185 20.4674 9.02682 20.0062 9.75916L17.7866 14.5174C18.0266 15.1413 20.16 20.0506 20.3812 20.5892L24.9705 9.63904C24.648 8.74396 23.6057 8.55022 23.2007 8.54247V8.00387L27.9925 8.0465L28 8.06587Z"
					fill="#B6B6B6"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1746_8793">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default PlatformWikiIconSvg;
