import * as XLSX from "xlsx";

export type SheetData = Record<string, any>;

export const ProductHeaders = {
	SKU: "sku",
	Name: "name",
	Description: "description",
	Price: "price",
	Discounted_Price: "discounted_price",
	Category: "category",
	Image_Link: "images",
};

export const StoreHeaders = {
	Name: "name",
	Address: "address",
	Postal_Code: "code",
	City: "city",
	Opening_Hrs: "opening_hrs",
	Landline_Number: "landline_no",
	Longitude: "long",
	Latitude: "lat",
};

const InvalidStoreHeaders = {
	Reason: "reason",
	Name: "name",
	Address: "address",
	Postal_Code: "code",
	City: "city",
	Opening_Hrs: "opening_hrs",
	Landline_Number: "landline_no",
	Longitude: "long",
	Latitude: "lat",
};

export const InvalidProductHeaders = {
	Reason: "reason",
	SKU: "sku",
	Name: "name",
	Description: "description",
	Price: "price",
	Discounted_Price: "discounted_price",
	Category: "category",
	Image_Link: "images",
};

export const _exportTemplate = (type: string) => {
	const headers = type == "Product" ? ProductHeaders : StoreHeaders;

	const headings = [Object.keys(headers)];
	const columnHeaders = [...Object.values(headers)];

	const workbook: XLSX.WorkBook = XLSX.utils.book_new();
	const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(headings);

	XLSX.utils.book_append_sheet(workbook, worksheet, `Sheet 1`);

	const wscols: XLSX.WorkSheet["!cols"] = columnHeaders.map(() => ({
		wch: 30,
	}));
	worksheet["!cols"] = wscols;

	const dateToday = new Date();

	XLSX.writeFile(
		workbook,
		`${type}_Template_${dateToday.toLocaleDateString("en-US")}.xlsx`
	);
};

export const _downloadFile = <T>(data: T[], type: "Product" | "Store") => {
	const headers = type === "Product" ? ProductHeaders : InvalidStoreHeaders;

	const headings = [Object.keys(headers)];
	const rows = data.map((item) => {
		return Object.keys(headers).map((key) => {
			// Convert header key to lowercase and get corresponding value from item
			const dataKey = headers[
				key as keyof typeof headers
			].toLowerCase() as keyof T;

			// Return the item's value or a default placeholder if undefined
			return item[dataKey] !== undefined ? item[dataKey] : "N/A";
		});
	});

	const workbook: XLSX.WorkBook = XLSX.utils.book_new();

	// Create worksheet with both headers and rows
	const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([
		...headings,
		...rows,
	]);

	// Append worksheet to workbook
	XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

	// Set column widths for better readability
	const wscols: XLSX.WorkSheet["!cols"] = Object.values(headers).map(() => ({
		wch: 30,
	}));
	worksheet["!cols"] = wscols;

	// Format date for filename
	const dateToday = new Date();
	const formattedDate = dateToday.toISOString().split("T")[0]; // YYYY-MM-DD format

	// Write the file
	XLSX.writeFile(workbook, `Invalid_${type}_${formattedDate}.xlsx`);
};
