import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import rolesArray from "../../../data/role/roles.json";
import { Role } from "../utils/types";

export const rolesApi = createApi({
	reducerPath: "rolesApi",
	tagTypes: ["Roles", "Role"],
	baseQuery: fetchBaseQuery({ baseUrl: "/" }),
	endpoints: (builder) => ({
		getRoles: builder.query<Role[], void>({
			async queryFn() {
				return new Promise<{ data: Role[] }>((resolve, reject) => {
					try {
						setTimeout(() => {
							const roles = rolesArray.map((i: Role) => {
								return {
									...i,
								};
							});
							resolve({
								data: roles,
							});
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			providesTags: ["Roles"],
		}),
		getSelectedRole: builder.query<Role, { id: number }>({
			async queryFn({ id }) {
				return new Promise<{ data: Role }>((resolve, reject) => {
					try {
						setTimeout(() => {
							const selectedRole = rolesArray.find(
								(role: Role) => id === role.id
							);

							resolve({
								data: selectedRole as Role,
							});
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			// ? Comment cache for now, not sure if calling api everytime I need a selected role data is better than using the cache one
			// providesTags: (result, error, { id }) => [{ type: "Role", id: id }],
			// Prevents caching
			keepUnusedDataFor: 0,
		}),
		addRole: builder.mutation<void, Role>({
			query: (newRole) => ({
				url: "/new",
				method: "POST",
				body: newRole,
			}),
			invalidatesTags: ["Roles"],
		}),
		updateRole: builder.mutation<void, Role>({
			query: (updatedRole) => ({
				url: `/update/${updatedRole.id}`,
				method: "PUT",
				body: updatedRole,
			}),
			invalidatesTags: ["Roles"],
		}),
		deleteRole: builder.mutation<void, string>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Roles"],
		}),
	}),
});

export const {
	useGetRolesQuery,
	useGetSelectedRoleQuery,
	useAddRoleMutation,
	useUpdateRoleMutation,
	useDeleteRoleMutation,
} = rolesApi;
