import {
	AlertDialog,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "src/shared/components/ui/alert-dialog";
import { Label } from "../ui/label";
import { Button } from "../ui/button";

type LeavePageDialogProps = {
	title: string;
	description: string;
	confirmButtonLabel: string;
	closeButtonLabel: string;
	modalState: boolean;
	_onCancel: () => void;
	_onConfirm?: () => void;
	confirmButtonIcon?: React.ElementType;
	confirmButtonVariant?: string;
};

const ConfirmationDialog = ({
	title,
	description,
	confirmButtonLabel,
	closeButtonLabel,
	modalState,
	_onCancel,
	_onConfirm,
	confirmButtonIcon: Icon,
	confirmButtonVariant = "destructive",
}: LeavePageDialogProps) => {
	return (
		<AlertDialog open={modalState} onOpenChange={_onCancel}>
			<AlertDialogContent className="w-[80%] max-w-[18em] sm:w-[50%] md:w-[40%] md:max-w-[20em] lg:w-[35%] lg:max-w-[23em] xl:w-[23em] sm:space-y-4 md:space-y-2 lg:space-y-4 sm:py-8 lg:py-10 rounded-2xl sm:rounded-2xl lg:rounded-2xl xl:rounded-2xl">
				<AlertDialogHeader className="space-y-0 sm:text-center lg:space-y-2">
					<AlertDialogTitle>
						<Label variant="sub_header" className="sm:text-lg">
							{title}
						</Label>
					</AlertDialogTitle>
					<AlertDialogDescription>
						<Label variant="description" className="text-secondary sm:text-xs">
							{description}
						</Label>
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter className="flex flex-row gap-2 sm:gap-0 w-full lg:w-[90%] lg:mx-auto">
					<Button
						variant={`${confirmButtonVariant}` as "default"}
						size="sm"
						onClick={_onConfirm}
					>
						{Icon && <Icon fill="white" className="h-4 w-4" />}
						{confirmButtonLabel}
					</Button>
					<Button variant="secondary" size="sm" onClick={_onCancel}>
						{closeButtonLabel}
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};

export default ConfirmationDialog;
