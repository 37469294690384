import { useNavigate } from "react-router-dom";

// Components

import { Button } from "src/shared/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
} from "src/shared/components/ui/dialog";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";

// Image
import { Category } from "../utils/types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CategoryFormSchema } from "../utils/schema";
import { useEffect } from "react";
import { Form, FormField } from "src/shared/components/form/Form";
import FormController from "src/shared/components/form/FormController";
import { z } from "zod";
import UploadButton from "src/shared/components/button/UploadButton";
import ButtonPlusIconSvg from "src/assets/svg/ButtonPlusIconSvg";

type PreviewProductModalProps = {
	category?: Category;
	modalState: boolean;
	modalTrigger: () => void;
};

const CategoryFormModal = ({
	category,
	modalState,
	modalTrigger,
}: PreviewProductModalProps) => {
	const form = useForm<Category>({
		resolver: zodResolver(CategoryFormSchema),
		defaultValues: {
			id: 0,
			name: "",
			image: "",
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const _onSubmit = (values: z.infer<typeof CategoryFormSchema>) => {
		console.log({ values });
	};

	useEffect(() => {
		if (category) {
			form.reset(category, {
				keepDirty: false,
				keepTouched: false,
				keepIsValid: false,
				keepErrors: false,
			});
		}
	}, [category, form]);

	return (
		<Dialog open={modalState} onOpenChange={modalTrigger}>
			<Form {...form}>
				<div className="h-auto relative">
					<form onSubmit={form.handleSubmit(_onSubmit)}>
						<DialogContent className="w-[90%] max-w-[380px] sm:w-full sm:max-w-[460px] lg:w-[35em] lg:h-fit rounded-xl lg:rounded-2xl">
							<DialogHeader className="mt-4 xl:h-1 xl:my-4">
								<Label className="w-full text-center xl:text-lg">
									{!category?.id ? "Add Category" : "Edit Category"}
								</Label>
							</DialogHeader>
							<div className="grid grid-cols-3">
								<div className="col-span-3">
									<FormField
										control={form.control}
										name="name"
										render={({ field }) => (
											<FormController label="Category Name">
												<Input {...field} placeholder="-" />
											</FormController>
										)}
									/>
								</div>

								<div className="col-span-3 gap-4 md:flex md:flex-row">
									<FormField
										control={form.control}
										name="image"
										render={({ field: { value, onChange, ...fieldProps } }) => (
											<div>
												<FormController label="Category Image">
													<div className="relative h-[8em] w-[10em] md:h-[10em] aspect-square border-[1px] border-secondary rounded-md overflow-hidden mx-auto sm:ml-2">
														{value && (
															<div
																className="absolute inset-0 bg-cover bg-no-repeat bg-center opacity-70"
																style={{
																	backgroundImage: `url(${value})`,
																}}
															/>
														)}

														<div className="relative h-full w-full flex items-center justify-center">
															<div className="absolute bottom-10 md:bottom-14 ">
																<UploadButton
																	icon={ButtonPlusIconSvg}
																	className="w-[5em]"
																	{...fieldProps}
																	onChange={(
																		e: React.ChangeEvent<HTMLInputElement>
																	) => {
																		const file = e.target.files?.[0];
																		if (file) {
																			// If you need to show a preview immediately:
																			const imageUrl =
																				URL.createObjectURL(file);
																			onChange(imageUrl);

																			// Here you would typically upload the file to your server
																			// and then update the form with the returned URL
																			// uploadFileToServer(file).then(url => onChange(url));
																		}
																	}}
																	value="" // Keep the file input value empty
																/>
															</div>
														</div>
													</div>
												</FormController>
											</div>
										)}
									/>
									<div className="flex flex-col gap-1 lg:gap-0 leading-tight tracking-tight md:tracking-normal md:leading-normal text-secondary md:mt-8 text-justify">
										<Label variant="description" className="lg:text-[0.70rem]">
											&bull; Recommended image resolution is minimum of{" "}
											<span className="font-bold">88px</span> by{" "}
											<span className="font-bold">88px</span>
										</Label>
										<Label variant="description" className="lg:text-[0.70rem]">
											&bull; Image file size should be no more than{" "}
											<span className="font-bold">2mb</span>
										</Label>
										<Label variant="description" className="lg:text-[0.70rem]">
											&bull; Allowed image formats are{" "}
											<span className="font-bold">.jpg</span> and{" "}
											<span className="font-bold">.png</span> only
										</Label>
									</div>
								</div>
							</div>

							<DialogFooter className="flex flex-row gap-2 w-fit mx-auto mt-2 md:mt-0">
								<Button
									size="sm"
									className="py-[2px] px-6 h-8"
									onClick={modalTrigger}
								>
									Save
								</Button>
								<Button
									size="sm"
									variant="secondary"
									className="py-[2px] px-4 h-8"
									onClick={modalTrigger}
								>
									Cancel
								</Button>
							</DialogFooter>
						</DialogContent>
					</form>
				</div>
			</Form>
		</Dialog>
	);
};

export default CategoryFormModal;
