import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	useGetActiveBannersQuery,
	useGetInactiveBannersQuery,
} from "./slices/bannerApiSlice";
import { useActiveBannerColumns } from "./activeBannersColumns";
import { useInactiveBannerColumns } from "./inactiveBannersColumns";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import PageControls from "src/shared/components/control/PageControls";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import SearchInput from "src/shared/components/textfield/SearchInput";
import TableTabs from "./TableTabs";
import Loading from "src/shared/components/loading-indicator/Loading";

// Icons
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import DesignBackground from "src/shared/components/background/DesignBackground";

const BannerPage = () => {
	const navigate = useNavigate();

	const { data: activeBanners = [], isFetching: isActiveBannersFetching } =
		useGetActiveBannersQuery();
	const { data: inactiveBanners = [], isFetching: isInactiveBannersFetching } =
		useGetInactiveBannersQuery();
	const [selectedBanner, setSelectedBanner] = useState({
		id: 0,
		name: "",
	});
	const [searchValue, setSearchValue] = useState("");

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isActiveTable, setIsActiveTable] = useState(true);

	const _onOpenDeleteModal = (id: number, name: string) => {
		setIsDeleteDialogOpen(true);
		setSelectedBanner({
			id: id,
			name: name,
		});
	};

	const activeColumns = useActiveBannerColumns(_onOpenDeleteModal);
	const inactiveColumns = useInactiveBannerColumns(_onOpenDeleteModal);

	const _onCloseDeleteModal = () => {
		setIsDeleteDialogOpen(false);
	};

	const _onConfirmDelete = async () => {
		setIsDeleteDialogOpen(false);
	};

	const handleAddBanner = () => {
		navigate(`/banner/add`);
	};

	if (isActiveBannersFetching || isInactiveBannersFetching) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Banner Management</Label>
			</PageHeader>

			<PageControls>
				<div className="flex gap-2 w-full sm:w-[15em]">
					<Button onClick={() => navigate("add")}>
						<ButtonAddIconSvg fill="#292d32" className="h-6 w-6" />
						Add Banner
					</Button>
					<Button variant="destructive" disabled={true}>
						<ButtonDeleteIconSvg
							fill={`${true ? "#cbcbcc" : "white"}`}
							className="h-5 w-5 mb-[2px]"
						/>
						Delete
					</Button>
				</div>
				<div className="flex w-fit ml-auto">
					<SearchInput setSearchValue={setSearchValue} />
				</div>{" "}
			</PageControls>

			{/* Active and Inactive button */}
			<TableTabs
				isActiveTable={isActiveTable}
				setIsActiveTable={setIsActiveTable}
			/>

			{isActiveTable && (
				<DataTable data={activeBanners} columns={activeColumns} />
			)}

			{!isActiveTable && (
				<DataTable
					data={inactiveBanners}
					columns={inactiveColumns}
					hasPagination
				/>
			)}

			<ConfirmationDialog
				title="Delete Banner?"
				description={`Banner ${selectedBanner.name} will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteDialogOpen}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>
		</PageContainer>
	);
};

export default BannerPage;

// const data = Array.isArray(searchText ? filteredBanners : banners)
//   ? (searchText ? filteredBanners : banners).map((item: any) => ({
//       ...item,
//       key: item.id,
//     }))
//   : [];

// const handleSearchTextChange = (
//   event: React.ChangeEvent<HTMLInputElement>
// ) => {
//   dispatch(setSearchText(event.target.value));
// };

// const handleDeleteBannerLists = async () => {
//   await dispatch(deleteMany(toDeleteBannerLists));
//   await dispatch(setBannerListsToDelete([]));
//   await Toaster("Successfully deleted banner/s", "success");
// };

// const rowClassName = (record: any) => {
//   return record.key === selectedRow ? "selected-row" : "";
// };

// if (isFetching) return <div>Loading...</div>;
