import { Table } from "@tanstack/react-table";

export const renderPaginationNumbers = <T>(table: Table<T>) => {
	const currentPage = table.getState().pagination.pageIndex + 1; // 1-based for display
	const totalPages = table.getPageCount();
	const paginationNumbers = [];

	// Always show first page
	paginationNumbers.push(1);

	// Calculate the window around current page
	let windowStart = Math.max(2, currentPage - 1);
	let windowEnd = Math.min(totalPages - 1, currentPage + 1);

	// Adjust window to show more numbers if we're near the start or end
	if (currentPage <= 3) {
		windowEnd = Math.min(4, totalPages - 1);
	} else if (currentPage >= totalPages - 2) {
		windowStart = Math.max(2, totalPages - 3);
	}

	// Add ellipsis after 1 if there's a gap
	if (windowStart > 2) {
		paginationNumbers.push("...");
	}

	// Add all numbers in the window
	for (let i = windowStart; i <= windowEnd; i++) {
		paginationNumbers.push(i);
	}

	// Add ellipsis before last page if there's a gap
	if (windowEnd < totalPages - 1) {
		paginationNumbers.push("...");
	}

	// Always show last page if there is more than one page
	if (totalPages > 1) {
		paginationNumbers.push(totalPages);
	}

	return paginationNumbers;
};
