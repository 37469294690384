import { z } from "zod";

export const CustomerFormSchema = z
	.object({
		firstName: z
			.string()
			.min(2, {
				message: "First name must be at least 2 characters.",
			})
			.max(20, {
				message: "First name must not exceed 10 characters.",
			})
			.regex(/^[A-Za-z.'\s-]+$/, {
				message: "First name can only contain letters",
			})
			.trim(),
		lastName: z
			.string()
			.min(2, {
				message: "Last name must be at least 2 characters.",
			})
			.max(20, {
				message: "Last name must not exceed 10 characters.",
			})
			.regex(/^[A-Za-z.'-]+$/, {
				message: "Last name can only contain letters",
			})
			.trim(),
		email: z
			.string()
			.min(2, {
				message: "Invalid email.",
			})
			.email({ message: "Invalid email." }),
		mobile_no: z.coerce
			.string()
			.min(10, { message: "Mobile number must be 10 characters long." })
			.max(10, { message: "Mobile number must be 10 characters long." }),
		landline_no: z.coerce
			.string()
			.min(12, { message: "Landline number must be 12 characters long." })
			.max(12, { message: "Landline number must be 12 characters long." })
			.refine((value) => /^\d{3}-\d{3}-\d{4}$/.test(value), {
				message:
					"Please input a valid landline number in the format 111-222-3333.",
			})
			.optional(),
	})
	.strict();
