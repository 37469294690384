import { configureStore } from "@reduxjs/toolkit";
import { usersApi } from "../modules/user/slices/userApiSlice";
import { bannersApi } from "../modules/banner/slices/bannerApiSlice";
import { customersApi } from "../modules/customer/slices/customerApiSlice";
import { storesApi } from "../modules/store/slices/storeApiSlice";
import { accountsApi } from "./account/apiSlice";
import { productsApi } from "src/modules/product/slices/productApiSlice";
import { rolesApi } from "src/modules/roles/slices/roleApiSlice";
import { categoryApi } from "src/modules/category/slices/categoryApiSlice";
import { libraryApi } from "./library/apiSlice";
import sidebarSlice from "./slices/sidebarSlice";

export const store = configureStore({
	reducer: {
		[usersApi.reducerPath]: usersApi.reducer,
		[rolesApi.reducerPath]: rolesApi.reducer,
		[bannersApi.reducerPath]: bannersApi.reducer,
		[customersApi.reducerPath]: customersApi.reducer,
		[storesApi.reducerPath]: storesApi.reducer,
		[accountsApi.reducerPath]: accountsApi.reducer,
		[productsApi.reducerPath]: productsApi.reducer,
		[categoryApi.reducerPath]: categoryApi.reducer,
		[libraryApi.reducerPath]: libraryApi.reducer,

		sidebar: sidebarSlice,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(usersApi.middleware)
			.concat(rolesApi.middleware)
			.concat(customersApi.middleware)
			.concat(storesApi.middleware)
			.concat(bannersApi.middleware)
			.concat(accountsApi.middleware)
			.concat(productsApi.middleware)
			.concat(categoryApi.middleware)
			.concat(libraryApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
