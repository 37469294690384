const TableViewIconSvg = ({
	fill,
	className,
}: {
	fill: string;
	className?: string;
}) => {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M22.0546 12.0229L22.0547 12.0229L22.0539 12.0209C21.263 9.92863 19.8898 8.11887 18.1037 6.81555C16.3176 5.5122 14.197 4.7725 12.0039 4.68816L12.0039 4.68786L11.9962 4.68816C9.80311 4.7725 7.68247 5.5122 5.89637 6.81555C4.11029 8.11887 2.7371 9.92863 1.94618 12.0209L1.94616 12.0209L1.94544 12.0229C1.88485 12.1943 1.88485 12.3819 1.94544 12.5532L1.94541 12.5532L1.94618 12.5553C2.7371 14.6475 4.11029 16.4573 5.89637 17.7606C7.68247 19.064 9.80311 19.8037 11.9962 19.888L11.9962 19.8883L12.0039 19.888C14.197 19.8037 16.3176 19.064 18.1037 17.7606C19.8898 16.4573 21.263 14.6475 22.0539 12.5553L22.0546 12.5532C22.1152 12.3819 22.1152 12.1943 22.0546 12.0229ZM12.0001 16.479C14.2667 16.479 16.1003 14.6005 16.1003 12.2881C16.1003 9.97564 14.2667 8.09718 12.0001 8.09718C9.73359 8.09718 7.9 9.97564 7.9 12.2881C7.9 14.6005 9.73359 16.479 12.0001 16.479ZM12 18.3245C8.52349 18.3245 4.8388 15.6921 3.48725 12.2881C4.8388 8.88403 8.52349 6.25172 12 6.25172C15.4766 6.25172 19.1613 8.88404 20.5128 12.2881C19.1613 15.6921 15.4766 18.3245 12 18.3245Z"
				fill={fill}
				stroke={fill}
				stroke-width="0.2"
			/>
		</svg>
	);
};

export default TableViewIconSvg;
