import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Types
import { ColumnDef } from "@tanstack/react-table";
import { AppDispatch } from "src/redux/store";
import { UserWithFullName } from "./utils/types";

// Components
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import { Checkbox } from "src/shared/components/ui/checkbox";

// Icons
import { FaSort } from "react-icons/fa";
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";

type UserColumnProps = (id: number, name: string) => void;

export const useUserColumns = (
	_onOpenDeleteModal: UserColumnProps
): ColumnDef<UserWithFullName>[] => {
	const navigate = useNavigate();
	const dispatch: AppDispatch = useDispatch();

	const columns: ColumnDef<UserWithFullName>[] = [
		{
			id: "select",
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && "indeterminate")
					}
					onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "name",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Name</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
		},
		{
			accessorKey: "email",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Email</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
		},
		{
			accessorKey: "role",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Role</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
		},
		{
			accessorKey: "status",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Status</Label>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
			cell: ({ row }) => (
				<div
					className={`mx-auto text-center text-white rounded-full text-[0.85em] w-[7em] font-bold py-1 px-1 ${
						row.getValue("status") == 0 ? "bg-neutral-500" : "bg-green-600"
					}`}
				>
					{/* TODO: ADD BADGE */}
					{row.getValue("status") == 0 ? "INACTIVE" : "ACTIVE"}
				</div>
			),
		},
		{
			id: "actions",
			header: () => (
				<div>
					<Label>Action</Label>
				</div>
			),
			cell: ({ row }) => {
				return (
					<div className="flex flex-row gap-3">
						<Button
							variant="icon"
							size="icon"
							onClick={() => navigate(`/user/${row.original.id}`)}
						>
							<TableEditIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>

						{/* TRIGGER */}
						<Button
							variant="icon"
							size="icon"
							onClick={() =>
								_onOpenDeleteModal(row.original.id, row.original.name)
							}
						>
							<TableDeleteIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
					</div>
				);
			},
		},
	];

	return columns;
};
