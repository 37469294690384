import { z } from "zod";

export const AccountFormSchema = z
	.object({
		firstName: z
			.string({
				required_error: "Please enter your first name.",
				invalid_type_error: "First name cannot contain numbers or symbols.",
			})
			.min(2, {
				message: "First name must be at least 2 characters long.",
			})
			.max(20, {
				message: "First name must not exceed 10 characters.",
			})
			.regex(/^[A-Za-z.'\s-]+$/, {
				message: "First name cannot contain numbers or symbols.",
			})
			.trim(),
		lastName: z
			.string({
				required_error: "Please enter your last name.",
				invalid_type_error: "Last name cannot contain numbers or symbols.",
			})
			.min(2, {
				message: "Last name must be at least 2 characters long.",
			})
			.max(20, {
				message: "Last name must not exceed 10 characters.",
			})
			.regex(/^[A-Za-z.'\s-]+$/, {
				message: "Last name cannot contain numbers or symbols.",
			})
			.trim(),
		email: z
			.string({
				required_error: "Please enter your email.",
			})
			.min(2, {
				message: "Please enter a valid email address.",
			})
			.email({ message: "Please enter a valid email address." }),
		role: z.coerce
			.number({
				required_error: "Please select a role.",
			})
			.refine((value) => value != 0, {
				message: "Please select a role.",
			}),
		image: z.union([z.instanceof(File), z.string().url()]),
	})
	.strict();
