import { z } from "zod";

export const storeSchema = z
	.object({
		name: z
			.string({
				required_error: "Store name is empty.",
				invalid_type_error: "Store name cannot be a number.",
			})
			.min(2, {
				message: "Store name must be at least 2 characters.",
			})
			.max(50, {
				message: "Store name must not exceed 50 characters.",
			})
			.regex(/^[A-Za-z.'\s-]+$/, {
				message:
					"Store name can only contain letters, spaces, apostrophes, and hyphens.",
			})
			.transform((val) => val.trim()),
		address: z
			.string({
				required_error: "Address is empty.",
				invalid_type_error: "Address cannot be a number.",
			})
			.min(5, {
				message: "Address must be at least 5 characters.",
			})
			.max(100, {
				message: "Address must not exceed 100 characters.",
			})
			.regex(/^[A-Za-z0-9,.'\s-]+$/, {
				message:
					"Address can only contain letters, numbers, spaces, commas, apostrophes, and hyphens.",
			})
			.trim(),
		code: z
			.string({
				required_error: "Postal Code is empty.",
				invalid_type_error: "Postal Code cannot be a number.",
			})
			.min(5, {
				message: "Postal code must be at least 5 characters.",
			})
			.max(10, {
				message: "Postal code must not exceed 10 characters.",
			})
			.regex(/^[A-Za-z0-9\s-]+$/, {
				message:
					"Postal code can only contain letters, numbers, spaces, and hyphens.",
			})
			.trim(),
		city: z
			.string({
				required_error: "City is empty.",
				invalid_type_error: "City cannot be a number.",
			})
			.min(2, {
				message: "City name must be at least 2 characters.",
			})
			.max(50, {
				message: "City name must not exceed 50 characters.",
			})
			.regex(/^[A-Za-z.'\s-]+$/, {
				message:
					"City name can only contain letters, spaces, apostrophes, and hyphens.",
			})
			.trim(),
		lat: z.coerce.number({
			required_error: "Latitude is empty.",
			invalid_type_error: "Latitude must be a number.",
		}),
		long: z.coerce.number({
			required_error: "Longitude is empty.",
			invalid_type_error: "Longitude must be a number.",
		}),
	})
	.strict();

export const importStoreSchema = z
	.object({
		name: z
			.string({
				required_error: "Store name is empty.",
				invalid_type_error: "Store name cannot be a number.",
			})
			.min(2, {
				message: "Store name must be at least 2 characters.",
			})
			.max(50, {
				message: "Store name must not exceed 50 characters.",
			})
			.regex(/^[A-Za-z.'\s-]+$/, {
				message:
					"Store name can only contain letters, spaces, apostrophes, and hyphens.",
			})
			.transform((val) => val.trim()),
		address: z
			.string({
				required_error: "Address is empty.",
				invalid_type_error: "Address cannot be a number.",
			})
			.min(5, {
				message: "Address must be at least 5 characters.",
			})
			.max(100, {
				message: "Address must not exceed 100 characters.",
			})
			.regex(/^[A-Za-z0-9,.'\s-]+$/, {
				message:
					"Address can only contain letters, numbers, spaces, commas, apostrophes, and hyphens.",
			})
			.trim(),
		code: z
			.string({
				required_error: "Postal Code is empty.",
				invalid_type_error: "Postal Code cannot be a number.",
			})
			.min(5, {
				message: "Postal code must be at least 5 characters.",
			})
			.max(10, {
				message: "Postal code must not exceed 10 characters.",
			})
			.regex(/^[A-Za-z0-9\s-]+$/, {
				message:
					"Postal code can only contain letters, numbers, spaces, and hyphens.",
			})
			.trim(),
		city: z
			.string({
				required_error: "City is empty.",
				invalid_type_error: "City cannot be a number.",
			})
			.min(2, {
				message: "City name must be at least 2 characters.",
			})
			.max(50, {
				message: "City name must not exceed 50 characters.",
			})
			.regex(/^[A-Za-z.'\s-]+$/, {
				message:
					"City name can only contain letters, spaces, apostrophes, and hyphens.",
			})
			.trim(),
		lat: z.coerce.number({
			required_error: "Latitude is empty.",
			invalid_type_error: "Latitude must be a number.",
		}),
		long: z.coerce.number({
			required_error: "Longitude is empty.",
			invalid_type_error: "Longitude must be a number.",
		}),
		opening_hrs: z
			.string({
				required_error: "Opening hours is empty.",
				invalid_type_error: "Invalid opening hours.",
			})
			.trim(),
		landline_no: z.coerce.number({
			required_error: "Landline Number is empty.",
			invalid_type_error: "Landline Number must be a number.",
		}),
	})
	.strict();
