import React, { HTMLProps } from "react";
import { Button } from "../ui/button";
import DownloadIconSvg from "src/assets/svg/DownloadIconSvg";
import { Label } from "../ui/label";
import { Table } from "@tanstack/react-table";
import { Product } from "src/modules/product/utils/types";

type ExportButtonProps = {
	onClick?: () => void;
};

const ExportButton = ({ onClick }: ExportButtonProps) => {
	return (
		<Button className="relative px-8" onClick={onClick} size="sm">
			<DownloadIconSvg className="w-5 h-5" />
			<Label className="hidden md:block cursor-pointer">Get Template</Label>
		</Button>
	);
};

export default ExportButton;
