const ButtonCheckIconSvg = ({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM15.535 8.381L10.585 13.331L8.465 11.21C8.37216 11.1171 8.26192 11.0434 8.14059 10.9931C8.01926 10.9428 7.8892 10.9168 7.75785 10.9168C7.49258 10.9167 7.23814 11.022 7.0505 11.2095C6.86286 11.397 6.75739 11.6514 6.7573 11.9166C6.7572 12.1819 6.86249 12.4364 7.05 12.624L9.808 15.382C9.91015 15.4842 10.0314 15.5653 10.1649 15.6206C10.2984 15.6759 10.4415 15.7044 10.586 15.7044C10.7305 15.7044 10.8736 15.6759 11.0071 15.6206C11.1406 15.5653 11.2618 15.4842 11.364 15.382L16.95 9.796C17.1376 9.60836 17.2431 9.35386 17.2431 9.0885C17.2431 8.82314 17.1376 8.56864 16.95 8.381C16.7624 8.19336 16.5079 8.08794 16.2425 8.08794C15.9771 8.08794 15.7226 8.19336 15.535 8.381Z"
        fill={fill}
      />
      <path
        d="M6.90853 12.7654L6.90858 12.7654L9.66654 15.5234C9.66656 15.5234 9.66657 15.5234 9.66658 15.5234C9.7873 15.6442 9.93062 15.74 10.0884 15.8054C10.2461 15.8707 10.4152 15.9044 10.586 15.9044C10.7568 15.9044 10.9259 15.8707 11.0836 15.8054C11.2414 15.74 11.3847 15.6442 11.5054 15.5234C11.5054 15.5234 11.5054 15.5234 11.5055 15.5234L17.0914 9.93742C17.3166 9.71227 17.4431 9.40691 17.4431 9.0885C17.4431 8.77009 17.3166 8.46473 17.0914 8.23958C16.8663 8.01443 16.5609 7.88794 16.2425 7.88794C15.9241 7.88794 15.6187 8.01443 15.3936 8.23958L10.585 13.0481L8.60647 11.0686C8.60647 11.0686 8.60646 11.0686 8.60645 11.0686C8.49505 10.9571 8.36278 10.8687 8.21719 10.8083C8.0716 10.748 7.91554 10.7169 7.75792 10.7168C7.43961 10.7167 7.13429 10.843 6.90913 11.068C6.68397 11.293 6.55741 11.5983 6.5573 11.9166C6.55718 12.2349 6.68353 12.5402 6.90853 12.7654ZM12 2.2C17.4125 2.2 21.8 6.58746 21.8 12C21.8 17.4125 17.4125 21.8 12 21.8C6.58746 21.8 2.2 17.4125 2.2 12C2.2 6.58746 6.58746 2.2 12 2.2Z"
        stroke="none"
        strokeOpacity="0.5"
        strokeWidth="0.4"
      />
    </svg>
  );
};

export default ButtonCheckIconSvg;
