import { z } from "zod";

export const userFormSchema = z
	.object({
		firstName: z
			.string()
			.min(2, {
				message: "First name must be at least 2 characters.",
			})
			.max(20, {
				message: "First name must not exceed 10 characters.",
			})
			.regex(/^[A-Za-z.'\s-]+$/, {
				message: "First name can only contain letters",
			})
			.trim(),
		lastName: z
			.string()
			.min(2, {
				message: "Last name must be at least 2 characters.",
			})
			.max(20, {
				message: "Last name must not exceed 10 characters.",
			})
			.regex(/^[A-Za-z.'-]+$/, {
				message: "Last name can only contain letters",
			})
			.trim(),
		email: z
			.string()
			.min(2, {
				message: "Invalid email.",
			})
			.email({ message: "Invalid email." }),
		role: z.string({ message: "Please select a user role." }),
		status: z.coerce.number({ message: "Please select a user status." }),
	})
	.strict();
