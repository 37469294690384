const ChevronDownIconSvg = ({
	fill,
	className,
}: {
	fill: string;
	className?: string;
}) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M18.3002 8.85015L12.0002 15.1501L5.7002 8.85015"
				stroke={fill}
				strokeWidth="2.8"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ChevronDownIconSvg;
