import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import library from "src/data/library/library.json";
import { Library } from "./types";

export const libraryApi = createApi({
	reducerPath: "libraryApi",
	tagTypes: ["Library"],
	baseQuery: fetchBaseQuery({ baseUrl: "/" }),
	endpoints: (builder) => ({
		getLibrary: builder.query<Library, void>({
			async queryFn() {
				return new Promise<{ data: Library }>((resolve, reject) => {
					try {
						setTimeout(async () => {
							if (library) {
								const response = await fetch(library.image);
								const blob = await response.blob();
								const imageUrl = URL.createObjectURL(blob);

								const formattedLibrary = {
									...library,
									image: imageUrl,
								};

								resolve({
									data: formattedLibrary as Library,
								});
							}
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			keepUnusedDataFor: 0,
		}),
		updateLibrary: builder.mutation<void, Library>({
			query: (library) => ({
				url: `/update/${library}`,
				method: "PUT",
				body: library,
			}),
		}),
	}),
});

export const { useGetLibraryQuery, useUpdateLibraryMutation } = libraryApi;
