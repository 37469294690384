import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import { Input } from "src/shared/components/ui/input";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/shared/components/ui/card";

import AuthEmailIconSvg from "src/assets/svg/auth/AuthEmailIconSvg";
import diyLogo from "src/assets/images/mini_diy_logo.png";
import BackIconSvg from "src/assets/svg/BackIconSvg";

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [modalOpen, setModalOpen] = useState(false);
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");

	const validateEmail = (email: string) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	};

	const handleResetPassword = () => {
		if (!validateEmail(email)) {
			setEmailError("Please enter a valid email address.");
			return;
		}
		setEmailError(""); // Clear any previous errors
		setModalOpen(true);

		setTimeout(() => {
			navigate(`/auth/reset-password`, { replace: true });
		}, 3000); // Adjust timeout as needed
	};

	return (
		<form>
			<Card className="w-[80%] max-w-[20em] sm:w-[20em] sm:max-w-[28em] lg:w-[23em] 2xl:w-[28em] rounded-3xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/4">
				<CardHeader className="pb-5 2xl:pb-8">
					<CardTitle className="relative mb-9 lg:mb-14 2xl:mb-20">
						<div className="absolute h-[6em] w-[6em] -top-24 left-1/2 -translate-x-1/2 overflow-hidden lg:w-[8em] lg:h-[8em] 2xl:w-[10em] 2xl:h-[10em] lg:-top-32 2xl:-top-36">
							<img
								src={diyLogo}
								alt="banner"
								className="w-full h-full object-fill"
							/>
						</div>
					</CardTitle>
					<CardDescription className="flex flex-col gap-4 w-full lg:w-[90%] mx-auto">
						<div className="flex flex-row text-center">
							<Button
								size="icon"
								variant="icon"
								onClick={() => navigate("/auth")}
							>
								<BackIconSvg
									className="h-7 w-7 lg:h-8 lg:w-8 2xl:h-10 2xl:w-10"
									fill="#292D32"
								/>
							</Button>
							<Label
								variant="sub_header"
								className="flex mx-auto w-fit text-secondary font-semibold 2xl:text-2xl 2xl:my-2"
							>
								Forgot Password
							</Label>
						</div>
						<Label
							variant="description"
							className="flex mx-auto text-secondary lg:text-xs text-center leading-tight tracking-tight"
						>
							Please enter your email address where the link to reset your
							password will be sent.
						</Label>
					</CardDescription>
				</CardHeader>
				<CardContent>
					<div className="flex flex-col w-full items-center gap-4 2xl:gap-6">
						<div className="flex flex-col gap-1 w-[90%]">
							<Label
								variant="form"
								className="flex flex-row gap-1 2xl:gap-2 items-center"
							>
								<AuthEmailIconSvg
									className="h-4 w-4 2xl:h-6 2xl:w-6"
									fill="#292D32"
								/>
								Email
							</Label>
							<Input
								id="email"
								placeholder="..."
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
					</div>
				</CardContent>
				<CardFooter className="flex justify-center mb-2 lg:mt-1 lg:mb-8 2xl:mt-2">
					<Button
						size="sm"
						className="px-6 h-7 lg:h-9 lg:px-10 xl:w-[40%] shadow-lg rounded-full"
						type="submit"
					>
						Submit
					</Button>
				</CardFooter>
			</Card>
		</form>
	);
};

export default ForgotPassword;
