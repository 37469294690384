import Styled from "styled-components";
import { useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { Navigate, useNavigate } from "react-router-dom";

// Utils
import * as yup from "yup";
import { logginIn } from "../../shared/utils/login";

// Components
import { Input } from "src/shared/components/ui/input";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/shared/components/ui/card";

import AuthEmailIconSvg from "src/assets/svg/auth/AuthEmailIconSvg";
import AuthHideIconSvg from "src/assets/svg/auth/AuthHideIconSvg";
import AuthLockIconSvg from "src/assets/svg/auth/AuthLockIconSvg";
import AuthShowIconSvg from "src/assets/svg/auth/AuthShowIconSvg";
import diyLogo from "src/assets/images/mini_diy_logo.png";

// TODO Should be removed, create schema file dedicated for this
const loginSchema = yup.object({
	email: yup.string().email("Invalid email").required("Email is required"),
	password: yup.string().required("Password is required"),
});

const LoginForm = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const auth = useAuth();

	const [showPassword, setShowPassword] = useState(false);

	// Function to toggle the password visibility
	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword: boolean) => !prevShowPassword);
	};

	const signInUser = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		try {
			await loginSchema.validate({ email, password }, { abortEarly: false });
			const userData = await logginIn(email, password);

			if (Array.isArray(userData) && userData.length > 0) {
				// Toaster("You have successfully logged in", "success");
				auth?.setAuthUser(userData[0]);
				localStorage.setItem("accesstoken", JSON.stringify(userData[0].id));
			} else {
				// Toaster("Please Input correct email and password", "error");
			}

			setEmailError("");
			setPasswordError("");
		} catch (err: any) {
			if (err.inner) {
				const emailErr = err.inner.find(
					(e: { path: string }) => e.path === "email"
				);
				const passwordErr = err.inner.find(
					(e: { path: string }) => e.path === "password"
				);
				setEmailError(emailErr ? emailErr.message : "");
				setPasswordError(passwordErr ? passwordErr.message : "");
			} else {
				console.log(err);
			}
		}
	};

	if (auth?.authUser) {
		return <Navigate to="/banner" replace />;
	}

	return (
		<form onSubmit={signInUser}>
			<Card className="w-[80%] max-w-[20em] sm:w-[20em] sm:max-w-[28em] lg:w-[23em] 2xl:w-[28em] rounded-3xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/4 border-none shadow-xl">
				<CardHeader className="pb-5 2xl:pb-8">
					<CardTitle className="relative mb-9 lg:mb-14 2xl:mb-20">
						<div className="absolute h-[6em] w-[6em] -top-24 left-1/2 -translate-x-1/2 overflow-hidden lg:w-[8em] lg:h-[8em] 2xl:w-[10em] 2xl:h-[10em] lg:-top-32 2xl:-top-36">
							<img
								src={diyLogo}
								alt="banner"
								className="w-full h-full object-fill"
							/>
						</div>
					</CardTitle>
					<CardDescription>
						<Label
							variant="sub_header"
							className="flex mx-auto w-fit text-secondary text-base font-semibold 2xl:text-2xl 2xl:my-2"
						>
							Login
						</Label>
					</CardDescription>
				</CardHeader>
				<CardContent>
					<div className="flex flex-col w-full items-center gap-4 2xl:gap-6">
						<div className="flex flex-col gap-1 w-[90%]">
							<Label
								variant="form"
								className="flex flex-row gap-1 2xl:gap-2 items-center"
							>
								<AuthEmailIconSvg
									className="h-4 w-4 2xl:h-6 2xl:w-6"
									fill="#292D32"
								/>
								Email
							</Label>

							<Input
								id="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className="flex flex-col gap-1 w-[90%]">
							<Label
								variant="form"
								className="flex flex-row gap-1 2xl:gap-2 items-center"
							>
								<AuthLockIconSvg
									className="h-4 w-4 2xl:h-6 2xl:w-6"
									fill="#292D32"
								/>
								Password
							</Label>
							<div className="relative w-full">
								<Input
									className="pr-6 lg:pr-8"
									id="password"
									type={showPassword ? "" : "password"}
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>

								<div onClick={togglePasswordVisibility}>
									{showPassword ? (
										<AuthHideIconSvg
											fill="#292D32"
											className="text-secondary absolute right-1 bottom-0 transform -translate-y-1/4 w-4 h-4 2xl:h-6 2xl:w-6"
										/>
									) : (
										<AuthShowIconSvg
											fill="#292D32"
											className="text-secondary absolute right-1 bottom-0 transform -translate-y-1/4 w-4 h-4 2xl:h-6 2xl:w-6"
										/>
									)}
								</div>
							</div>
							<Button
								variant="link"
								size="icon"
								className="ml-auto mt-1 tracking-tight]"
								onClick={() => navigate("/auth/forgot-password")}
							>
								<Label variant="description" className="hover:cursor-pointer">
									Forgot Password
								</Label>
							</Button>
						</div>
					</div>
				</CardContent>
				<CardFooter className="flex justify-center mb-2 2xl:mt-2 2xl:mb-4">
					<Button
						size="sm"
						className="px-6 h-7 lg:h-9 lg:px-10 xl:w-[40%] shadow-lg rounded-full"
						type="submit"
					>
						Sign-In
					</Button>
				</CardFooter>
			</Card>
		</form>
	);
};

export default LoginForm;
