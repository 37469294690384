import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { Button } from "src/shared/components/ui/button";
import { Sheet, SheetContent } from "src/shared/components/ui/sheet";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "src/shared/components/ui/tooltip";

// Icons
import SidebarBannerIconSvg from "src/assets/svg/sidebar/SidebarBannerIconSvg";
import SidebarUserIconSvg from "src/assets/svg/sidebar/SidebarUserIconSvg";
import SidebarCustomerIconSvg from "src/assets/svg/sidebar/SidebarCustomerIconSvg";
import SidebarProductIconSvg from "src/assets/svg/sidebar/SidebarProductIconSvg";
import SidebarCategoryIconSvg from "src/assets/svg/sidebar/SidebarCategoryIconSvg";
import SidebarStoreIconSvg from "src/assets/svg/sidebar/SidebarStoreIconSvg";
import SidebarLibraryIconSvg from "src/assets/svg/sidebar/SidebarLibraryIconSvg";
import SidebarSettingsIconSvg from "src/assets/svg/sidebar/SidebarSettingsIconSvg";
import ChevronDownIconSvg from "src/assets/svg/ChevronDownIconSvg";
import ChevronUpIconSvg from "src/assets/svg/ChevronUpIconSvg";
import SidebarSubmoduleIconSvg from "src/assets/svg/sidebar/SidebarSubmoduleIconSvg";
import { Label } from "../../ui/label";

type NavItem = {
	label: string;
	path: string;
	icon: React.ElementType;
};

type NavItems = NavItem & {
	subModule?: NavItem[];
};

const sideNavItems: NavItems[] = [
	{
		label: "Banner Management",
		path: "banner",
		icon: SidebarBannerIconSvg,
	},
	{
		label: "User Management",
		path: "users",
		icon: SidebarUserIconSvg,
		subModule: [
			{ label: "Users List", path: "user", icon: SidebarSubmoduleIconSvg },
			{
				label: "Roles & Permission",
				path: "role",
				icon: SidebarSubmoduleIconSvg,
			},
		],
	},
	{
		label: "Customer Management",
		path: "customer",
		icon: SidebarCustomerIconSvg,
	},
	{
		label: "Product Management",
		path: "product",
		icon: SidebarProductIconSvg,
	},
	{
		label: "Category Management",
		path: "category",
		icon: SidebarCategoryIconSvg,
	},
	{
		label: "Store Management",
		path: "store",
		icon: SidebarStoreIconSvg,
	},
	{
		label: "Library",
		path: "library",
		icon: SidebarLibraryIconSvg,
	},
	{
		label: "Account Settings",
		path: "account",
		icon: SidebarSettingsIconSvg,
	},
];

type NavigationListProps = {
	isLargeScreenNavCollapsed: boolean;
	isSmallScreenNavOpen: boolean;
	setIsSmallScreenNavOpen: (isOpen: boolean) => void;
};

const NavigationList = ({
	isLargeScreenNavCollapsed,
	isSmallScreenNavOpen,
	setIsSmallScreenNavOpen,
}: NavigationListProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");

	const [isUserModuleOpen, setIsUserModuleOpen] = useState(false);

	return (
		<>
			<Sheet open={isSmallScreenNavOpen} onOpenChange={setIsSmallScreenNavOpen}>
				<SheetContent side="left" className="px-3 overflow-auto">
					<div
						id="small-nav"
						className="flex flex-col mt-8 gap-y-2 items-center"
					>
						{sideNavItems.map((item) => {
							return (
								<Fragment key={item.path}>
									{!item.subModule ? (
										<Button
											className={`w-full text-start mx-none group rounded-lg ${
												pathSegments[1] === item.path
													? "bg-secondary text-white active:text-white hover:text-white focus:text-white"
													: "bg-transparent hover:bg-accent hover:text-secondary"
											}`}
											variant="sidenav"
											onClick={() => {
												navigate(item.path);
												setIsUserModuleOpen(false);
											}}
										>
											<div className="flex flex-row w-full items-center justify-between">
												<div className="flex items-center gap-3">
													<item.icon
														className={`${
															item.path === "banner" ? "h-7 w-7" : "h-6 w-6"
														}`}
														fill={
															pathSegments[1] === item.path ? "#ED1D24" : "none"
														}
														outline={
															pathSegments[1] === item.path
																? "#FFF200"
																: "#292d32"
														}
													/>
													<span
														className={`text-xs ${
															isLargeScreenNavCollapsed ? "hidden" : "inline"
														}`}
													>
														{item.label}
													</span>
												</div>
											</div>
										</Button>
									) : (
										<div
											className={`w-full ${isUserModuleOpen && "space-y-2"}`}
										>
											<Button
												key={item.path}
												className={`w-full text-start mx-none group rounded-lg ${
													isUserModuleOpen
														? "bg-secondary text-white active:text-white hover:text-white focus:text-white"
														: "bg-transparent hover:bg-accent hover:text-secondary"
												}`}
												variant="sidenav"
												onClick={() =>
													setIsUserModuleOpen((prevValue) => !prevValue)
												}
											>
												<div className="flex flex-row w-full items-center justify-between">
													<div className="flex items-center gap-3">
														<item.icon
															className="h-6 w-6"
															fill="none"
															outline={isUserModuleOpen ? "white" : "#292d32"}
														/>
														<span
															className={`text-xs ${
																isLargeScreenNavCollapsed ? "hidden" : "inline"
															}`}
														>
															{item.label}
														</span>
													</div>

													{isUserModuleOpen ? (
														<ChevronUpIconSvg
															fill="white"
															className="h-5 w-5"
														/>
													) : (
														<ChevronDownIconSvg
															fill="#292d32"
															className="h-5 w-5"
														/>
													)}
												</div>
											</Button>

											<div
												className={`flex flex-col transition-all transform duration-150 overflow-hidden gap-2 ${
													isUserModuleOpen
														? "h-[6em] translate-y-0 opacity-100"
														: "h-0 -translate-y-full opacity-0"
												}`}
											>
												{item.subModule.map((module) => {
													return (
														<Button
															key={module.path}
															className={`w-full text-start mx-none group rounded-lg ${
																pathSegments[1] === module.path
																	? "bg-secondary text-white active:text-white hover:text-white focus:text-white"
																	: "bg-transparent hover:bg-accent hover:text-secondary"
															}`}
															variant="sidenav"
															onClick={() => navigate(module.path)}
														>
															<div className="flex flex-row w-full items-center justify-between">
																<div className="flex items-center gap-3">
																	<SidebarSubmoduleIconSvg
																		className="h-6 w-6"
																		fill={
																			pathSegments[1] === module.path
																				? "#FFF200"
																				: "#292d32"
																		}
																		outline={
																			pathSegments[1] === module.path
																				? "#ED1D24"
																				: "#292d32"
																		}
																	/>
																	<span
																		className={`text-xs ${
																			isLargeScreenNavCollapsed
																				? "hidden"
																				: "inline"
																		}`}
																	>
																		{module.label}
																	</span>
																</div>
															</div>
														</Button>
													);
												})}
											</div>
										</div>
									)}
								</Fragment>
							);
						})}
					</div>
				</SheetContent>
			</Sheet>

			{/* NAVIGATION LIST FOR LARGE SCREENS */}
			<div
				id="large-nav"
				className={`hidden mt-4 mx-[26px] xl:flex xl:flex-col gap-y-2 items-start`}
			>
				{sideNavItems.map((item) => {
					return (
						<Fragment key={item.path}>
							{!item.subModule ? (
								// MODULES WITHOUT DROPDOWN
								<TooltipProvider key={item.path}>
									<Tooltip delayDuration={100}>
										<TooltipTrigger className="w-full">
											<Button
												className={`text-start mx-none group rounded-lg ${
													pathSegments[1] === item.path
														? "bg-secondary text-white active:text-white hover:text-white focus:text-white"
														: "bg-transparent hover:bg-accent hover:text-secondary"
												}`}
												variant="sidenav"
												onClick={() => {
													navigate(item.path);
													setIsUserModuleOpen(false);
												}}
											>
												<div className="flex flex-row w-full items-center justify-between">
													<div className="flex items-center gap-3">
														<item.icon
															className={`${
																item.path === "banner"
																	? "h-7 w-7"
																	: "h-6 w-6 ml-[1px]"
															}`}
															fill={
																pathSegments[1] === item.path
																	? "#ED1D24"
																	: "none"
															}
															outline={
																pathSegments[1] === item.path
																	? "#FFF200"
																	: "#292d32"
															}
														/>
														<span
															className={`text-xs ${
																isLargeScreenNavCollapsed ? "hidden" : "inline"
															}`}
														>
															{item.label}
														</span>
													</div>
												</div>
											</Button>
										</TooltipTrigger>
										<TooltipContent
											side="right"
											className={`bg-primary text-secondary border-none shadow-md rounded-xl p-2 px-4 ${
												isLargeScreenNavCollapsed ? "ml-3 " : "hidden"
											}`}
										>
											<Label>{item.label}</Label>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							) : (
								// USER MANAGEMENT MODULE
								<Fragment key={item.path}>
									<div className={`w-full ${isUserModuleOpen && "space-y-2"}`}>
										<TooltipProvider>
											<Tooltip delayDuration={100}>
												<TooltipTrigger className="w-full">
													<Button
														className={`w-full text-start mx-none group rounded-lg ${
															isUserModuleOpen
																? "bg-secondary text-white active:text-white hover:text-white focus:text-white"
																: "bg-transparent hover:bg-accent hover:text-secondary"
														}`}
														variant="sidenav"
														onClick={() =>
															setIsUserModuleOpen((prevValue) => !prevValue)
														}
													>
														<div className="flex flex-row w-full items-center justify-between">
															<div className="flex items-center gap-3">
																<item.icon
																	className="h-6 w-6"
																	fill="none"
																	outline={
																		isUserModuleOpen ? "white" : "#292d32"
																	}
																/>
																<span
																	className={`text-xs ${
																		isLargeScreenNavCollapsed
																			? "hidden"
																			: "inline"
																	}`}
																>
																	{item.label}
																</span>
															</div>

															{isUserModuleOpen ? (
																<ChevronUpIconSvg
																	fill="white"
																	className={`${
																		isLargeScreenNavCollapsed
																			? "h-4 w-4"
																			: "h-5 w-5"
																	}`}
																/>
															) : (
																<ChevronDownIconSvg
																	fill="#292d32"
																	className={`${
																		isLargeScreenNavCollapsed
																			? "h-4 w-4"
																			: "h-5 w-5"
																	}`}
																/>
															)}
														</div>
													</Button>
												</TooltipTrigger>
												<TooltipContent
													side="right"
													className={`bg-primary text-secondary border-none shadow-md rounded-xl p-2 px-4 mb-4 ${
														isLargeScreenNavCollapsed ? "ml-3" : "hidden"
													}`}
												>
													<Label>{item.label}</Label>
												</TooltipContent>
											</Tooltip>
										</TooltipProvider>

										<div
											className={`flex flex-col transition-all duration-150 gap-2 relative ${
												isUserModuleOpen
													? "h-[6em] opacity-100"
													: "h-0 opacity-0 scale-0 transform"
											}`}
										>
											{item.subModule.map((module) => {
												return (
													<div key={module.path} className="relative">
														<TooltipProvider>
															<Tooltip delayDuration={100}>
																<TooltipTrigger className="w-full">
																	<Button
																		className={`w-full text-start mx-none group rounded-lg  ${
																			pathSegments[1] === module.path
																				? "bg-secondary text-white active:text-white hover:text-white focus:text-white"
																				: "bg-transparent hover:bg-accent hover:text-secondary"
																		}`}
																		variant="sidenav"
																		onClick={() => navigate(module.path)}
																	>
																		<div className="flex flex-row w-full items-center justify-between">
																			<div className="flex items-center gap-3">
																				<SidebarSubmoduleIconSvg
																					className="h-6 w-6"
																					fill={
																						pathSegments[1] === module.path
																							? "#FFF200"
																							: "#292d32"
																					}
																					outline={
																						pathSegments[1] === module.path
																							? "#ED1D24"
																							: "#292d32"
																					}
																				/>
																				<span
																					className={`text-xs ${
																						isLargeScreenNavCollapsed
																							? "hidden"
																							: "inline"
																					}`}
																				>
																					{module.label}
																				</span>
																			</div>
																		</div>
																	</Button>
																</TooltipTrigger>
																<TooltipContent
																	side="right"
																	className={`bg-primary text-secondary border-none shadow-md rounded-xl p-2 px-4 ${
																		isLargeScreenNavCollapsed
																			? "ml-3"
																			: "hidden"
																	}`}
																>
																	<Label>{module.label}</Label>
																</TooltipContent>
															</Tooltip>
														</TooltipProvider>
													</div>
												);
											})}
										</div>
									</div>
								</Fragment>
							)}
						</Fragment>
					);
				})}
			</div>
		</>
	);
};

export default NavigationList;
