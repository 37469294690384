import { useState } from "react";
import { UseFormReturn } from "react-hook-form";

// Types
import { Banner } from "../utils/types";

// Components
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Label } from "src/shared/components/ui/label";
import { FormField } from "src/shared/components/form/Form";
import {
	RadioGroup,
	RadioGroupItem,
} from "src/shared/components/ui/radio-group";
import FormController from "src/shared/components/form/FormController";
import DateInput from "src/shared/components/textfield/DateInput";

type PromoDurationSectionProps = {
	form: UseFormReturn<Banner, any, undefined>;
};

const PromoDurationSection = ({ form }: PromoDurationSectionProps) => {
	const [isNoExpirationDate, setIsNoExpirationDate] = useState(false);

	return (
		<div className="flex flex-col lg:flex-row lg:flex-wrap gap-4">
			<Label variant="sub_header" className="w-full">
				Promo Duration*
			</Label>

			<div>
				<FormField
					control={form.control}
					name="from"
					render={({ field }) => (
						<FormController label="Start Date">
							<DateInput {...field} />
						</FormController>
					)}
				/>
			</div>

			<div className="flex flex-col-reverse gap-2 lg:flex-row lg:gap-4">
				<FormField
					control={form.control}
					name="to"
					render={({ field }) => (
						<FormController label="End Date">
							<DateInput disabled={isNoExpirationDate} {...field} />
						</FormController>
					)}
				/>
				<div className="flex flex-col gap-4">
					<Label variant="form">Expiration</Label>
					<div className="flex gap-1 items-center">
						<Checkbox
							className="w-3 h-3 lg:w-4 lg:h-4"
							checked={isNoExpirationDate}
							onCheckedChange={() => setIsNoExpirationDate(!isNoExpirationDate)}
						/>
						<Label variant="description">No Expiration</Label>
					</div>
				</div>
			</div>

			<div className="lg:flex-1">
				<FormField
					control={form.control}
					name="status"
					render={({ field }) => (
						<FormController
							label="Status"
							description="* Inactive promo banners will have their Display Order nullified by default and will not reflect in the app."
						>
							<RadioGroup
								onValueChange={field.onChange}
								value={String(field.value)}
								className="flex gap-4 mt-2"
							>
								<div className="flex items-center space-x-2">
									<RadioGroupItem value="1" id="active" />
									<Label
										htmlFor="active"
										variant="form"
										className="text-secondary"
									>
										Active
									</Label>
								</div>
								<div className="flex items-center space-x-2">
									<RadioGroupItem value="0" id="inactive" />
									<Label
										htmlFor="inactive"
										variant="form"
										className="text-secondary"
									>
										Inactive
									</Label>
								</div>
							</RadioGroup>
						</FormController>
					)}
				/>
			</div>
		</div>
	);
};

export default PromoDurationSection;
