import { useAuth } from "src/providers/AuthProvider";
import { Button } from "../../ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "src/shared/components/ui/dropdown-menu";
import LogoutIconSvg from "src/assets/svg/LogoutIconSvg";
import { Label } from "src/shared/components/ui/label";
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from "src/shared/components/ui/avatar";
import SidebarSettingsIconSvg from "src/assets/svg/sidebar/SidebarSettingsIconSvg";
import { useNavigate } from "react-router-dom";

const NavBarDropDown = () => {
	const navigate = useNavigate();
	const auth = useAuth();

	return (
		<DropdownMenuContent className="mr-4 w-[10em] sm:mr-6 lg:w-[13em] border-[1px] border-zinc-200 shadow-xl">
			<DropdownMenuLabel className="text-center flex-col font-normal leading-none">
				<Label variant="description">Last logged on</Label>
				<Label
					variant="description"
					className="font-normal block mt-[6px] text-center"
				>
					<span className="font-bold">15/08/2022 </span>
					at
					<span className="font-bold"> 16:40:51</span>
				</Label>
			</DropdownMenuLabel>
			<DropdownMenuSeparator />
			<div className="flex flex-col gap-1 h-fit">
				<div className="p-0 w-full group">
					<Button
						variant="sidenav"
						className="px-auto bg-transparent hover:bg-accent hover:text-secondary h-8 lg:h-10"
						onClick={() => navigate("/account")}
					>
						<SidebarSettingsIconSvg className="h-4 w-4 lg:h-5 lg:w-5 group-hover:cursor-pointer" />
						<Label variant="description" className="group-hover:cursor-pointer">
							Account Settings
						</Label>
					</Button>
				</div>
				<div className="p-0 w-full group mb-1" onClick={auth?.logout}>
					<Button
						variant="sidenav"
						className="px-auto bg-transparent hover:bg-accent hover:text-secondary h-8 lg:h-10"
					>
						<LogoutIconSvg
							fill="#292D32"
							className="h-3 w-3 ml-[2px] mr-[3px] lg:h-4 lg:w-4 group-hover:cursor-pointer"
						/>
						<Label variant="description" className="group-hover:cursor-pointer">
							Log-out
						</Label>
					</Button>
				</div>
			</div>
		</DropdownMenuContent>
	);
};

const ProfileAvatar = ({
	isLargeScreenNavCollapsed,
}: {
	isLargeScreenNavCollapsed: boolean;
}) => {
	const auth = useAuth();

	return (
		<div className="my-auto flex gap-2 items-center">
			<Label className="hidden xl:block">Hi, {auth?.authUser?.firstName}</Label>

			<div id="avatar" className={`h-fit w-fit overlow-hidden my-auto`}>
				<DropdownMenu>
					<DropdownMenuTrigger className="mr-2">
						<Avatar className={`${isLargeScreenNavCollapsed && "mx-auto"}`}>
							<AvatarImage src={`${auth?.authUser?.image}`} alt="ur-avatar" />
							<AvatarFallback>DIY</AvatarFallback>
						</Avatar>
					</DropdownMenuTrigger>
					<NavBarDropDown />
				</DropdownMenu>
			</div>
		</div>
	);
};

export default ProfileAvatar;
