const PlatformXIconSvg = ({ className }: { className?: string }) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_1746_9621)">
				<path
					d="M22.901 5H26.581L18.541 14.3201L28 27H20.594L14.794 19.3087L8.156 27H4.474L13.074 17.0309L4 5.00101H11.594L16.837 12.0311L22.901 5ZM21.61 24.7668H23.649L10.486 7.11654H8.298L21.61 24.7668Z"
					fill="#B6B6B6"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1746_9621">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default PlatformXIconSvg;
