import LoadingSvgIcon from "src/assets/svg/LoadingIconSvgIcon";

import MrDiyLogo from "src/assets/images/MRDIYlogo.png";
import Loading_logo from "src/assets/images/Loading_logo.png";

const Loading = () => {
	return (
		<div className="flex items-center justify-center h-full w-full">
			<div
				className="relative h-[15em] w-[15em] lg:w-[30em] xl:w-[31vw] xl:h-[31vh] border-[1px] border-none  items-center justify-center bg-no-repeat"
				style={{
					backgroundImage: `url(${Loading_logo})`,
					backgroundSize: "contain",
					backgroundPosition: "center",
				}}
			>
				<LoadingSvgIcon
					fill="#ffe444"
					className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[15em] h-[15em] sm:w-[25em] sm:h-[25em] lg:w-[30em] lg:h-[30em] lg:bottom-9 xl:w-[31vw] xl:h-[31vh] "
				/>
			</div>

			{/* SAMPLE 2 */}
			{/* <div className="relative max-w-[18em] w-full h-[50vh]">
				<div
					className="w-full h-full bg-contain bg-center bg-no-repeat"
					style={{
						backgroundImage: `url(${ShortLogo})`,
						filter: "grayscale(95%) brightness(0.5)", // Monochrome effect
					}}
				></div>
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
					<ImSpinner2 className="w-9 h-9 sm:w-12 sm:h-12 animate-spin text-primary" />
				</div>
			</div> */}
		</div>
	);
};

export default Loading;
