const ArrowRightIconSvg = ({
	fill,
	className,
}: {
	fill: string;
	className?: string;
}) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M20.681 12.8098L14.2022 19.6648C13.9993 19.8794 13.7242 20 13.4373 20C13.1504 20 12.8753 19.8794 12.6724 19.6648C12.4696 19.4502 12.3556 19.159 12.3556 18.8555C12.3556 18.552 12.4696 18.2609 12.6724 18.0462L17.3075 13.1439H4.07981C3.79343 13.1439 3.51877 13.0236 3.31627 12.8093C3.11377 12.595 3 12.3044 3 12.0014C3 11.6984 3.11377 11.4078 3.31627 11.1936C3.51877 10.9793 3.79343 10.8589 4.07981 10.8589H17.3075L12.6742 5.95376C12.4714 5.73913 12.3574 5.44802 12.3574 5.14449C12.3574 4.84095 12.4714 4.54985 12.6742 4.33521C12.8771 4.12058 13.1522 4 13.4391 4C13.726 4 14.0011 4.12058 14.204 4.33521L20.6828 11.1902C20.7835 11.2965 20.8634 11.4228 20.9178 11.5618C20.9722 11.7009 21.0002 11.8499 21 12.0004C20.9998 12.1509 20.9716 12.2998 20.9168 12.4387C20.8621 12.5776 20.782 12.7037 20.681 12.8098Z"
				fill={fill}
			/>
		</svg>
	);
};

export default ArrowRightIconSvg;
