const ButtonAddIconSvg = ({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 2C6.47581 2 2 6.47581 2 12C2 17.5242 6.47581 22 12 22C17.5242 22 22 17.5242 22 12C22 6.47581 17.5242 2 12 2ZM17.8065 13.129C17.8065 13.3952 17.5887 13.6129 17.3226 13.6129H13.6129V17.3226C13.6129 17.5887 13.3952 17.8065 13.129 17.8065H10.871C10.6048 17.8065 10.3871 17.5887 10.3871 17.3226V13.6129H6.67742C6.41129 13.6129 6.19355 13.3952 6.19355 13.129V10.871C6.19355 10.6048 6.41129 10.3871 6.67742 10.3871H10.3871V6.67742C10.3871 6.41129 10.6048 6.19355 10.871 6.19355H13.129C13.3952 6.19355 13.6129 6.41129 13.6129 6.67742V10.3871H17.3226C17.5887 10.3871 17.8065 10.6048 17.8065 10.871V13.129Z"
        fill={fill}
      />
      <path
        d="M13.6129 13.4129H13.4129V13.6129V17.3226C13.4129 17.4783 13.2847 17.6065 13.129 17.6065H10.871C10.7153 17.6065 10.5871 17.4783 10.5871 17.3226V13.6129V13.4129H10.3871H6.67742C6.52175 13.4129 6.39355 13.2847 6.39355 13.129V10.871C6.39355 10.7153 6.52175 10.5871 6.67742 10.5871H10.3871H10.5871V10.3871V6.67742C10.5871 6.52175 10.7153 6.39355 10.871 6.39355H13.129C13.2847 6.39355 13.4129 6.52175 13.4129 6.67742V10.3871V10.5871H13.6129H17.3226C17.4783 10.5871 17.6065 10.7153 17.6065 10.871V13.129C17.6065 13.2847 17.4783 13.4129 17.3226 13.4129H13.6129ZM12 1.8C6.36535 1.8 1.8 6.36535 1.8 12C1.8 17.6346 6.36535 22.2 12 22.2C17.6347 22.2 22.2 17.6347 22.2 12C22.2 6.36535 17.6346 1.8 12 1.8Z"
        stroke="#292D32"
        strokeOpacity="0.5"
        strokeWidth="0.4"
        style={{ mixBlendMode: "overlay" }}
      />
    </svg>
  );
};

export default ButtonAddIconSvg;
