import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Type
import { Category } from "../utils/types";

// Data
import categoriesArray from "../../../data/categories/categories.json";

// TODO: Change this to api call
export const categoryApi = createApi({
	reducerPath: "categoryApi",
	tagTypes: ["Categories", "Category"],
	baseQuery: fetchBaseQuery({ baseUrl: "/" }),
	endpoints: (builder) => ({
		getCategories: builder.query<Category[], void>({
			queryFn() {
				return new Promise<{ data: Category[] }>((resolve, reject) => {
					try {
						setTimeout(() => {
							const categories = categoriesArray.map((category) => category);

							resolve({ data: categories });
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
		}),
		getSelectedCategory: builder.query<Category, { id: number }>({
			async queryFn({ id }) {
				return new Promise<{ data: Category }>((resolve, reject) => {
					try {
						setTimeout(() => {
							const selectedCategory = categoriesArray.find(
								(category) => id === category.id
							);
							resolve({
								data: selectedCategory as Category,
							});
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			keepUnusedDataFor: 0,
		}),
		addCategory: builder.mutation<void, Category>({
			query: (newCategory) => ({
				url: "/new",
				method: "POST",
				body: newCategory,
			}),
			invalidatesTags: ["Categories"],
		}),
		updateCategory: builder.mutation<void, Category>({
			query: (updatedCategory) => ({
				url: `/update/${updatedCategory.id}`,
				method: "PUT",
				body: updatedCategory,
			}),
			invalidatesTags: ["Categories"],
		}),
		deleteCategory: builder.mutation<void, string>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Categories"],
		}),
	}),
});

export const {
	useGetCategoriesQuery,
	useGetSelectedCategoryQuery,
	useAddCategoryMutation,
	useUpdateCategoryMutation,
	useDeleteCategoryMutation,
} = categoryApi;

// TODO: Integrate force refetch. combine active and inactive if needs to refetch the data everytime
// forceRefetch
// Prevents caching
// keepUnusedDataFor: 0,
// providesTags: [{ type: "Categories", id: "ACTIVE" }],
