import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetSelectedUserQuery } from "./slices/userApiSlice";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { User } from "./utils/types";
import { userFormSchema } from "./utils/schema";

// Component
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "src/shared/components/ui/select";
import {
	RadioGroup,
	RadioGroupItem,
} from "src/shared/components/ui/radio-group";
import { Form, FormField } from "src/shared/components/form/Form";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import FormController from "src/shared/components/form/FormController";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";

const UserForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { userId } = useParams<{ userId: string }>();

	const { data: selectedUser, isFetching: isSelectedUserFetching } =
		useGetSelectedUserQuery({ id: Number(userId) });

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

	const form = useForm<User>({
		resolver: zodResolver(userFormSchema),
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			role: "",
			status: 1,
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		navigate("/user");

		form.reset();
	};

	const _onCancelPress = () => {
		if (!isDirty) navigate("/user");

		setIsLeaveModalOpen(true);
	};

	const _onSubmit = (values: User) => {
		console.log({ values });
	};

	// Update form values when selectedUser data is available
	useEffect(() => {
		if (selectedUser) {
			form.reset({
				firstName: selectedUser.firstName,
				lastName: selectedUser.lastName,
				email: selectedUser.email,
				role: selectedUser.role,
				status: selectedUser.status,
			});
		}
	}, [selectedUser, form.reset]);

	// * Reroute to UserPage/Table if id is invalid/no user
	if (!userId && pathSegments[2] != "add") {
		navigate("/user", { replace: true });
	}

	// * Avoid loading/fetching state if the form is for adding
	if (isSelectedUserFetching) {
		return <Loading />;
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(_onSubmit)}>
				<PageContainer>
					<FormHeader>
						<Label variant="title">User Management</Label>
						<div className="w-full h-fit sm:w-fit flex gap-2">
							<Button type="submit" disabled={!isDirty || !isValid}>
								<ButtonCheckIconSvg
									className="h-5 w-5"
									fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
								/>
								Save
							</Button>
							<Button
								variant="secondary"
								size="md"
								type="reset"
								onClick={_onCancelPress}
							>
								Cancel
							</Button>
						</div>
					</FormHeader>
					<SectionContainer className="lg:grid-cols-3">
						<SectionHeader className="lg:col-span-3">
							<div className="flex flex-col space-y-0 lg:gap-2">
								<Label variant="header">
									{!selectedUser?.id
										? "Add User Information"
										: "Edit User Information"}
								</Label>
								{selectedUser?.id && (
									<Label variant="description">
										Last edited on <span className="font-bold">15/08/2022</span>{" "}
										at <span className="font-bold">16:40:51</span>
									</Label>
								)}
							</div>
						</SectionHeader>

						<FormField
							control={form.control}
							name="firstName"
							render={({ field }) => (
								<FormController label="First Name">
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="lastName"
							render={({ field }) => (
								<FormController label="Last Name">
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="email"
							render={({ field }) => (
								<FormController label="Email">
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="role"
							render={({ field }) => (
								<FormController label="Role">
									<Select onValueChange={field.onChange} value={field.value}>
										<SelectTrigger>
											<SelectValue placeholder="Default Option" />
										</SelectTrigger>
										<SelectContent>
											<SelectGroup>
												<SelectLabel className="font-normal">
													Default Option
												</SelectLabel>
												<SelectItem value="Accounting">Accounting</SelectItem>
												<SelectItem value="Marketing">Marketing</SelectItem>
											</SelectGroup>
										</SelectContent>
									</Select>
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="status"
							render={({ field }) => (
								<FormController
									label="Status"
									description="* Inactive promo banners will have their Display Order nullified by default and will not reflect in the app."
								>
									<RadioGroup
										onValueChange={field.onChange}
										value={String(field.value)}
										className="flex gap-4 mt-2"
									>
										<div className="flex items-center space-x-2">
											<RadioGroupItem value="1" id="active" />
											<Label
												htmlFor="active"
												variant="form"
												className="text-secondary"
											>
												Active
											</Label>
										</div>
										<div className="flex items-center space-x-2">
											<RadioGroupItem value="0" id="inactive" />
											<Label
												htmlFor="inactive"
												variant="form"
												className="text-secondary"
											>
												Inactive
											</Label>
										</div>
									</RadioGroup>
								</FormController>
							)}
						/>
					</SectionContainer>
				</PageContainer>
			</form>
			<ConfirmationDialog
				title="Leave page?"
				description="Changes are not yet saved."
				confirmButtonLabel="Leave"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>
		</Form>
	);
};

export default UserForm;
