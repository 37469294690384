import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useGetSelectedProductsQuery } from "../slices/productApiSlice";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { Product } from "../utils/types";

// Component
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Form, FormField } from "src/shared/components/form/Form";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "src/shared/components/ui/select";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import FormController from "src/shared/components/form/FormController";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";

// Image & Icons
import image from "src/assets/images/image.png";
import ButtonEditIconSvg from "src/assets/svg/ButtonEditIconSvg";
import CalendarIconSvg from "src/assets/svg/CalendarIconSvg";
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import { X } from "lucide-react";
import { ProductFormSchema } from "../utils/schema";

const ProductForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { productId } = useParams<{ productId: string }>();

	const { data: selectedProduct, isFetching: isSelectedProductFetching } =
		useGetSelectedProductsQuery({ id: Number(productId) });

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isDeleteImageModalOpen, setIsDeleteImageModalOpen] = useState(false);
	const [isNoExpirationDate, setIsNoExpirationDate] = useState(false);
	const [selectedImage, setSelectedImage] = useState(0);

	const form = useForm<Product>({
		resolver: zodResolver(ProductFormSchema),
		defaultValues: {
			sku: 0,
			images: [],
			color: "#FFFFFF",
			name: "",
			category: 0,
			description: "",
			variations: 1,
			stocks: 0,
			price: 0.0,
			discounted_price: 0.0,
			size: "0",
			startDate: "",
			endDate: "",
		},
		mode: "onBlur",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const handleFileChange = (file: File, index: number) => {
		if (file) {
			const imageUrl = URL.createObjectURL(file);
			let currentImages = form.getValues("images") || [];

			// Ensure array is of length 5
			if (currentImages.length < 5) {
				currentImages = [
					...currentImages,
					...Array(5 - currentImages.length).fill(""),
				];
			}

			// Find the first empty slot
			const firstEmptyIndex = currentImages.findIndex((img) => !img);

			if (firstEmptyIndex !== -1) {
				// If there's an empty slot, use it
				currentImages[firstEmptyIndex] = imageUrl;
			} else {
				// If no empty slots, use the specified index
				currentImages[index] = imageUrl;
			}

			form.setValue("images", currentImages);
		}
	};

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		navigate("/product");

		form.reset();
	};

	const _onCancelLeave = () => {
		if (!isDirty) navigate("/product");

		setIsLeaveModalOpen(true);
	};

	const _onConfirmDeleteImage = () => {
		const images = form.getValues("images");

		if (Array.isArray(images)) {
			// Remove the image at selectedImage index
			images.splice(selectedImage, 1);

			// Add a null/empty value at the end to maintain array length of 5
			images.push("");

			form.setValue("images", images);
		}

		setIsDeleteImageModalOpen(false);
	};

	const _onDeleteImagePress = (index: number) => {
		setSelectedImage(index);
		setIsDeleteImageModalOpen(true);
	};

	const _onSubmit = (values: Product) => {
		console.log({ values });
	};

	// Update form values when selectedProduct data is available
	useEffect(() => {
		if (selectedProduct) {
			form.reset({
				sku: selectedProduct.sku,
				images: selectedProduct.images,
				color: selectedProduct.color,
				name: selectedProduct.name,
				category: selectedProduct.category,
				description: selectedProduct.description,
				variations: selectedProduct.variations,
				stocks: selectedProduct.stocks,
				price: selectedProduct.price,
				discounted_price: selectedProduct.discounted_price,
				size: selectedProduct.size,
				startDate: selectedProduct.startDate,
				endDate: selectedProduct.endDate,
			});
		}
	}, [selectedProduct, form.reset]);

	if (!productId && pathSegments[2] != "add") {
		navigate("/product", { replace: true });
	}

	if (isSelectedProductFetching) {
		return <Loading />;
	}

	// TODO ADD EDIT ROUTE

	return (
		<Form {...form}>
			<div className="h-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer className="space-y-4">
						<FormHeader>
							<Label variant="title">Product Management</Label>
							<div className="w-full h-fit sm:w-fit flex gap-2">
								<Button type="submit" disabled={!isDirty || !isValid}>
									<ButtonCheckIconSvg
										className="h-5 w-5"
										fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
									/>
									Save
								</Button>
								<Button
									variant="secondary"
									size="md"
									type="reset"
									onClick={_onCancelLeave}
								>
									Cancel
								</Button>
							</div>
						</FormHeader>

						<div className="grid lg:grid-cols-3 gap-4">
							<Label variant="sub_header" className="col-span-3">
								Product Details
							</Label>
							<div className="col-span-3 lg:col-span-1">
								<FormField
									control={form.control}
									name="sku"
									render={({ field }) => (
										<FormController label="SKU">
											<Input {...field} placeholder="-" />
										</FormController>
									)}
								/>
							</div>
							<div className="col-span-3 lg:col-span-1">
								<FormField
									control={form.control}
									name="name"
									render={({ field }) => (
										<FormController label="Name">
											<Input {...field} placeholder="-" />
										</FormController>
									)}
								/>
							</div>
							<div className="col-span-3 lg:col-span-1">
								<FormField
									control={form.control}
									name="category"
									render={({ field }) => (
										<FormController label="Category">
											<Select
												onValueChange={field.onChange}
												defaultValue={String(field.value)}
											>
												<SelectTrigger
													className={`${
														!field.value ? "text-gray-300" : "text-secondary"
													}`}
												>
													<SelectValue placeholder="Select a category" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectItem value="0" disabled>
															No Category
														</SelectItem>
														<SelectItem value="1">Option 1</SelectItem>
														<SelectItem value="2">Option 2</SelectItem>
													</SelectGroup>
												</SelectContent>
											</Select>
										</FormController>
									)}
								/>
							</div>
							<div className="col-span-3">
								<FormField
									control={form.control}
									name="description"
									render={({ field }) => (
										<FormController label="Description">
											<Input {...field} placeholder="-" />
										</FormController>
									)}
								/>
							</div>
						</div>

						<div className="grid lg:grid-cols-2 gap-4">
							<Label variant="sub_header" className="lg:col-span-2">
								Price
							</Label>

							<div className="w-full flex gap-6 ml-auto justify-end items-center">
								<div className="relative w-full">
									<FormField
										control={form.control}
										name="price"
										render={({ field }) => (
											<FormController label="Original">
												<Input
													type="number"
													step="0.01"
													{...field}
													value={
														String(field.value) === "0" ? "0.00" : field.value
													}
													onChange={(e) => {
														const value = e.target.value || "0.00";
														field.onChange(value);
													}}
												/>
											</FormController>
										)}
									/>
									<Button
										variant="icon"
										size="icon"
										className="absolute right-1 top-11 transform -translate-y-1/2 text-disabled-foreground"
										onClick={() => form.resetField("price")}
									>
										<X className=" w-[14px] h-[14px]" />
									</Button>
								</div>
							</div>

							<div className="w-full flex gap-6 ml-auto justify-end items-center">
								<div className="relative w-full">
									<FormField
										control={form.control}
										name="discounted_price"
										render={({ field }) => (
											<FormController label="Discounted">
												<Input
													type="number"
													step="0.01"
													{...field}
													value={
														String(field.value) === "0" ? "0.00" : field.value
													}
													onChange={(e) => {
														const value = e.target.value || "0.00";
														field.onChange(value);
													}}
												/>
											</FormController>
										)}
									/>
									<Button
										variant="icon"
										size="icon"
										className="absolute right-1 top-11 transform -translate-y-1/2 text-disabled-foreground"
										onClick={() => form.resetField("discounted_price")}
									>
										<X className=" w-[14px] h-[14px]" />
									</Button>
								</div>
							</div>
						</div>

						<div className="grid lg:grid-cols-3 gap-4">
							<Label variant="sub_header" className="w-full lg:col-span-3">
								Discount Duration
							</Label>

							<div className="w-full flex gap-6">
								<div className="relative w-full">
									<FormField
										control={form.control}
										name="startDate"
										render={({ field }) => (
											<FormController label="Discount Start Date">
												<Input
													placeholder="--/--/----"
													className="pr-6 lg:pr-8"
													{...field}
												/>
											</FormController>
										)}
									/>
									<CalendarIconSvg
										className="absolute right-1 top-11 transform -translate-y-1/2 w-4 h-4 text-disabled-foreground"
										fill="#292D32"
									/>
								</div>
							</div>

							<div className="flex flex-col-reverse gap-2 lg:flex-row lg:col-span-2 lg:gap-4">
								<div className="w-full flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
									<div className="relative w-full row-start-1 sm:row-start-auto">
										<FormField
											control={form.control}
											name="endDate"
											render={({ field }) => (
												<FormController label="Discount End Date">
													<Input
														placeholder="--/--/----"
														className="pr-6 lg:pr-8"
														disabled={isNoExpirationDate}
														{...field}
													/>
												</FormController>
											)}
										/>
										<CalendarIconSvg
											className="absolute right-1 top-11 transform -translate-y-1/2 w-4 h-4 text-disabled-foreground"
											fill={isNoExpirationDate ? "#c9c9c9" : "#292D32"}
										/>
									</div>
								</div>

								<div className="flex flex-col w-full gap-4">
									<Label variant="form">Expiration</Label>
									<div className="flex gap-1 lg:gap-2 items-center">
										<Checkbox
											className="w-3 h-3 lg:w-4 lg:h-4"
											checked={isNoExpirationDate}
											onCheckedChange={() =>
												setIsNoExpirationDate(!isNoExpirationDate)
											}
										/>
										<Label variant="description">No Expiration</Label>
									</div>
								</div>
							</div>
						</div>

						<div className="flex flex-col space-y-2">
							<Label variant="sub_header">Product Images</Label>
							<Label variant="form" className="my-4 h-3">
								Images
							</Label>
							<div className="grid gap-4 xl:max-w-[71vw] 2xl:max-w-full overflow-auto">
								<FormField
									control={form.control}
									name="images"
									render={({ field: { value, onChange, ...fieldProps } }) => (
										<FormController>
											<div className="flex flex-row gap-2 lg:gap-4 space-evenly h-40 lg:h-60 w-full overflow-auto">
												{Array(5)
													.fill(null)
													.map((_, index) => {
														const productImage = value?.[index] || image;

														return (
															<div
																key={index}
																className={`relative h-40 w-40 lg:h-60 lg:w-60 aspect-square rounded-lg border-[1px] border-gray-400 group hover:bg-gray-900/50 transform-all duration-300 overflow-hidden ${
																	!value?.[index] ? "p-10 lg:p-16" : "p-0"
																}`}
															>
																<div
																	key={index}
																	className={`${
																		!value?.[index]
																			? "bg-contain w-18"
																			: "bg-cover"
																	} bg-center bg-no-repeat aspect-square -z-10`}
																	style={{
																		backgroundImage: `url('${productImage}')`,
																	}}
																/>

																{value?.[index] && (
																	<div className="hidden absolute top-0 h-40 w-40 lg:h-60 lg:w-60 z-10 group-hover:bg-gray-900/50 group-hover:flex aspect-square rounded-lg transform-all duration-300" />
																)}

																<div className="z-40 absolute bottom-1 left-1">
																	<Input
																		id={`input-${index}`}
																		type="file"
																		className="hidden"
																		{...fieldProps}
																		onChange={(
																			e: React.ChangeEvent<HTMLInputElement>
																		) => {
																			const file = e.target.files?.[0];
																			if (file) {
																				handleFileChange(file, index);
																			}
																		}}
																	/>
																	<Button
																		type="button"
																		onClick={() =>
																			document
																				.getElementById(`input-${index}`)
																				?.click()
																		}
																		className="px-[10px] py-[4px] lg:px-[18px] lg:py-2 bg-primary rounded-full hidden group-hover:flex hover:bg-primary"
																		variant="icon"
																		size="icon"
																	>
																		<ButtonEditIconSvg
																			fill="#ffe444"
																			className="h-3 w-3 p-[3px] lg:h-5 lg:w-5 lg:p-[4px] bg-secondary rounded-full"
																		/>
																	</Button>
																</div>

																<div className="z-40 absolute bottom-1 right-1">
																	<Button
																		type="button"
																		disabled={!value?.[index]}
																		onClick={() => _onDeleteImagePress(index)}
																		className={`px-[10px] py-[4px] lg:px-[18px] lg:py-2 rounded-full hidden group-hover:flex ${
																			!value?.[index]
																				? "bg-disabled hover:bg-disabled"
																				: "bg-destructive hover:bg-destructive"
																		}`}
																		variant="icon"
																		size="icon"
																	>
																		<ButtonDeleteIconSvg
																			fill={`${
																				!value?.[index] ? "#cbcbcc" : "white"
																			}`}
																			className="h-3 w-3 lg:h-5 lg:w-5"
																		/>
																	</Button>
																</div>
															</div>
														);
													})}
											</div>
											{/* </div> */}
										</FormController>
									)}
								/>
							</div>
							<div className="flex flex-col gap-1 lg:gap-0 leading-tight tracking-tight md:tracking-normal md:leading-normal text-secondary text-justify">
								<Label variant="description" className="lg:text-[0.70rem]">
									&bull; Recommended image resolution is minimum of{" "}
									<span className="font-bold">360px</span> by{" "}
									<span className="font-bold">360px</span>
								</Label>
								<Label variant="description" className="lg:text-[0.70rem]">
									&bull; Image file size should be no more than{" "}
									<span className="font-bold">2mb</span>
								</Label>
								<Label variant="description" className="lg:text-[0.70rem]">
									&bull; Allowed image formats are{" "}
									<span className="font-bold">.jpg</span> and{" "}
									<span className="font-bold">.png</span> only
								</Label>
							</div>
						</div>
					</PageContainer>
				</form>
			</div>

			<ConfirmationDialog
				title="Leave page?"
				description="Changes are not yet saved."
				confirmButtonLabel="Leave"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>

			<ConfirmationDialog
				title="Delete Product Image?"
				description="Product Image will be deleted."
				confirmButtonIcon={ButtonDeleteIconSvg}
				confirmButtonLabel="Delete"
				closeButtonLabel="Cancel"
				modalState={isDeleteImageModalOpen}
				_onCancel={() => setIsDeleteImageModalOpen(false)}
				_onConfirm={_onConfirmDeleteImage}
			/>
		</Form>
	);
};

export default ProductForm;
