import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useGetSelectedBannerQuery } from "./slices/bannerApiSlice";

// Libraries
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { Banner } from "./utils/types";
import { BannerfFormSchema } from "./utils/schema";

// Component
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";

import { Form } from "src/shared/components/form/Form";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";

// Icons
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import BannerInformationSection from "./fragments/BannerInformationSection";
import PromoDurationSection from "./fragments/PromoDurationSection";
import ArrowRightIconSvg from "src/assets/svg/ArrowRightIconSvg";
import ProductListSection from "./fragments/ProductListSection";
import Loading from "src/shared/components/loading-indicator/Loading";

const BannerForm = () => {
	const navigate = useNavigate();
	const { bannerId } = useParams<{ bannerId: string }>();

	const { data: selectedBanner, isFetching: isSelectedBannerFetching } =
		useGetSelectedBannerQuery({ id: Number(bannerId) });

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

	const form = useForm<Banner>({
		resolver: zodResolver(BannerfFormSchema),
		defaultValues: {
			id: 0,
			title: "",
			image: "",
			description: "",
			products: 0,
			from: "",
			to: "",
			order: 0,
			category: 0,
			status: 0,
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _navigateToAddProducts = () => {
		if (!bannerId) {
			navigate("products");
		} else {
			navigate("add/products");
		}
	};

	const _onConfirmLeave = () => {
		navigate("/banner");

		form.reset();
	};

	const _onCancelPress = () => {
		if (!isDirty) navigate("/banner");
		setIsLeaveModalOpen(true);
	};

	const _onSubmit = (values: z.infer<typeof BannerfFormSchema>) => {
		console.log({ values });
	};

	useEffect(() => {
		if (selectedBanner) {
			form.reset(selectedBanner, {
				keepDirty: false,
				keepTouched: false,
				keepIsValid: false,
				keepErrors: false,
			});
		}
	}, [selectedBanner, form]);

	if (isSelectedBannerFetching) {
		return <Loading />;
	}

	return (
		<Form {...form}>
			<div className="h-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer>
						<FormHeader>
							<Label variant="title">
								{!selectedBanner?.id ? "New Promo Banner" : "Edit Promo Banner"}
							</Label>
							<div className="w-full h-fit sm:w-fit flex gap-2">
								<Button type="submit" disabled={!isDirty || !isValid}>
									<ButtonCheckIconSvg
										className="h-5 w-5"
										fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
									/>
									Save
								</Button>
								<Button
									variant="secondary"
									size="md"
									type="reset"
									onClick={_onCancelPress}
								>
									Cancel
								</Button>
							</div>
						</FormHeader>
						<div className="grid gap-4 xl:gap-6 w-full h-fit xl:h-full">
							<div className="flex flex-col gap-4 w-full my-1 justify-start items-start sm:flex-row sm:my-4 sm:justify-between sm:items-center lg:my-6">
								<Label variant="header">Banner Information</Label>
								<Button size="md" onClick={_navigateToAddProducts}>
									Add Products
									<ArrowRightIconSvg className="h-5 w-5" fill="#292D32" />
								</Button>
							</div>
							<BannerInformationSection form={form} />
							<PromoDurationSection form={form} />
						</div>

						<ProductListSection />
					</PageContainer>

					<ConfirmationDialog
						title="Leave page?"
						description="Changes are not yet saved."
						confirmButtonLabel="Leave"
						closeButtonLabel="Cancel"
						modalState={isLeaveModalOpen}
						_onCancel={() => setIsLeaveModalOpen(false)}
						_onConfirm={_onConfirmLeave}
					/>
				</form>
			</div>
		</Form>
	);
};

export default BannerForm;
