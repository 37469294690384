import { useState } from "react";
import { useRolesColumns } from "./columns";
import { useGetRolesQuery } from "./slices/roleApiSlice";
import { useNavigate } from "react-router-dom";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import PageControls from "src/shared/components/control/PageControls";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import SearchInput from "src/shared/components/textfield/SearchInput";
import Loading from "src/shared/components/loading-indicator/Loading";

// Icons
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import DesignBackground from "src/shared/components/background/DesignBackground";

const RolePage = () => {
	const navigate = useNavigate();

	// DATA
	const { data: rolesArray = [], isFetching: isRolesArrayFecthing } =
		useGetRolesQuery();

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedRole, setSelectedRole] = useState({
		id: 0,
		name: "",
	});
	const [searchValue, setSearchValue] = useState("");
	const [isDisabled, setIsDisabled] = useState(true);

	const _onOpenDeleteModal = (id: number, name: string) => {
		setIsDeleteDialogOpen(true);
		setSelectedRole({
			id: id,
			name: name,
		});
	};

	const _onCloseDeleteModal = () => {
		setIsDeleteDialogOpen(false);
	};

	const _onConfirmDelete = async () => {
		// const updatedArray = await _deleteUser(selectedUser.id);

		// console.log("Updated Array: ", updatedArray);
		setIsDeleteDialogOpen(false);
	};

	const columns = useRolesColumns(_onOpenDeleteModal);

	if (isRolesArrayFecthing) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Roles & Permission</Label>
			</PageHeader>
			<PageControls>
				<div className="flex gap-2 w-full sm:w-[15em]">
					<Button onClick={() => navigate("add")}>
						<ButtonAddIconSvg fill="#292d32" className="h-6 w-6" />
						Add Roles
					</Button>
					<Button variant="destructive" size="md" disabled={isDisabled}>
						<ButtonDeleteIconSvg
							fill={`${isDisabled ? "#cbcbcc" : "white"}`}
							className="h-5 w-5 mb-[2px]"
						/>
						Delete
					</Button>
				</div>
				<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<SearchInput setSearchValue={setSearchValue} />
				</div>
			</PageControls>

			<div className="mt-8">
				<DataTable data={rolesArray} columns={columns} />
			</div>

			<ConfirmationDialog
				title="Delete Role?"
				description={`Role ${selectedRole.name} will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteDialogOpen}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>
		</PageContainer>
	);
};

export default RolePage;
