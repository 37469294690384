import * as React from "react";

const LoadingSvgIcon = ({
	fill,
	className,
}: {
	fill?: string;
	className?: string;
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		id="vectoroutline - MRDIY"
		width="211"
		height="39"
		fill="none"
		style={{ maxWidth: "100%", maxHeight: "100%" }}
		viewBox="0 0 211 39"
		className={className}
	>
		<path
			fillRule="evenodd"
			stroke={fill}
			strokeDasharray="0,0,0,616.445556640625"
			strokeWidth="2"
			d="M2.25 31.233V12c0-.556 0-1.667-1-5C1.12 6.567 1 6.026 1 5.5A4.5 4.5 0 0 1 5.5 1h16c1.96 0 3.5 1 4.25 3l2 4.5L29.256 4A4.5 4.5 0 0 1 33.5 1H49c.946 0 1.815.328 2.5.877A3.98 3.98 0 0 1 54 1h27c6.075 0 11 3.806 11 8.5v5.3c1.254-.502 2.812-.8 4.5-.8s3.246.298 4.5.8V12c0-.496 0-1.437-1.25-4.01-.182-.378-.25-.49-.494-.99C99 6.5 99 6.026 99 5.5a4.5 4.5 0 0 1 4.5-4.5h27c6.168 0 11.5 4.5 11.5 10v10.17c.477-.111.98-.17 1.5-.17 1.33 0 2.549.386 3.5 1.029V12c0-.496 0-1.437-1.249-4.01-.212-.437-.251-.49-.482-.953-.203-.409-.269-1.011-.269-1.537a4.5 4.5 0 0 1 4.5-4.5H164c.946 0 1.815.328 2.5.877A3.98 3.98 0 0 1 169 1h16a4 4 0 0 1 3.939 4.702L191 9l2.159-2.879A4 4 0 0 1 197 1h9c2 0 4 2.184 4 4.5a4.48 4.48 0 0 1-1.337 3.2l-8.664 12.432a8.5 8.5 0 1 1-2.41 15.917A3.98 3.98 0 0 1 195 38h-15a4 4 0 0 1-3.2-1.6c-1.095.972-2.837 1.6-4.8 1.6s-3.705-.628-4.8-1.6A4 4 0 0 1 164 38h-13.5c-1.472 0-2.779-.628-3.6-1.6-1.003.972-2.601 1.6-4.4 1.6-2.255 0-4.193-.987-5.042-2.399-.94 1.323-2.958 2.399-5.458 2.387L103 38c-1 0-1.706-.432-2.364-.914-1.123.57-2.565.914-4.136.914s-3.013-.343-4.136-.914C91.707 37.568 91 38 90 38H75.5c-1.414 0-2.675-.58-3.5-1.486-.825.907-2.086 1.486-3.5 1.486h-14c-1.153 0-2.204-.385-3-1.019-.796.634-1.847 1.019-3 1.019h-14c-1.153 0-2.204-.385-3-1.019-.796.634-1.848 1.019-3 1.019h-9c-1.152 0-2.204-.385-3-1.019-.796.634-1.848 1.019-3 1.019h-8C3.015 38 1 36.21 1 34c0-1.074.476-2.048 1.25-2.767Zm172.859-9.583L167 10.292v12.72c1.075-1.213 2.913-2.012 5-2.012 1.138 0 2.202.238 3.109.65Z"
			clipRule="evenodd"
		>
			<animate
				fill="freeze"
				attributeName="stroke-dasharray"
				attributeType="XML"
				dur="3.8461538461538463s"
				keyTimes="0; 0.5; 1"
				repeatCount="1"
				values="0,0,0,616.445556640625; 0,308.2227783203125,308.2227783203125,0; 616.445556640625,0,0,0"
			></animate>
		</path>
	</svg>
);

export default LoadingSvgIcon;
