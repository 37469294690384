const PlatformYoutubeIconSvg = ({ className }: { className?: string }) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_1746_8778)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16 6.40039C17.026 6.40039 18.0784 6.42679 19.0984 6.46999L20.3032 6.52759L21.4564 6.59599L22.5364 6.66919L23.5228 6.74599C24.5934 6.82746 25.601 7.28411 26.3679 8.03551C27.1349 8.78691 27.6122 9.78486 27.7156 10.8536L27.7636 11.3636L27.8536 12.4556C27.9376 13.5872 28 14.8208 28 16.0004C28 17.18 27.9376 18.4136 27.8536 19.5452L27.7636 20.6372L27.7156 21.1472C27.6121 22.2161 27.1347 23.2142 26.3675 23.9656C25.6003 24.7171 24.5924 25.1736 23.5216 25.2548L22.5376 25.3304L21.4576 25.4048L20.3032 25.4732L19.0984 25.5308C18.0662 25.5755 17.0332 25.5987 16 25.6004C14.9668 25.5987 13.9338 25.5755 12.9016 25.5308L11.6968 25.4732L10.5436 25.4048L9.4636 25.3304L8.4772 25.2548C7.40658 25.1733 6.39904 24.7167 5.63205 23.9653C4.86507 23.2139 4.38783 22.2159 4.2844 21.1472L4.2364 20.6372L4.1464 19.5452C4.055 18.3657 4.00617 17.1834 4 16.0004C4 14.8208 4.0624 13.5872 4.1464 12.4556L4.2364 11.3636L4.2844 10.8536C4.38779 9.78505 4.86485 8.78725 5.6316 8.03588C6.39834 7.2845 7.40559 6.82773 8.476 6.74599L9.4612 6.66919L10.5412 6.59599L11.6956 6.52759L12.9004 6.46999C13.933 6.42526 14.9664 6.40206 16 6.40039ZM13.6 13.0904V18.9104C13.6 19.4648 14.2 19.8104 14.68 19.5344L19.72 16.6244C19.8297 16.5613 19.9208 16.4703 19.9841 16.3608C20.0475 16.2512 20.0808 16.1269 20.0808 16.0004C20.0808 15.8738 20.0475 15.7495 19.9841 15.64C19.9208 15.5304 19.8297 15.4395 19.72 15.3764L14.68 12.4676C14.5705 12.4044 14.4463 12.3711 14.3199 12.3711C14.1935 12.3711 14.0693 12.4045 13.9598 12.4677C13.8503 12.531 13.7594 12.6219 13.6963 12.7314C13.6331 12.8409 13.5999 12.9652 13.6 13.0916V13.0904Z"
					fill="#B6B6B6"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1746_8778">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default PlatformYoutubeIconSvg;
