const ImportIconSvg = ({
  fill,
  className,
}: {
  fill?: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.5 21.1067C3.83696 21.1067 3.20107 20.8433 2.73223 20.3745C2.26339 19.9057 2 19.2698 2 18.6067V15.0353C2 14.7511 2.11288 14.4786 2.31381 14.2777C2.51475 14.0768 2.78727 13.9639 3.07143 13.9639C3.35559 13.9639 3.62811 14.0768 3.82904 14.2777C4.02997 14.4786 4.14286 14.7511 4.14286 15.0353V18.6067C4.14286 18.8039 4.30286 18.9639 4.5 18.9639H19.5C19.5947 18.9639 19.6856 18.9262 19.7525 18.8593C19.8195 18.7923 19.8571 18.7014 19.8571 18.6067V15.0353C19.8571 14.7511 19.97 14.4786 20.171 14.2777C20.3719 14.0768 20.6444 13.9639 20.9286 13.9639C21.2127 13.9639 21.4853 14.0768 21.6862 14.2777C21.8871 14.4786 22 14.7511 22 15.0353V18.6067C22 19.2698 21.7366 19.9057 21.2678 20.3745C20.7989 20.8433 20.163 21.1067 19.5 21.1067H4.5Z"
        fill={fill}
      />
      <path
        d="M17.4007 7.84951C17.5002 7.94894 17.579 8.06698 17.6328 8.19689C17.6867 8.3268 17.7143 8.46604 17.7143 8.60665C17.7143 8.74727 17.6867 8.88651 17.6328 9.01642C17.579 9.14633 17.5002 9.26437 17.4007 9.3638C17.3013 9.46323 17.1833 9.5421 17.0533 9.59591C16.9234 9.64972 16.7842 9.67742 16.6436 9.67742C16.503 9.67742 16.3637 9.64972 16.2338 9.59591C16.1039 9.5421 15.9859 9.46323 15.8864 9.3638L13.0722 6.55094V14.6781C13.0722 14.9622 12.9593 15.2348 12.7583 15.4357C12.5574 15.6366 12.2849 15.7495 12.0007 15.7495C11.7166 15.7495 11.444 15.6366 11.2431 15.4357C11.0422 15.2348 10.9293 14.9622 10.9293 14.6781V6.55094L8.11501 9.3638C8.01558 9.46323 7.89754 9.5421 7.76763 9.59591C7.63772 9.64972 7.49849 9.67742 7.35787 9.67742C7.21726 9.67742 7.07802 9.64972 6.94811 9.59591C6.8182 9.5421 6.70016 9.46323 6.60073 9.3638C6.5013 9.26437 6.42243 9.14633 6.36862 9.01642C6.31481 8.88651 6.28711 8.74727 6.28711 8.60665C6.28711 8.46604 6.31481 8.3268 6.36862 8.19689C6.42243 8.06698 6.5013 7.94894 6.60073 7.84951L11.2436 3.20665C11.4445 3.00601 11.7168 2.89331 12.0007 2.89331C12.2847 2.89331 12.557 3.00601 12.7579 3.20665L17.4007 7.84951Z"
        fill={fill}
      />
    </svg>
  );
};

export default ImportIconSvg;
