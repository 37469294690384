import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

import { getLoggedInUser } from "../shared/services/auth";

export type AuthUser = {
	id: number;
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	image: string;
};

type AuthProviderValue = {
	authUser: AuthUser | null;
	logout: () => void;
	setAuthUser: React.Dispatch<React.SetStateAction<AuthUser | null>>;
	isLoading: boolean;
};

const AuthContext = createContext<AuthProviderValue | undefined>(undefined);

AuthContext.displayName = "AuthContext";

export default function AuthProvider({ children }: { children: ReactNode }) {
	const storedToken = localStorage.getItem("accesstoken");
	const token = storedToken ? JSON.parse(storedToken) : null;

	const [authUser, setAuthUser] = useState<AuthUser | any>(null);
	const [isLoading, setIsLoading] = useState(true);

	const authUserId = storedToken ? JSON.parse(storedToken) : null;

	useEffect(() => {
		const getMe = async () => {
			try {
				setIsLoading(true);

				const currentUser = await getLoggedInUser(authUserId);

				setAuthUser(currentUser);
			} catch (error) {
				return;
			} finally {
				setIsLoading(false);
			}
		};

		getMe();
	}, [authUserId]);

	const logout = async () => {
		setAuthUser(null);
		localStorage.clear();
		window.location.href = "/auth";
	};

	const value = useMemo(() => {
		return {
			authUser,
			logout,
			setAuthUser,
			isLoading,
		};
	}, [authUser, isLoading]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
	return useContext(AuthContext);
}
