import React, { HTMLProps } from "react";
import { Search } from "lucide-react";
import { Input } from "../ui/input";
import CalendarIconSvg from "src/assets/svg/CalendarIconSvg";

const DateInput = ({ ...props }: HTMLProps<HTMLInputElement>) => {
	return (
		<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
			<div className="relative w-full row-start-1 sm:row-start-auto">
				<Input placeholder="--/--/----" className="pr-6 lg:pr-8" {...props} />
				<CalendarIconSvg
					className="absolute right-1 bottom-0 transform -translate-y-1/2 w-4 h-4 text-secondary"
					fill={props.disabled ? "#c9c9c9" : "#292D32"}
				/>
			</div>
		</div>
	);
};

export default DateInput;
