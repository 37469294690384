import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Type
import { Product } from "../utils/types";

// Data
import productsArray from "../../../data/product/products.json";

// TODO: Change this to api call
export const productsApi = createApi({
	reducerPath: "productsApi",
	tagTypes: ["Products", "Product"],
	baseQuery: fetchBaseQuery({ baseUrl: "/" }),
	endpoints: (builder) => ({
		getProducts: builder.query<Product[], void>({
			queryFn() {
				return new Promise<{ data: Product[] }>((resolve, reject) => {
					try {
						const products = productsArray.map((product: Product) => {
							return { ...product };
						});

						setTimeout(() => {
							resolve({ data: products });
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			providesTags: ["Products"],
		}),
		getSelectedProducts: builder.query<Product, { id: number }>({
			async queryFn({ id }) {
				return new Promise<{ data: Product }>((resolve, reject) => {
					try {
						setTimeout(() => {
							const selectedProduct = productsArray.find(
								(product) => id === product.id
							);

							// TODO: Create a util for type assertion
							resolve({
								data: selectedProduct as Product,
							});
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			// ? Comment cache for now, not sure if calling api everytime I need a selected user data is better than using the cache one
			providesTags: (result, error, { id }) => [{ type: "Product", id: id }],
			keepUnusedDataFor: 0,
		}),
		addProduct: builder.mutation<void, Product>({
			query: (newProduct) => ({
				url: "/new",
				method: "POST",
				body: newProduct,
			}),
			invalidatesTags: ["Products"],
		}),
		updateProduct: builder.mutation<void, Product>({
			query: (updatedProduct) => ({
				url: `/update/${updatedProduct.id}`,
				method: "PUT",
				body: updatedProduct,
			}),
			invalidatesTags: ["Products"],
		}),
		deleteProduct: builder.mutation<void, string>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Products"],
		}),
	}),
});

export const {
	useGetProductsQuery,
	useGetSelectedProductsQuery,
	useAddProductMutation,
	useUpdateProductMutation,
	useDeleteProductMutation,
} = productsApi;
