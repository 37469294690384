import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import customersArray from "../../../data/customer/customers.json";
import { CustomerWithFullName, Customer } from "../utils/types";

export const customersApi = createApi({
	reducerPath: "customersApi",
	tagTypes: ["Customers", "Customer"],
	baseQuery: fetchBaseQuery({ baseUrl: "" }),
	endpoints: (builder) => ({
		getCustomers: builder.query<CustomerWithFullName[], void>({
			async queryFn() {
				return new Promise<{ data: CustomerWithFullName[] }>(
					(resolve, reject) => {
						try {
							setTimeout(() => {
								const customersWithFullName = customersArray.map(
									(i: Customer, index: number) => {
										return {
											...i,
											name: `${i.firstName} ${i.lastName}`,
										};
									}
								);

								resolve({
									data: customersWithFullName,
								});
							}, 2000);
						} catch (error) {
							reject({ error: error as Error });
						}
					}
				);
			},
			providesTags: ["Customers"],
		}),
		getSelectedCustomer: builder.query<Customer, { id: number }>({
			async queryFn({ id }) {
				return new Promise<{ data: Customer }>((resolve, reject) => {
					try {
						setTimeout(() => {
							const selectedCustomer = customersArray.find(
								(customer) => id === customer.id
							);
							resolve({
								data: selectedCustomer as Customer,
							});
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			// ? Comment cache for now, not sure if calling api everytime I need a selected customer data is better than using the cache one
			// providesTags: (result, error, { id }) => [{ type: "Customer", id: id }],
			// Prevents caching
			keepUnusedDataFor: 0,
		}),
		addCustomer: builder.mutation<void, Customer>({
			query: (newCustomer) => ({
				url: "/new",
				method: "POST",
				body: newCustomer,
			}),
			invalidatesTags: ["Customers"],
		}),
		updateCustomer: builder.mutation<void, Customer>({
			query: (updatedCustomer) => ({
				url: `/update/${updatedCustomer.id}`,
				method: "PUT",
				body: updatedCustomer,
			}),
			invalidatesTags: ["Customers"],
		}),
		deleteCustomer: builder.mutation<void, string>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Customers"],
		}),
	}),
});

export const {
	useGetCustomersQuery,
	useGetSelectedCustomerQuery,
	useAddCustomerMutation,
	useUpdateCustomerMutation,
	useDeleteCustomerMutation,
} = customersApi;
