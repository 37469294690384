import { useEffect, useState } from "react";
import { useGetProductsQuery } from "src/modules/product/slices/productApiSlice";
import { useProductColumns } from "src/modules/product/columns";
import { useNavigate } from "react-router-dom";

// Types
import { Product } from "src/modules/product/utils/types";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "src/shared/components/ui/select";
import PageControls from "src/shared/components/control/PageControls";
import PageContainer from "src/shared/components/layout/container/PageContainer";

// Icons
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import { Input } from "src/shared/components/ui/input";
import { Search } from "lucide-react";
import PreviewProductModal from "./PreviewProductModal";

const BannerProductsList = () => {
	const navigate = useNavigate();

	const { data: productsArray = [], isFetching: isProductsFetching } =
		useGetProductsQuery();

	const [searchValue, setSearchValue] = useState("");

	const [categorySelected, setCategorySelected] = useState("");

	const [selectedProduct, setSelectedProduct] = useState<Product>({
		id: 0,
		sku: 0,
		images: [],
		color: "",
		name: "",
		category: 0,
		description: "",
		variations: 0,
		stocks: 0,
		price: 0,
		discounted_price: 0,
		size: "",
	});
	const [isProductPreviewModalOpen, setIsProductPreviewModalOpen] =
		useState(false);

	const _onPreviewPress = (product: Product) => {
		setSelectedProduct(product);
		setIsProductPreviewModalOpen(true);
	};

	const _onCancelPress = () => {
		navigate(-1);
	};

	const columns = useProductColumns({ page: "Products List", _onPreviewPress });

	useEffect(() => {
		if (categorySelected == "0") {
			setCategorySelected("");
		}
	}, [categorySelected]);

	if (isProductsFetching) {
		return <div>Loading...</div>;
	}

	return (
		<PageContainer>
			<div className="flex flex-col sm:flex-row sm:justify-between gap-4 mb-4 lg:mb-8">
				<Label variant="title">Add Products to Banner</Label>

				<div className="w-full h-fit sm:w-fit flex gap-2">
					<Button
						type="submit"
						disabled={true}
						className="bg-disabled text-disabled-foreground"
					>
						<ButtonAddIconSvg className="h-5 w-5" fill="#cbcbcc" />
						Add Product
					</Button>
					<Button
						variant="secondary"
						size="md"
						type="reset"
						onClick={_onCancelPress}
					>
						Cancel
					</Button>
				</div>
			</div>

			<div className="flex mt-2 mb-10">
				<Label variant="header">Product List</Label>
			</div>

			<PageControls className="lg:mt-2">
				<div className="flex gap-2 w-full sm:w-[18em]">
					<Select
						onValueChange={setCategorySelected}
						defaultValue={String(categorySelected)}
					>
						<SelectTrigger
							className={`pl-4 ${
								!categorySelected ? "text-gray-300" : "text-secondary"
							}`}
						>
							<SelectValue placeholder="Select a category" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								<SelectItem value="0">No Category</SelectItem>
								<SelectItem value="1">Category Level 1</SelectItem>
								<SelectItem value="2">Category Level 2</SelectItem>
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
				<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<div className="relative w-full row-start-1 sm:row-start-auto sm:w-[15em] sm:max-w-[15em] md:w-[25em] md:max-w-[25em]">
						<Search className="absolute left-0 bottom-0 transform -translate-y-1/2 w-[14px] h-[14px] md:w-4 md:h-4 text-secondary" />
						<Input
							placeholder="Search"
							className="pl-6 lg:pl-8 text-[0.65rem] sm:text-sm"
							onChange={(e) => setSearchValue(e.target.value)}
						/>
					</div>
				</div>
			</PageControls>

			<div className="mt-8">
				<DataTable data={productsArray} columns={columns} hasPagination />
			</div>

			<PreviewProductModal
				product={selectedProduct}
				modalState={isProductPreviewModalOpen}
				modalTrigger={() =>
					setIsProductPreviewModalOpen(!isProductPreviewModalOpen)
				}
			/>
		</PageContainer>
	);
};

export default BannerProductsList;
