const ButtonDeleteIconSvg = ({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.106 0.553C5.18899 0.386919 5.31658 0.247222 5.47447 0.149552C5.63237 0.0518831 5.81434 9.91486e-05 6 0H12C12.1857 9.91486e-05 12.3676 0.0518831 12.5255 0.149552C12.6834 0.247222 12.811 0.386919 12.894 0.553L14.618 4H17C17.2652 4 17.5196 4.10536 17.7071 4.29289C17.8946 4.48043 18 4.73478 18 5C18 5.26522 17.8946 5.51957 17.7071 5.70711C17.5196 5.89464 17.2652 6 17 6H16V17C16 17.7956 15.6839 18.5587 15.1213 19.1213C14.5587 19.6839 13.7956 20 13 20H5C4.20435 20 3.44129 19.6839 2.87868 19.1213C2.31607 18.5587 2 17.7956 2 17V6H1C0.734784 6 0.48043 5.89464 0.292893 5.70711C0.105357 5.51957 0 5.26522 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H3.382L5.106 0.553ZM11.382 2L12.382 4H5.618L6.618 2H11.382ZM8 9C8 8.73478 7.89464 8.48043 7.70711 8.29289C7.51957 8.10536 7.26522 8 7 8C6.73478 8 6.48043 8.10536 6.29289 8.29289C6.10536 8.48043 6 8.73478 6 9V15C6 15.2652 6.10536 15.5196 6.29289 15.7071C6.48043 15.8946 6.73478 16 7 16C7.26522 16 7.51957 15.8946 7.70711 15.7071C7.89464 15.5196 8 15.2652 8 15V9ZM12 9C12 8.73478 11.8946 8.48043 11.7071 8.29289C11.5196 8.10536 11.2652 8 11 8C10.7348 8 10.4804 8.10536 10.2929 8.29289C10.1054 8.48043 10 8.73478 10 9V15C10 15.2652 10.1054 15.5196 10.2929 15.7071C10.4804 15.8946 10.7348 16 11 16C11.2652 16 11.5196 15.8946 11.7071 15.7071C11.8946 15.5196 12 15.2652 12 15V9Z"
        fill={fill}
      />
      <path
        d="M5.28491 0.6424L5.28487 0.642463L3.56087 4.08946L3.50559 4.2H3.382H1C0.787827 4.2 0.584344 4.28429 0.434314 4.43431C0.284285 4.58434 0.2 4.78783 0.2 5C0.2 5.21217 0.284285 5.41566 0.434314 5.56569C0.584344 5.71571 0.787827 5.8 1 5.8H2H2.2V6V17C2.2 17.7426 2.495 18.4548 3.0201 18.9799C3.5452 19.505 4.25739 19.8 5 19.8H13C13.7426 19.8 14.4548 19.505 14.9799 18.9799C15.505 18.4548 15.8 17.7426 15.8 17V6V5.8H16H17C17.2122 5.8 17.4157 5.71571 17.5657 5.56569C17.7157 5.41566 17.8 5.21217 17.8 5C17.8 4.78783 17.7157 4.58434 17.5657 4.43431C17.4157 4.28429 17.2122 4.2 17 4.2H14.618H14.4944L14.4391 4.08946L12.7151 0.642463L12.7151 0.6424C12.6487 0.509535 12.5466 0.397777 12.4203 0.319642C12.294 0.241525 12.1485 0.200099 12 0.2L5.28491 0.6424ZM5.28491 0.6424C5.3513 0.509535 5.45337 0.397777 5.57969 0.319642M5.28491 0.6424L5.57969 0.319642M5.57969 0.319642C5.70597 0.241525 5.85151 0.200099 6 0.2M5.57969 0.319642L6 0.2M6 0.2L6.00011 0.2L11.9999 0.2C11.9999 0.2 11.9999 0.2 11.9999 0.2L6 0.2ZM11.5609 1.91056L11.5056 1.8H11.382H6.618H6.49439L6.43911 1.91056L5.43911 3.91056L5.29439 4.2H5.618H12.382H12.7056L12.5609 3.91056L11.5609 1.91056ZM8.2 9C8.2 8.68174 8.07357 8.37652 7.84853 8.15147C7.62348 7.92643 7.31826 7.8 7 7.8C6.68174 7.8 6.37652 7.92643 6.15147 8.15147C5.92643 8.37652 5.8 8.68174 5.8 9V15C5.8 15.3183 5.92643 15.6235 6.15147 15.8485C6.37652 16.0736 6.68174 16.2 7 16.2C7.31826 16.2 7.62348 16.0736 7.84853 15.8485C8.07357 15.6235 8.2 15.3183 8.2 15V9ZM12.2 9C12.2 8.68174 12.0736 8.37652 11.8485 8.15147C11.6235 7.92643 11.3183 7.8 11 7.8C10.6817 7.8 10.3765 7.92643 10.1515 8.15147C9.92643 8.37652 9.8 8.68174 9.8 9V15C9.8 15.3183 9.92643 15.6235 10.1515 15.8485C10.3765 16.0736 10.6817 16.2 11 16.2C11.3183 16.2 11.6235 16.0736 11.8485 15.8485C12.0736 15.6235 12.2 15.3183 12.2 15V9Z"
        stroke="#292D32"
        strokeOpacity="0.5"
        strokeWidth="0.4"
        style={{ mixBlendMode: "overlay" }}
      />
    </svg>
  );
};

export default ButtonDeleteIconSvg;
