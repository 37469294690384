import PageControls from "src/shared/components/control/PageControls";
import { Label } from "src/shared/components/ui/label";

const AccountFormDescription = () => {
	return (
		<PageControls className="w-full flex">
			<Label variant="description">
				Changes will be reflected on the app. Last edited{" "}
				<span className="font-bold">15/08/2022</span> at{" "}
				<span className="font-bold">16:40:51</span>
			</Label>
		</PageControls>
	);
};

export default AccountFormDescription;
