import React from "react";

// Types
import { UseFormReturn } from "react-hook-form";
import { Account } from "src/redux/account/types";

// Utils
import {
	validateFileExtension,
	validateFileSize,
} from "src/shared/helpers/validateAttachment";

// Components
import FormController from "src/shared/components/form/FormController";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import { FormField, FormMessage } from "src/shared/components/form/Form";
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";

// Icons
import ButtonEditIconSvg from "src/assets/svg/ButtonEditIconSvg";

type AccountFormImageSectionProp = {
	form: UseFormReturn<Account, any, undefined>;
};

const AccountFormImageSection = ({ form }: AccountFormImageSectionProp) => {
	return (
		<SectionContainer className="col-span-1 row-span-1 sm:col-span-2 h-fit xl:h-fit row-start-1">
			<SectionHeader>
				<Label variant="sub_header">Profile Image</Label>
			</SectionHeader>
			<div className="w-full h-full">
				<FormField
					control={form.control}
					name="image"
					render={({ field: { value, onChange, ...fieldProps } }) => {
						return (
							<div className="flex flex-col gap-4 items-center">
								<Label variant="form">Edit Profile Image</Label>
								<div
									className="h-28 w-28 rounded-full relative bg-cover bg-center"
									style={{
										backgroundImage: `url('${value}')`,
									}}
								>
									<div>
										<FormController hasValidationMessage={false}>
											<Input
												id="profile"
												type="file"
												className="hidden"
												accept="image/*"
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													const file = e.target.files?.[0];

													if (file) {
														const isAttachmentValid = validateFileSize({
															file: file,
															form: form,
															errorMessage: "Image size must not exceed 2mb.",
															fieldName: "image",
															sizeLimit: 2,
														});

														const isExtensionValid = validateFileExtension({
															file: file,
															form: form,
															errorMessage:
																"Invalid file type. Please upload a .png, .jpg, or .jpeg file.",
															fieldName: "image",
															validExtensions: [
																"image/jpeg",
																"image/jpg",
																"image/png",
															],
														});

														if (!isExtensionValid || !isAttachmentValid) {
															return;
														} else {
															form.clearErrors();
														}

														const imageUrl = URL.createObjectURL(file);

														onChange(imageUrl);

														// Here you would typically upload the file to your server
														// and then update the form with the returned URL
														// uploadFileToServer(file).then(url => onChange(url));
													}
												}}
												value="" // Keep the file input value empty
												{...fieldProps}
											/>
										</FormController>
										<Button
											variant="icon"
											className="absolute bottom-1 right-2 rounded-full bg-secondary p-1 h-6 w-6 hover:bg-secondary-foreground"
											onClick={() =>
												document.getElementById("profile")?.click()
											}
											type="button"
										>
											<span className="h-3 w-3 text-white">
												<ButtonEditIconSvg fill="white" />
											</span>
										</Button>
									</div>
								</div>
								{form.formState.errors.image && (
									<FormMessage>
										<>{form.formState.errors.image}</>
									</FormMessage>
								)}
							</div>
						);
					}}
				/>
			</div>
		</SectionContainer>
	);
};

export default AccountFormImageSection;
