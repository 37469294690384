import PageHeader from "src/shared/components/layout/header/PageHeader";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";

const AccountFormHeader = ({
	isDirty,
	isValid,
	isLoading,
	_onCancelPress,
	_onSavePress,
}: {
	isDirty: boolean;
	isValid: boolean;
	isLoading: boolean;
	_onCancelPress: () => void;
	_onSavePress: () => void;
}) => {
	return (
		<PageHeader className="flex flex-col sm:flex-row items-start gap-4">
			<Label variant="title">Account Settings</Label>
			<div className="flex gap-2 m-0 p-0 h-fit w-full sm:w-fit">
				<Button
					onClick={_onSavePress}
					disabled={!isDirty || !isValid || isLoading}
				>
					{isLoading ? (
						<ButtonLoading />
					) : (
						<>
							<ButtonCheckIconSvg
								className="h-5 w-5"
								fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
							/>
							Save
						</>
					)}
				</Button>
				<Button
					variant="secondary"
					onClick={_onCancelPress}
					disabled={!isDirty || isLoading}
				>
					Cancel
				</Button>
			</div>
		</PageHeader>
	);
};

export default AccountFormHeader;
