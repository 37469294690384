const SuccessImportDataIconSvg = ({
	fill,
	className,
}: {
	fill: string;
	className?: string;
}) => {
	return (
		<svg
			width="52"
			height="64"
			viewBox="0 0 52 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M32.3999 0H6.7999C5.10252 0 3.47465 0.674284 2.27442 1.87452C1.07419 3.07475 0.399902 4.70261 0.399902 6.4V57.6C0.399902 59.2974 1.07419 60.9253 2.27442 62.1255C3.47465 63.3257 5.10252 64 6.7999 64H45.1999C46.8973 64 48.5252 63.3257 49.7254 62.1255C50.9256 60.9253 51.5999 59.2974 51.5999 57.6V19.2L32.3999 0ZM45.1999 57.6H6.7999V6.4H29.1999V22.4H45.1999V57.6ZM19.5999 35.2H9.9999V28.8H19.5999V35.2ZM32.3999 35.2H22.7999V28.8H32.3999V35.2ZM19.5999 44.8H9.9999V38.4H19.5999V44.8ZM32.3999 44.8H22.7999V38.4H32.3999V44.8ZM19.5999 54.4H9.9999V48H19.5999V54.4ZM32.3999 54.4H22.7999V48H32.3999V54.4Z"
				fill={fill}
			/>
			<path
				d="M29.2115 17.0208L29.2115 17.0209L32.8888 20.6982C32.8888 20.6982 32.8888 20.6982 32.8889 20.6982C33.0498 20.8592 33.2409 20.987 33.4512 21.0741C33.6616 21.1613 33.8871 21.2061 34.1147 21.2061C34.3424 21.2061 34.5679 21.1613 34.7783 21.0741C34.9886 20.987 35.1797 20.8592 35.3406 20.6982C35.3407 20.6982 35.3407 20.6982 35.3407 20.6982L42.7886 13.2502C43.0888 12.95 43.2575 12.5429 43.2575 12.1183C43.2575 11.6938 43.0888 11.2866 42.7886 10.9864C42.4884 10.6862 42.0813 10.5176 41.6567 10.5176C41.2322 10.5176 40.8251 10.6862 40.5249 10.9864L34.1135 17.3978L31.4754 14.7585C31.4754 14.7585 31.4754 14.7585 31.4754 14.7585C31.3268 14.6098 31.1505 14.4919 30.9563 14.4114C30.7622 14.3309 30.5541 14.2895 30.344 14.2894C29.9196 14.2892 29.5125 14.4577 29.2123 14.7577C28.912 15.0577 28.7433 15.4647 28.7431 15.8891C28.743 16.3135 28.9115 16.7206 29.2115 17.0208ZM36.0001 2.93366C43.2168 2.93366 49.0667 8.7836 49.0667 16.0003C49.0667 23.2171 43.2168 29.067 36.0001 29.067C28.7834 29.067 22.9334 23.2171 22.9334 16.0003C22.9334 8.7836 28.7834 2.93366 36.0001 2.93366Z"
				fill="#28A745"
			/>
			<path
				d="M29.2115 17.0208L29.2115 17.0209L32.8888 20.6982C32.8888 20.6982 32.8888 20.6982 32.8889 20.6982C33.0498 20.8592 33.2409 20.987 33.4512 21.0741C33.6616 21.1613 33.8871 21.2061 34.1147 21.2061C34.3424 21.2061 34.5679 21.1613 34.7783 21.0741C34.9886 20.987 35.1797 20.8592 35.3406 20.6982C35.3407 20.6982 35.3407 20.6982 35.3407 20.6982L42.7886 13.2502C43.0888 12.95 43.2575 12.5429 43.2575 12.1183C43.2575 11.6938 43.0888 11.2866 42.7886 10.9864C42.4884 10.6862 42.0813 10.5176 41.6567 10.5176C41.2322 10.5176 40.8251 10.6862 40.5249 10.9864L34.1135 17.3978L31.4754 14.7585C31.4754 14.7585 31.4754 14.7585 31.4754 14.7585C31.3268 14.6098 31.1505 14.4919 30.9563 14.4114C30.7622 14.3309 30.5541 14.2895 30.344 14.2894C29.9196 14.2892 29.5125 14.4577 29.2123 14.7577C28.912 15.0577 28.7433 15.4647 28.7431 15.8891C28.743 16.3135 28.9115 16.7206 29.2115 17.0208ZM36.0001 2.93366C43.2168 2.93366 49.0667 8.7836 49.0667 16.0003C49.0667 23.2171 43.2168 29.067 36.0001 29.067C28.7834 29.067 22.9334 23.2171 22.9334 16.0003C22.9334 8.7836 28.7834 2.93366 36.0001 2.93366Z"
				stroke="#28A745"
				strokeWidth="0.533333"
			/>
		</svg>
	);
};

export default SuccessImportDataIconSvg;
