const TableEditIconSvg = ({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.9464 3.12054H4.98661C3.88944 3.12054 3 4.00998 3 5.10715V19.0134C3 20.1106 3.88944 21 4.98661 21H18.8929C19.9901 21 20.8795 20.1106 20.8795 19.0134V13.0536"
        stroke={fill}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.45675 11.5636L17.4032 3.61716C18.226 2.79428 19.5603 2.79428 20.3831 3.61716C21.206 4.44005 21.206 5.77419 20.3831 6.59708L12.4367 14.5435L7.9668 16.0335L9.45675 11.5636Z"
        stroke={fill}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TableEditIconSvg;
