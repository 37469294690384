import { useNavigate } from "react-router-dom";

// Types
import { Store } from "../utils/types";
import { ColumnDef } from "@tanstack/react-table";

// Utils
import { formatDate } from "../../../shared/utils/formatDate";

// Components
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";

// Icons
import { FaSort } from "react-icons/fa";
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";

type StoreColumnProps = (id: number, name: string) => void;

export const useValidStoreDataColumns = (
	_onOpenDeleteModal?: StoreColumnProps
): ColumnDef<Store>[] => {
	const navigate = useNavigate();

	const columns: ColumnDef<Store>[] = [
		{
			accessorKey: "name",
			header: ({ column }) => (
				<button
					type="button"
					// variant="table_filter"
					className="flex justify-between w-full"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<p className="font-bold">Name</p>
					<FaSort className="h-3 w-3 ml-auto group-hover:cursor-pointer text-gray-400" />
				</button>
			),
			cell: ({ row }) => {
				const storeName: string = row.getValue("name");
				return (
					<div
						className="w-[20ch] overflow-hidden text-ellipsis whitespace-nowrap"
						title={storeName}
					>
						{row.getValue("name")}
					</div>
				);
			},
		},
		{
			accessorKey: "address",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between items-center w-full"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<p className="font-bold">Address</p>
					<FaSort className="h-3 w-3 ml-auto group-hover:cursor-pointer text-gray-400" />
				</button>
			),
			cell: ({ row }) => {
				return (
					<div className="w-[12em] overflow-hidden text-ellipsis line-clamp-2">
						{row.getValue("address")}
					</div>
				);
			},
		},
		{
			accessorKey: "code",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between items-center w-full"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<p className="font-bold">Postal Code</p>
					<FaSort className="h-3 w-3 ml-auto group-hover:cursor-pointer text-gray-400" />
				</button>
			),
		},
		{
			accessorKey: "city",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between items-center w-full"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<p className="font-bold">City</p>
					<FaSort className="h-3 w-3 ml-auto group-hover:cursor-pointer text-gray-400" />
				</button>
			),
		},
		{
			accessorKey: "opening_hrs",
			header: () => <p className="font-bold">Opening Hrs</p>,
			cell: ({ row }) => {
				return (
					<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("opening_hrs")}
					</div>
				);
			},
		},
		{
			accessorKey: "landline_no",
			header: () => <p className="font-bold">Landline No.</p>,
			cell: ({ row }) => {
				return (
					<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("landline_no")}
					</div>
				);
			},
		},
		{
			accessorKey: "lat",
			header: () => <p className="font-bold">Lat</p>,
			cell: ({ row }) => {
				return (
					<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("lat")}
					</div>
				);
			},
		},
		{
			accessorKey: "long",
			header: () => <p className="font-bold">Long</p>,
			cell: ({ row }) => {
				return (
					<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("long")}
					</div>
				);
			},
		},
	];

	return columns;
};
