const TableAddIconSvg = ({
	fill,
	className,
}: {
	fill: string;
	className?: string;
}) => {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M12 4.28809C16.411 4.28809 20 7.87709 20 12.2881C20 16.6991 16.411 20.2881 12 20.2881C7.589 20.2881 4 16.6991 4 12.2881C4 7.87709 7.589 4.28809 12 4.28809ZM12 2.28809C6.477 2.28809 2 6.76509 2 12.2881C2 17.8111 6.477 22.2881 12 22.2881C17.523 22.2881 22 17.8111 22 12.2881C22 6.76509 17.523 2.28809 12 2.28809ZM17 11.2881H13V7.28809H11V11.2881H7V13.2881H11V17.2881H13V13.2881H17V11.2881Z"
				fill={fill}
			/>
		</svg>
	);
};

export default TableAddIconSvg;
