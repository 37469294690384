import { useEffect, useState } from "react";
import { useGetCategoriesQuery } from "./slices/categoryApiSlice";

// Types
import { Category } from "./utils/types";

import { Label } from "src/shared/components/ui/label";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import CategoryFormModal from "./fragments/CategoryFormModal";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import LevelTwoForm from "./fragments/LevelTwoForm";
import LevelThreeForm from "./fragments/LevelThreeForm";
import LevelOneForm from "./fragments/LevelOneForm";
import Loading from "src/shared/components/loading-indicator/Loading";
import DesignBackground from "src/shared/components/background/DesignBackground";

const CategoryPage = () => {
	const { data: categories = [], isLoading: isCategoriesFetching } =
		useGetCategoriesQuery();
	const [searchValue, setSearchValue] = useState("");
	const [isCategoryFormModalOpen, setIsCategoryFormModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [categorySelected, setCategorySelected] = useState({
		id: 0,
		name: "",
		image: "",
	});

	const _onOpenModalPress = (category: Category) => {
		setCategorySelected(category);
		setIsCategoryFormModalOpen(true);
	};

	const _onOpenDeleteModalPress = (category: Category) => {
		setCategorySelected(category);
		setIsDeleteModalOpen(true);
	};

	const _onConfirmDelete = () => {
		setIsDeleteModalOpen(false);
	};

	useEffect(() => {
		if (!isCategoryFormModalOpen) {
			setCategorySelected({
				id: 0,
				name: "",
				image: "",
			});
		}
	}, [isCategoryFormModalOpen]);

	if (isCategoriesFetching) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Category Management</Label>
			</PageHeader>

			<Label variant="description">
				Select a Category from the preceding column in order to add or edit its
				sub categories.
			</Label>

			<div className="grid gap-8 grid-cols-3 mt-8">
				<LevelOneForm
					categories={categories}
					_onOpenDeleteModalPress={_onOpenDeleteModalPress}
					_onOpenModalPress={_onOpenModalPress}
					_onOpenAddCategoryModal={setIsCategoryFormModalOpen}
					setSearchValue={setSearchValue}
				/>
				<LevelTwoForm />
				<LevelThreeForm />
			</div>

			<CategoryFormModal
				category={categorySelected}
				modalState={isCategoryFormModalOpen}
				modalTrigger={() =>
					setIsCategoryFormModalOpen(!isCategoryFormModalOpen)
				}
			/>

			<ConfirmationDialog
				title="Delete Category?"
				description={`Category ${categorySelected.name} will be deleted`}
				confirmButtonLabel="Delete"
				closeButtonLabel="Cancel"
				modalState={isDeleteModalOpen}
				_onCancel={() => setIsDeleteModalOpen(false)}
				_onConfirm={_onConfirmDelete}
			/>
		</PageContainer>
	);
};

export default CategoryPage;
