import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { Store } from "../../../shared/types/store/storeTypes";
import { Store } from "../utils/types";
import storeArray from "../../../data/store/stores.json";
import inactiveStoreArray from "../../../data/store/inactiveStore.json";

export const storesApi = createApi({
	reducerPath: "storesApi",
	tagTypes: ["Stores", "Store"],
	baseQuery: fetchBaseQuery({ baseUrl: "/" }),
	endpoints: (builder) => ({
		getStores: builder.query<Store[], void>({
			queryFn() {
				return new Promise<{ data: Store[] }>((resolve, reject) => {
					try {
						const stores = storeArray.map((store: Store, index: number) => {
							return {
								...store,
							};
						});
						setTimeout(() => {
							resolve({ data: stores });
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			keepUnusedDataFor: 0,
		}),
		getInactiveStores: builder.query<Store[], void>({
			queryFn() {
				return new Promise<{ data: Store[] }>((resolve, reject) => {
					try {
						const stores = inactiveStoreArray.map(
							(store: Store, index: number) => {
								return {
									...store,
								};
							}
						);
						setTimeout(() => {
							resolve({ data: stores });
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			keepUnusedDataFor: 0,
		}),
		getSelectedStore: builder.query<Store, { id: number }>({
			async queryFn({ id }) {
				return new Promise<{ data: Store }>((resolve, reject) => {
					try {
						setTimeout(() => {
							const selectedStore = storeArray.find((store) => id === store.id);

							resolve({
								data: selectedStore as Store,
							});
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			// ? Comment cache for now, not sure if calling api everytime I need a selected user data is better than using the cache one
			// providesTags: (result, error, { id }) => [{ type: "User", id: id }],
			// Prevents caching
			keepUnusedDataFor: 0,
		}),
		addStores: builder.mutation<void, Store>({
			query: (newStore) => ({
				url: "/new",
				method: "POST",
				body: newStore,
			}),
			invalidatesTags: ["Stores"],
		}),
		updateStore: builder.mutation<void, Store>({
			query: (updatedStore) => ({
				url: `/update/${updatedStore.id}`,
				method: "PUT",
				body: updatedStore,
			}),
			invalidatesTags: ["Stores"],
		}),
		deleteStore: builder.mutation<void, string>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Stores"],
		}),
	}),
});

export const {
	useGetStoresQuery,
	useGetInactiveStoresQuery,
	useGetSelectedStoreQuery,
	useAddStoresMutation,
	useUpdateStoreMutation,
	useDeleteStoreMutation,
} = storesApi;
