import { useState } from "react";
import { useGetProductsQuery } from "./slices/productApiSlice";
import { useProductColumns } from "./columns";

// Utils
import { _exportTemplate } from "src/shared/helpers/exportFile";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import PageControls from "src/shared/components/control/PageControls";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import ImportButton from "src/shared/components/button/ImportButton";
import ExportButton from "src/shared/components/button/ExportButton";
import SearchInput from "src/shared/components/textfield/SearchInput";
import Loading from "src/shared/components/loading-indicator/Loading";

// Icons
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import FilterIconSvg from "src/assets/svg/FilterIconSvg";
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import DesignBackground from "src/shared/components/background/DesignBackground";

const ProductPage = () => {
	const navigate = useNavigate();

	const { data: productsArray = [], isFetching: isProductsFetching } =
		useGetProductsQuery();
	const [searchValue, setSearchValue] = useState("");

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(0);

	const _onOpenDeleteModal = (id: number) => {
		setIsDeleteModalOpen(true);
		setSelectedProduct(id);
	};

	const _onCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const _onConfirmDelete = async () => {
		setIsDeleteModalOpen(false);
	};

	const columns = useProductColumns({ _onOpenDeleteModal });

	if (isProductsFetching) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Product Management</Label>

				<div className="m-0 p-0 h-fit w-fit flex gap-2">
					<ExportButton onClick={() => _exportTemplate("Product")} />
					<ImportButton
						buttonText="Import Product Data"
						onClick={() => navigate("import")}
					/>
				</div>
			</PageHeader>

			<PageControls>
				<div className="flex gap-2 w-full sm:w-[15em]">
					<Button onClick={() => navigate("add")}>
						<ButtonAddIconSvg fill="#292d32" className="h-6 w-6" />
						Add Product
					</Button>
					<Button variant="destructive" disabled={true}>
						<ButtonDeleteIconSvg
							fill={`${true ? "#cbcbcc" : "white"}`}
							className="h-5 w-5 mb-[2px]"
						/>
						Delete
					</Button>
				</div>
				<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<Button variant="icon" size="icon">
						<FilterIconSvg fill="#292d32" className="h-5 w-5 mt-1" />
					</Button>

					<SearchInput setSearchValue={setSearchValue} />
				</div>
			</PageControls>

			<div className="mt-8">
				<DataTable data={productsArray} columns={columns} hasPagination />
			</div>

			<ConfirmationDialog
				title="Delete Product?"
				description={`Product will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteModalOpen}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>
		</PageContainer>
	);
};

export default ProductPage;
