import { z } from "zod";

export const BannerfFormSchema = z
	.object({
		id: z.number().min(1, "ID must be a positive number"),
		title: z.string().min(1, "Title is required"),
		image: z.string().url("Image must be a valid URL"),
		description: z.string().min(1, "Description is required"),
		products: z.number().nonnegative("Products must be a non-negative number"),
		from: z.string().refine((date) => !isNaN(Date.parse(date)), {
			message: "From date must be a valid date string",
		}),
		to: z.string().refine((date) => !isNaN(Date.parse(date)), {
			message: "To date must be a valid date string",
		}),
		category: z.coerce
			.number()
			.min(0, "Category must be a non-negative integer"),
		status: z.coerce.number().min(0, "Status must be a non-negative integer"),
		order: z
			.number()
			.nonnegative("Order must be a non-negative number")
			.optional(),
	})
	.strict();
