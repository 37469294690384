import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useGetSelectedCustomerQuery } from "./slices/customerApiSlice";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { Customer } from "./utils/types";
import { CustomerFormSchema } from "./utils/schema";

// Component
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import { Form, FormField } from "src/shared/components/form/Form";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import FormController from "src/shared/components/form/FormController";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";

const CustomerForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { customerId } = useParams<{ customerId: string }>();

	const { data: selectedCustomer, isFetching: isSelectedCustomerFetching } =
		useGetSelectedCustomerQuery({ id: Number(customerId) });

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

	const form = useForm<Customer>({
		resolver: zodResolver(CustomerFormSchema),
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			mobile_no: "",
			landline_no: "",
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		navigate("/customer");

		form.reset();
	};

	const _onCancelPress = () => {
		if (!isDirty) navigate("/customer");

		setIsLeaveModalOpen(true);
	};

	const _onSubmit = (values: Customer) => {
		console.log({ values });
	};

	// Update form values when selectedCustomer data is available
	useEffect(() => {
		if (selectedCustomer) {
			form.reset({
				firstName: selectedCustomer.firstName,
				lastName: selectedCustomer.lastName,
				email: selectedCustomer.email,
				mobile_no: selectedCustomer.mobile_no,
				landline_no: selectedCustomer.landline_no,
			});
		}
	}, [selectedCustomer, form.reset]);

	// * Reroute to UserPage/Table if id is invalid/no user
	if (!customerId && pathSegments[2] != "add") {
		navigate("/customer", { replace: true });
	}

	// * Avoid loading/fetching state if the form is for adding
	if (isSelectedCustomerFetching) {
		return <Loading />;
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(_onSubmit)}>
				<PageContainer>
					<FormHeader>
						<Label variant="title">Edit Customer Information</Label>
						<div className="w-full h-fit sm:w-fit flex gap-2">
							<Button type="submit" disabled={!isDirty || !isValid}>
								<ButtonCheckIconSvg
									className="h-5 w-5"
									fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
								/>
								Save
							</Button>
							<Button
								variant="secondary"
								size="md"
								type="reset"
								onClick={_onCancelPress}
							>
								Cancel
							</Button>
						</div>
					</FormHeader>
					<SectionContainer className="lg:grid-cols-3">
						<SectionHeader className="lg:col-span-3">
							<Label variant="header">Customer Information</Label>
						</SectionHeader>

						<FormField
							control={form.control}
							name="firstName"
							render={({ field }) => (
								<FormController label="First Name">
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="lastName"
							render={({ field }) => (
								<FormController label="Last Name">
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="email"
							render={({ field }) => (
								<FormController label="Email">
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="mobile_no"
							render={({ field }) => (
								<FormController label="Mobile Number">
									<div className="relative">
										<Label className="absolute left-0 bottom-[5px] text-disabled-foreground text-[0.65rem] lg:text-sm tracking-tight whitespace-nowrap">
											+63
										</Label>
										<Input {...field} className="pl-6 xl:pl-9" />
									</div>
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="landline_no"
							render={({ field }) => (
								<FormController label="Landline">
									<Input {...field} />
								</FormController>
							)}
						/>
					</SectionContainer>
				</PageContainer>
			</form>
			<ConfirmationDialog
				title="Leave page?"
				description="Changes are not yet saved."
				confirmButtonLabel="Leave"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>
		</Form>
	);
};

export default CustomerForm;
