import { useNavigate } from "react-router-dom";

// Types
import { Store } from "./utils/types";
import { ColumnDef } from "@tanstack/react-table";

// Utils
import { formatDate } from "../../shared/utils/formatDate";

// Components
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";

// Icons
import { FaSort } from "react-icons/fa";
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";

type StoreColumnProps = (id: number, name: string) => void;

export const useStoreColumns = (
	_onOpenDeleteModal?: StoreColumnProps
): ColumnDef<Store>[] => {
	const navigate = useNavigate();

	const columns: ColumnDef<Store>[] = [
		{
			id: "select",
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && "indeterminate")
					}
					onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "code",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Code</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
		},
		{
			accessorKey: "name",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Store Name</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
			cell: ({ row }) => {
				const storeName: string = row.getValue("name");
				return (
					<div
						className="w-[20ch] overflow-hidden text-ellipsis whitespace-nowrap"
						title={storeName}
					>
						{row.getValue("name")}
					</div>
				);
			},
		},

		{
			accessorKey: "city",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">
						City/Municipality
					</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
		},
		{
			accessorKey: "address",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Detailed Address</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
		},
		{
			accessorKey: "createdAt",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Uploaded/Edited</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
			cell: ({ row }) => {
				const date = row.getValue("from");
				return <div>{formatDate(date)}</div>;
			},
		},
		{
			id: "actions",
			header: () => (
				<div>
					<Label>Action</Label>
				</div>
			),
			cell: ({ row }) => {
				return (
					<div className="flex flex-row gap-3">
						<Button
							variant="icon"
							size="icon"
							onClick={() => navigate(`/store/${row.original.id}`)}
						>
							<TableEditIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
						<Button
							variant="icon"
							size="icon"
							onClick={() => {
								if (_onOpenDeleteModal)
									_onOpenDeleteModal(row.original.id, row.original.name);
							}}
						>
							<TableDeleteIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
					</div>
				);
			},
		},
	];

	return columns;
};
