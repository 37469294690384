import { ElementType, useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

// Types
import { Library, SocialMedia } from "src/redux/library/types";

// Components;
import { Label } from "src/shared/components/ui/label";
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Input } from "src/shared/components/ui/input";
import { FormField } from "src/shared/components/form/Form";
import FormController from "src/shared/components/form/FormController";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";

// Icons
import PlatformFacebookIconSvg from "src/assets/svg/platform/PlatformFacebookIconSvg";
import PlatformGlobeIconSvg from "src/assets/svg/platform/PlatformGlobeIconSvg";
import PlatformInstagramIconSvg from "src/assets/svg/platform/PlatformInstagramIconSvg";
import PlatformWikiIconSvg from "src/assets/svg/platform/PlatformWikiIconSvg";
import PlatformXIconSvg from "src/assets/svg/platform/PlatformXIconSvg";
import PlatformYoutubeIconSvg from "src/assets/svg/platform/PlatformYoutubeIconSvg";

type SocialMediaFormProp = {
	form: UseFormReturn<Library, any, undefined>;
};

type SocialMediaWithIcon = SocialMedia & {
	icon: ElementType;
};

const iconMap: Record<string, ElementType> = {
	Website: PlatformGlobeIconSvg,
	Facebook: PlatformFacebookIconSvg,
	Instagram: PlatformInstagramIconSvg,
	Youtube: PlatformYoutubeIconSvg,
	Wiki: PlatformWikiIconSvg,
	X: PlatformXIconSvg,
};

const SocialMediaForm = ({ form }: SocialMediaFormProp) => {
	const [platforms, setPlatforms] = useState<SocialMediaWithIcon[]>([]);
	const [noOfCheckedPlatforms, setNoOfCheckedPlatforms] = useState(0);

	const socialMediaValues = useWatch({
		control: form.control,
		name: "social_media",
	});

	useEffect(() => {
		if (Array.isArray(form.getValues("social_media"))) {
			const updatedPlatformsArray = form
				.getValues("social_media")
				.map((platform) => {
					if (!platform.link) {
						return {
							...platform,
							isSelected: false,
							icon: iconMap[platform.name],
						};
					}

					return {
						...platform,
						icon: iconMap[platform.name],
					};
				});

			setPlatforms(updatedPlatformsArray);

			const checkedCount = socialMediaValues?.filter(
				(platform) => platform.isSelected
			).length;
			setNoOfCheckedPlatforms(checkedCount);
		}
	}, [socialMediaValues]);

	return (
		<SectionContainer>
			<SectionHeader>
				<div className="flex flex-col gap-2">
					<Label variant="header">Social Media</Label>
					<Label variant="description">
						A link must be provided to enable the checkbox of the Social media
						Platform. Only up to three (3) checked Social Media links will be
						displayed on the Dashboard footer and About MR. DIY pages of the
						app. Social media Icons are automatically provided by the system.
					</Label>
				</div>
			</SectionHeader>
			<div className="grid grid-cols-2 w-full gap-2">
				<div className="col-span-2 grid grid-cols-10 xl:grid-cols-8 py-2 border-b-[1px] border-zinc-200 shadow-b-md">
					<Label className="col-span-2 sm:col-span-2 text-xs lg:text-base font-bold">
						<span className="hidden xl:inline">Social Media</span> Platform
					</Label>
					<Label className="col-span-1 sm:col-span-1 col-start-4 text-xs lg:text-base font-bold">
						Icon
					</Label>
					<Label className="col-span-5 sm:col-span-5 col-start-6 text-xs lg:text-base font-bold">
						Link
					</Label>
				</div>

				{!socialMediaValues?.some((platform) => platform.isSelected) && (
					<Label variant="description" className="text-destructive">
						Please provide at least one social media platform.
					</Label>
				)}
				{platforms.map((platform, index) => {
					return (
						<div
							className="col-span-2 grid grid-cols-10 xl:grid-cols-8 items-center"
							key={platform.id}
						>
							<div className="col-span-2 sm:col-span-2 flex gap-1 lg:gap-2 text-xs lg:text-base items-center">
								<FormField
									control={form.control}
									name={`social_media.${platform.id}.isSelected`}
									render={({ field }) => (
										<FormController>
											<div className="flex items-center">
												<Checkbox
													disabled={
														(noOfCheckedPlatforms >= 3 && !field.value) ||
														!platforms[index].link ||
														(noOfCheckedPlatforms == 1 && field.value)
													}
													checked={field.value}
													onCheckedChange={(checked) => {
														field.onChange(checked);

														let noOfSelectedPlatform = 0;

														form.watch("social_media").forEach((platform) => {
															if (platform.isSelected == true) {
																noOfSelectedPlatform += 1;
															}
														});

														setNoOfCheckedPlatforms(noOfSelectedPlatform);
													}}
													className="w-[11px] h-[11px] lg:w-[13px] lg:h-[13px]"
												/>
											</div>
										</FormController>
									)}
								/>
								<Label variant="description">{platform.name}</Label>
							</div>
							<Label className="col-span-1 sm:col-span-1 col-start-4 text-xs lg:text-base block ">
								{!platform.icon ? (
									<></>
								) : (
									<platform.icon className="h-5 w-5 ml-1 lg:h-7 lg:w-7" />
								)}
							</Label>

							<div className="col-span-5 sm:col-span-7 xl:col-span-5 col-start-6 h-full tracking-tight whitespace-nowrap text-ellipsis px-1 py-0 border-stone-400 rounded-none ">
								<FormField
									control={form.control}
									name={`social_media.${platform.id}.link`}
									render={({ field }) => {
										return (
											<FormController>
												<Input
													{...field}
													placeholder={"-"}
													onChange={async (e) => {
														const currentValue = e.target.value;

														if (!currentValue) {
															const updatedPlatforms = form
																.getValues("social_media")
																.map((platform) => {
																	if (
																		`social_media.${platform.id}.link` ===
																		field.name
																	) {
																		return {
																			...platform,
																			isSelected: false, // Uncheck if the link is empty
																		};
																	}
																	return platform;
																});

															// Update the social media platforms in the form
															form.trigger("social_media");
															form.setValue("social_media", updatedPlatforms, {
																shouldValidate: true,
																shouldDirty: true,
																shouldTouch: true,
															});
														}

														field.onChange(e);
													}}
												/>
											</FormController>
										);
									}}
								/>
							</div>
						</div>
					);
				})}
			</div>
		</SectionContainer>
	);
};

export default SocialMediaForm;
