import { useProductColumns } from "src/modules/product/columns";
import { useGetProductsQuery } from "src/modules/product/slices/productApiSlice";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import PageHeader from "src/shared/components/layout/header/PageHeader";

// Icons
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";

const ProductListSection = () => {
	const { data: productsArray = [] } = useGetProductsQuery();

	const columns = useProductColumns({ page: "Banner Form" });

	return (
		<div className="mt-8">
			<PageHeader className="flex flex-col gap-4 items-start justify-start sm:flex-row sm:items-center">
				<Label variant="sub_header" className="w-fit">
					Product List
				</Label>
				<Button variant="destructive" size="md" disabled={true}>
					<ButtonDeleteIconSvg
						fill={`${true ? "#cbcbcc" : "white"}`}
						className="h-5 w-5"
					/>
					Delete
				</Button>
			</PageHeader>
			{/* <DataTable
                data={[]}
                columns={columns}
                emptyTableText="No Product has been added"
                hasPagination
            /> */}
			<DataTable
				data={productsArray}
				columns={columns}
				emptyTableText="No Product has been added"
				hasPagination
			/>
		</div>
	);
};

export default ProductListSection;
