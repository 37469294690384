import { useNavigate } from "react-router-dom";

// Types
import { ColumnDef } from "@tanstack/react-table";

// Utils
import { formatDate } from "../../../shared/utils/formatDate";

// Components
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";

// Icons
import { FaSort } from "react-icons/fa";
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "src/shared/components/ui/accordion";

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuPortal,
	DropdownMenuSeparator,
	DropdownMenuShortcut,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from "src/shared/components/ui/dropdown-menu";
import { InvalidProduct } from "../utils/types";

type StoreColumnProps = (id: number, name: string) => void;

export const useInvalidProductDataColumns = (
	_onOpenDeleteModal?: StoreColumnProps
): ColumnDef<InvalidProduct>[] => {
	const navigate = useNavigate();

	const columns: ColumnDef<InvalidProduct>[] = [
		// {
		// 	accessorKey: "row",
		// 	header: () => <p className="font-bold">Row</p>,
		// 	cell: ({ row }) => {
		// 		return (
		// 			<div className="w-fit px-2 font-bold overflow-hidden text-ellipsis whitespace-nowrap">
		// 				{row.original.row}
		// 			</div>
		// 		);
		// 	},
		// },
		{
			accessorKey: "reason",
			header: ({ column }) => <p className="font-bold">Reasons</p>,
			// ACCORDION
			// cell: ({ row }) => {
			// 	return (
			// 		<Accordion type="single" collapsible>
			// 			<AccordionItem value="item-1" className="border-none min-w-[20em]">
			// 				<AccordionTrigger className="border-b-0">
			// 					View Reasons
			// 				</AccordionTrigger>
			// 				<AccordionContent>
			// 					{row.original.reasons.map((reason, index) => {
			// 						return (
			// 							<div
			// 								className="flex flex-col mb-2 text-red-500"
			// 								key={index}
			// 							>
			// 								&#8226; {reason}
			// 							</div>
			// 						);
			// 					})}
			// 				</AccordionContent>
			// 			</AccordionItem>
			// 		</Accordion>
			// 	);
			// },
			// DROPDOWN
			// cell: ({ row }) => {
			// 	return (
			// 		<DropdownMenu>
			// 			<DropdownMenuTrigger asChild>
			// 				<Button variant="link">View Reasons</Button>
			// 			</DropdownMenuTrigger>
			// 			<DropdownMenuContent
			// 				className="w-[40vw] sm:w-80 max-h-[20em] overflow-auto z-[9999]"
			// 				side="right"
			// 			>
			// 				{/* {row.original.reasons.map((reason, index) => {
			// 					return (
			// 						<div className="flex flex-col mb-1" key={index}>
			// 							<DropdownMenuItem className="text-[0.65rem] lg:text-xs  text-destructive bg-background focus:bg-background focus:text-destructive focus:cursor-default">
			// 								&#8226; {reason}
			// 							</DropdownMenuItem>
			// 						</div>
			// 					);
			// 				})} */}
			// 			</DropdownMenuContent>
			// 		</DropdownMenu>
			// 	);
			// },
			cell: ({ row }) => {
				return <p className="text-destructive">{row.original.reason}</p>;
			},
		},
		{
			accessorKey: "sku",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-full"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<p className="font-bold">SKU</p>
					<FaSort className="h-3 w-3 ml-auto group-hover:cursor-pointer text-gray-400" />
				</button>
			),
			cell: ({ row }) => {
				return (
					<div className="overflow-hidden text-ellipsis whitespace-nowrap">
						{row.getValue("sku")}
					</div>
				);
			},
		},
		{
			accessorKey: "name",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-full"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<p className="font-bold">Name</p>
					<FaSort className="h-3 w-3 ml-auto group-hover:cursor-pointer text-gray-400" />
				</button>
			),
			cell: ({ row }) => {
				const storeName: string = row.getValue("name");
				return (
					<div
						className="w-[20ch] overflow-hidden text-ellipsis whitespace-nowrap"
						title={storeName}
					>
						{row.getValue("name")}
					</div>
				);
			},
		},
		{
			accessorKey: "description",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between items-center w-full"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<p className="font-bold">Description</p>
					<FaSort className="h-3 w-3 ml-auto group-hover:cursor-pointer text-gray-400" />
				</button>
			),
			cell: ({ row }) => {
				return (
					<div className="w-[12em] overflow-hidden text-ellipsis line-clamp-2">
						{row.getValue("description")}
					</div>
				);
			},
		},
		{
			accessorKey: "price",
			header: () => <p className="font-bold">Price</p>,
			cell: ({ row }) => {
				return (
					<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("price")}
					</div>
				);
			},
		},
		{
			accessorKey: "discounted_price",
			header: () => <p className="font-bold">Disc. Price</p>,
			cell: ({ row }) => {
				return (
					<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("discounted_price")}
					</div>
				);
			},
		},
		{
			accessorKey: "category",
			header: () => <p className="font-bold">Category</p>,
			cell: ({ row }) => {
				return (
					<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("category")}
					</div>
				);
			},
		},
		{
			accessorKey: "images",
			header: () => <p className="font-bold">Image Links</p>,
			cell: ({ row }) => {
				return (
					<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("images")}
					</div>
				);
			},
		},
	];

	return columns;
};
