import { useState } from "react";
import { useCustomerColumns } from "./columns";
import { useGetCustomersQuery } from "./slices/customerApiSlice";
import { useNavigate } from "react-router-dom";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import PageControls from "src/shared/components/control/PageControls";
import SearchInput from "src/shared/components/textfield/SearchInput";
import Loading from "src/shared/components/loading-indicator/Loading";

// Icons
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import DesignBackground from "src/shared/components/background/DesignBackground";

const Customer = () => {
	const navigate = useNavigate();
	const { data: customersArray = [], isFetching: isCustomersArrayFetching } =
		useGetCustomersQuery();

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState({
		id: 0,
		name: "",
	});

	const _onOpenDeleteModal = (id: number, name: string) => {
		setIsDeleteDialogOpen(true);
		setSelectedCustomer({
			id: id,
			name: name,
		});
	};

	const columns = useCustomerColumns(_onOpenDeleteModal);

	const _onCloseDeleteModal = () => {
		setIsDeleteDialogOpen(false);
	};

	const _onConfirmDelete = async () => {
		setIsDeleteDialogOpen(false);
	};

	const [searchValue, setSearchValue] = useState("");

	const [isDisabled, setIsDisabled] = useState(true);

	if (isCustomersArrayFetching) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Customer Management</Label>
			</PageHeader>
			<PageControls>
				<Button variant="destructive" size="md" disabled={isDisabled}>
					<ButtonDeleteIconSvg
						fill={`${isDisabled ? "#cbcbcc" : "white"}`}
						className="h-5 w-5 mb-[2px]"
					/>
					Delete
				</Button>
				<div className="flex w-fit ml-auto">
					<SearchInput setSearchValue={setSearchValue} />
				</div>
			</PageControls>

			<div className="mt-8">
				<DataTable data={customersArray} columns={columns} hasPagination />
			</div>

			<ConfirmationDialog
				title="Delete Customer?"
				description={`Customer ${selectedCustomer.name} will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteDialogOpen}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>
		</PageContainer>
	);
};

export default Customer;

// const Customers =
//   useSelector((state: RootState) => state.storeCustomer.customers) || [];
// const toDeleteCustomerLists =
//   useSelector((state: RootState) => state.storeCustomer.customerstoDelete) ||
//   [];
// const filteredCustomers = useSelector(selectFilteredCustomers) || [];
// const searchText = useSelector(
//   (state: RootState) => state.storeCustomer.searchText
// );
// const transactionStatus = useSelector(
//   (state: RootState) => state.storeCustomer.transactionStatus
// );
// const selectedRow = useSelector(
//   (state: RootState) => state.storeCustomer.selectedRow
// );
// const handleSearchTextChange = (
//   event: React.ChangeEvent<HTMLInputElement>
// ) => {
//   dispatch(setSearchText(event.target.value));
// };

// useEffect(() => {
//   dispatch(setCustomerListsToDelete([]));
// }, []);

// const handleDeleteCustomerLists = async () => {
//   await dispatch(deleteMany(toDeleteCustomerLists));
//   await dispatch(setCustomerListsToDelete([]));
//   await Toaster("Successfully Deleted Customers", "success");
// };

// const data = searchText
//   ? filteredCustomers.map((item) => ({
//       ...item,
//       key: item.id,
//     }))
//   : Customers.map((item: any) => ({
//       ...item,
//       key: item.id,
//     }));
// useEffect(() => {
//   dispatch(fetchCustomers());
// }, [dispatch]);

// const rowClassName = (record: any) => {
//   return record.key === selectedRow ? "selected-row" : "";
// };
