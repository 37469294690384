import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreColumns } from "./columns";
import {
	useGetStoresQuery,
	useGetInactiveStoresQuery,
} from "./slices/storeApiSlice";

// Utils
import { _exportTemplate } from "src/shared/helpers/exportFile";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageControls from "src/shared/components/control/PageControls";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import ExportButton from "src/shared/components/button/ExportButton";
import ImportButton from "src/shared/components/button/ImportButton";
import SearchInput from "src/shared/components/textfield/SearchInput";
import TableTabs from "src/shared/components/table-tabs/tableTabs";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";

// Icons
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";

const Store = () => {
	const navigate = useNavigate();
	const [isActiveTable, setIsActiveTable] = useState(true);

	const _onOpenDeleteModal = (id: number, name: string) => {
		setIsDeleteDialogOpen(true);
		setSelectedStore({
			id: id,
			name: name,
		});
	};

	const columns = useStoreColumns(_onOpenDeleteModal);
	const { data: activeStoresArray = [], isFetching: isStoresArrayFetching } =
		useGetStoresQuery();
	const {
		data: inactiveStoresArray = [],
		isFetching: isInactiveStoresArrayFetching,
	} = useGetInactiveStoresQuery();
	const storesArray = isActiveTable ? activeStoresArray : inactiveStoresArray;
	const [searchValue, setSearchValue] = useState("");
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedStore, setSelectedStore] = useState({
		id: 0,
		name: "",
	});

	const handleAddStore = () => {
		navigate(`/store/add`, { replace: true });
	};

	const _onCloseDeleteModal = () => {
		setIsDeleteDialogOpen(false);
	};

	const _onConfirmDelete = async () => {
		setIsDeleteDialogOpen(false);
	};

	if (isStoresArrayFetching || isInactiveStoresArrayFetching) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Store Management</Label>

				<div className="m-0 p-0 h-fit w-fit flex gap-2">
					<ExportButton onClick={() => _exportTemplate("Store")} />
					<ImportButton
						onClick={() => navigate("import")}
						buttonText="Import Store Data"
					/>
				</div>
			</PageHeader>

			<PageControls>
				<div className="flex gap-2 w-full sm:w-[15em]">
					<Button onClick={handleAddStore}>
						<ButtonAddIconSvg fill="#292d32" className="h-6 w-6" />
						Add Store
					</Button>
					<Button variant="destructive" disabled={true}>
						<ButtonDeleteIconSvg fill="#BFC0C2" className="h-4 w-4 mb-[2px]" />
						Delete
					</Button>
				</div>
				<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<SearchInput setSearchValue={setSearchValue} />
				</div>
			</PageControls>
			<TableTabs
				activeDataLength={activeStoresArray.length}
				inactiveDataLength={inactiveStoresArray.length}
				isActiveTable={isActiveTable}
				setIsActiveTable={setIsActiveTable}
			/>

			{isActiveTable && (
				<div className="mt-8">
					<DataTable data={storesArray} columns={columns} hasPagination />
				</div>
			)}

			{!isActiveTable && (
				<div className="mt-8">
					<DataTable data={storesArray} columns={columns} hasPagination />
				</div>
			)}
			<ConfirmationDialog
				title="Delete Store?"
				description={`Store ${selectedStore.name} will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteDialogOpen}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>
		</PageContainer>
	);
};

export default Store;
