import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useValidProductDataColumns } from "../hooks/useValidProductData";
import { useInvalidProductDataColumns } from "../hooks/useInvalidProductData";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";
import * as XLSX from "xlsx";

// Utils
import { ImportProduct, InvalidProduct, Product } from "../utils/types";
import { ImportProductSchema, ProductFormSchema } from "../utils/schema";
import {
	_downloadFile,
	ProductHeaders,
	SheetData,
} from "src/shared/helpers/exportFile";

// Component
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import { Form } from "src/shared/components/form/Form";
import { DataTable } from "src/shared/components/table/DataTable";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";

// Icons
import { LoaderCircle } from "lucide-react";
import DownloadIconSvg from "src/assets/svg/DownloadIconSvg";
import ImportIconSvg from "src/assets/svg/ImportIconSvg";
import ImportDataIcon from "src/assets/svg/ImportDataIconSvg";
import SuccessImportDataIconSvg from "src/assets/svg/SuccessImportDataIconSvg";

const ImportProductForm = () => {
	const navigate = useNavigate();

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const validDataColumns = useValidProductDataColumns();
	const invalidDataColumns = useInvalidProductDataColumns();

	// ? Can be removed if no need for other validation in frontend
	const form = useForm<Product>({
		resolver: zodResolver(ProductFormSchema),
		defaultValues: {
			id: 0,
			sku: 0,
			images: [],
			color: "",
			name: "",
			category: 0,
			description: "",
			price: 0,
			discounted_price: 0,
			size: "",
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty } = form.formState;
	const [fileError, setFileError] = useState("");
	const [data, setData] = useState<SheetData[]>([]);
	const [validData, setValidData] = useState<SheetData[]>([]);
	const [invalidData, setInvalidData] = useState<SheetData[]>([]);
	const [isUploading, setIsUploading] = useState(false);

	const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();

		const file = e.target.files?.[0];
		if (!file) return;

		const filename = file.name;

		if (
			filename.includes("Product_Template") ||
			filename.includes("Invalid_Product")
		) {
			const reader = new FileReader();

			reader.onload = (event) => {
				const workbook = XLSX.read(event.target?.result as string, {
					type: "binary",
				});
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];
				const headers = XLSX.utils.sheet_to_json(sheet, {
					header: 1,
				})[0] as String[];
				const sheetData = XLSX.utils.sheet_to_json<SheetData>(sheet);

				const requiredHeaders = Object.keys(ProductHeaders);

				for (const header of requiredHeaders) {
					if (!headers.includes(header)) {
						setFileError("Headers don't match to template headers.");
						setIsUploading(false);
						return;
					}
				}

				// TODO Call Bulk Import API Here
				// TODO Remove codes below, will be used only for demo

				const headerMap: Record<string, keyof Product> = {
					SKU: "sku",
					Name: "name",
					Description: "description",
					Color: "color",
					Size: "size",
					Price: "price",
					Discounted_Price: "discounted_price",
					Category: "category",
					Image_Link: "images",
				};

				// Remap the sheet data to match schema keys
				const remappedData = sheetData.map((row) => {
					const remappedRow: SheetData = {};
					for (const [excelKey, schemaKey] of Object.entries(headerMap)) {
						if (excelKey in row) {
							remappedRow[schemaKey] = row[excelKey];
						}
					}
					return remappedRow;
				});

				// Validate data and separate valid/invalid rows
				const validRows: SheetData[] = [];
				const invalidRows: InvalidProduct[] = [];

				remappedData.forEach((row, index) => {
					const result = ImportProductSchema.safeParse(row);

					if (result.success) {
						validRows.push(result.data);
					} else {
						let errMsg = "There is an error in";

						console.log(result.error.errors);

						if (result.error.errors.length == 2) {
							const errorFields = result.error.errors.map((err) => {
								const name = String(err.path[0]).replace(/_/g, " ");
								return name.charAt(0).toUpperCase() + name.slice(1);
							});
							errMsg += ` ${errorFields.join(", ")}`;
						} else if (result.error.errors.length >= 3) {
							errMsg =
								"There are multiple issues in this row. Please review all columns thoroughly to ensure accuracy.";
						} else if (result.error.errors.length === 1) {
							errMsg = result.error.errors[0].message;
						}

						const errorMessage = errMsg;

						const invalidRow: InvalidProduct = {
							id: index + 1,
							row: index + 1,
							reason: errorMessage,
							sku: row.sku || "",
							name: row.name || "",
							description: row.description || "",
							price: row.price ?? 0,
							discounted_price: row.discounted_price,
							category: row.category,
							images: row.images || [],
						};

						invalidRows.push(invalidRow);
					}
				});

				setData(remappedData);
				setValidData(validRows);
				setInvalidData(invalidRows);
				setIsUploading(false);
			};

			setIsUploading(true);
			setTimeout(() => {
				reader.readAsBinaryString(file);
			}, 2000);
		} else {
			setFileError("Please make sure to use the template provided.");
			setIsUploading(false);
			return;
		}
	};

	const _onConfirmLeave = () => {
		navigate("/product");

		form.reset();
	};

	const onCancelPress = () => {
		if (!isDirty) navigate("/product");

		setIsLeaveModalOpen(true);
	};

	return (
		<Form {...form}>
			<form>
				<PageContainer>
					<FormHeader>
						<Label variant="title">Import Product Data</Label>
						<div className="w-full h-fit sm:w-fit flex gap-2">
							<Button
								variant="secondary"
								size="md"
								type="reset"
								onClick={onCancelPress}
							>
								Cancel
							</Button>
						</div>
					</FormHeader>

					<SectionContainer>
						<div className="mt-4">
							<Label variant="sub_header">Upload Product Data</Label>
						</div>

						<div className="grid place-items-center px-10 w-full h-[20em] lg:h-[12em] rounded-xl border-[1px] border-gray-300">
							<div className="flex flex-col gap-4 items-center lg:justify-between lg:h-full lg:py-8">
								<Label
									variant="description"
									className="tracking-tight text-center"
								>
									Click the Upload Button and choose a{" "}
									<span className="font-semibold">.xlsx</span> file to import
									store data
								</Label>
								{isUploading ? (
									<LoaderCircle className="h-12 w-12 text-secondary animate-spin" />
								) : (
									<>
										{data.length > 0 ? (
											<SuccessImportDataIconSvg
												fill="currentColor"
												className="h-16 w-16 lg:h-14 lg:w-14"
											/>
										) : (
											<ImportDataIcon
												fill="#292D32"
												className="h-16 w-16 lg:h-14 lg:w-14"
											/>
										)}
									</>
								)}
							</div>
						</div>

						<div className="w-fit h-fit mx-auto">
							<Input
								id="input"
								type="file"
								className="hidden"
								onChange={handleFileUpload}
							/>
							<Button
								type="button"
								onClick={() => {
									setFileError("");
									document.getElementById("input")?.click();
								}}
							>
								<ImportIconSvg fill="currentColor" className="w-5 h-5" />
								Upload File
							</Button>
						</div>
						{fileError && (
							<Label className="text-destructive w-fit mx-auto">
								{fileError}
							</Label>
						)}
					</SectionContainer>

					<div className="flex flex-col gap-4 lg:gap-8 mt-8">
						<Label variant="sub_header">Upload Results</Label>

						<div className="flex flex-col max-w-full gap-4">
							<Label variant="form">Valid Data</Label>

							<div className="w-full">
								<DataTable
									data={validData as ImportProduct[]}
									columns={validDataColumns}
									emptyTableText="No Product Data has been Imported."
								/>
							</div>
						</div>

						<div className="flex flex-col max-w-full gap-4 lg:gap-0">
							<div className="w-full flex justify-between items-center">
								<Label variant="form">Invalid Data</Label>
								<Button
									size="sm"
									variant="secondary"
									className="w-fit"
									disabled={invalidData.length == 0}
									onClick={() =>
										_downloadFile(invalidData as InvalidProduct[], "Product")
									}
								>
									<DownloadIconSvg
										fill={invalidData.length == 0 ? "#cbcbcc" : "white"}
										className="h-5 w-5"
									/>{" "}
									Download File
								</Button>
							</div>

							<div className="w-full">
								<DataTable
									data={invalidData as InvalidProduct[]}
									columns={invalidDataColumns}
									emptyTableText="No Product Data has been Imported."
								/>
							</div>
						</div>
					</div>
				</PageContainer>
			</form>

			<ConfirmationDialog
				title="Leave page?"
				description="Changes are not yet saved."
				confirmButtonLabel="Leave"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>
		</Form>
	);
};
export default ImportProductForm;
