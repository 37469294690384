import { useNavigate } from "react-router-dom";

// Types
import { CustomerWithFullName } from "./utils/types";
import { ColumnDef } from "@tanstack/react-table";

// Components
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";

// Icons
import { FaSort } from "react-icons/fa";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";

type CustomerColumnProps = (id: number, name: string) => void;

export const useCustomerColumns = (
	_onOpenDeleteModal: CustomerColumnProps
): ColumnDef<CustomerWithFullName>[] => {
	const navigate = useNavigate();

	const columns: ColumnDef<CustomerWithFullName>[] = [
		{
			id: "select",
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && "indeterminate")
					}
					onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "name",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Name</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
		},
		{
			accessorKey: "email",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Email</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
		},
		{
			accessorKey: "mobile_no",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Mobile Number</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
		},
		{
			accessorKey: "landline_no",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Landline Number</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
		},
		{
			id: "actions",
			header: () => (
				<div>
					<Label>Action</Label>
				</div>
			),
			cell: ({ row }) => {
				return (
					<div className="flex flex-row gap-3">
						<Button
							variant="icon"
							size="icon"
							onClick={() => navigate(`${row.original.id}`)}
						>
							<TableEditIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
						<Button
							variant="icon"
							size="icon"
							onClick={() =>
								_onOpenDeleteModal(row.original.id, row.original.name)
							}
						>
							<TableDeleteIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
					</div>
				);
			},
		},
	];

	return columns;
};
