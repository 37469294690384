import { forwardRef, HTMLProps, PropsWithChildren } from "react";
import { cn } from "src/lib/utils";

type SectionContainerProps = HTMLProps<HTMLDivElement> & {
	className?: string;
};

const SectionContainer = forwardRef<
	HTMLDivElement,
	PropsWithChildren<SectionContainerProps>
>(({ children, className, ...props }, ref) => {
	return (
		<div
			ref={ref}
			className={cn("grid gap-4 xl:gap-6 w-full h-fit xl:h-full", className)}
			{...props}
		>
			{children}
		</div>
	);
});

export default SectionContainer;
