import { useNavigate } from "react-router-dom";

// Types
import { ColumnDef } from "@tanstack/react-table";
import { Banner } from "./utils/types";

// Utils
import { formatDate } from "src/shared/utils/formatDate";

// Components
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import { Checkbox } from "src/shared/components/ui/checkbox";

// Icons
import { FaSort } from "react-icons/fa";
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";

type InactiveBannerColumnProps = (id: number, name: string) => void;

export const useInactiveBannerColumns = (
	_onOpenDeleteModal: InactiveBannerColumnProps
): ColumnDef<Banner>[] => {
	const navigate = useNavigate();

	const columns: ColumnDef<Banner>[] = [
		{
			id: "select",
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && "indeterminate")
					}
					onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "title",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Title</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
		},
		{
			accessorKey: "image",
			header: () => (
				<div className="text-center">
					<Label>Image</Label>
				</div>
			),
			cell: ({ row }) => {
				const image = row.getValue("image") as string;
				return image ? (
					<div className="h-16 w-[20em] mx-6 2xl:mx-auto 2xl:h-20 2xl:w-[25em] rounded-lg overflow-hidden">
						<img
							src={image}
							alt="banner"
							className="w-full h-full object-cover"
						/>
					</div>
				) : null;
			},
		},
		{
			accessorKey: "description",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">Description</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="max-h-16 max-w-[20em] overflow-hidden text-ellipsis line-clamp-3">
					{row.original.description}
				</div>
			),
		},
		{
			accessorKey: "products",
			header: () => (
				<div>
					<Label>Products</Label>
				</div>
			),
			cell: ({ row }) => (
				<div className="text-center">{row.getValue("products")}</div>
			),
		},
		{
			accessorKey: "from",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">From</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
			cell: ({ row }) => {
				const date = row.getValue("from");
				return <div>{formatDate(date)}</div>;
			},
		},
		{
			accessorKey: "to",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<Label className="group-hover:cursor-pointer">To</Label>
					<FaSort className="h-4 w-4 ml-auto group-hover:cursor-pointer text-gray-400" />
				</Button>
			),
			cell: ({ row }) => {
				const date = row.getValue("to");
				return <div>{formatDate(date)}</div>;
			},
		},
		{
			id: "actions",
			header: () => (
				<div>
					<Label>Action</Label>
				</div>
			),
			cell: ({ row }) => {
				return (
					<div className="flex flex-row gap-3">
						<Button
							variant="icon"
							size="icon"
							onClick={() => navigate(`${row.original.id}`)}
						>
							<TableEditIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
						{/* TRIGGER */}
						<Button
							variant="icon"
							size="icon"
							onClick={() =>
								_onOpenDeleteModal(row.original.id, row.original.title)
							}
						>
							<TableDeleteIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
					</div>
				);
			},
		},
	];

	return columns;
};
