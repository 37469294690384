import { createSlice } from "@reduxjs/toolkit";

type SidebarState = {
  isLargeScreenNavCollapsed: boolean;
  isSmallScreenNavOpen: boolean;
};

const initialState: SidebarState = {
  isLargeScreenNavCollapsed: false,
  isSmallScreenNavOpen: false,
};

const sidebarSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleLargeScreenNav: (state) => {
      state.isLargeScreenNavCollapsed = !state.isLargeScreenNavCollapsed;
    },
    toggleSmallScreenNav: (state) => {
      state.isSmallScreenNavOpen = !state.isSmallScreenNavOpen;
    },
    openLargeScreenNav: (state) => {
      state.isLargeScreenNavCollapsed = false;
    },
    closeSmallScreenNav: (state) => {
      state.isSmallScreenNavOpen = false;
    },
  },
});

export const {
  toggleLargeScreenNav,
  toggleSmallScreenNav,
  openLargeScreenNav,
  closeSmallScreenNav,
} = sidebarSlice.actions;
export default sidebarSlice.reducer;
