import { forwardRef, HTMLProps, PropsWithChildren } from "react";
import { cn } from "src/lib/utils";

type PageControlsProps = HTMLProps<HTMLDivElement> & {
  className?: string;
};

const PageControls = forwardRef<
  HTMLDivElement,
  PropsWithChildren<PageControlsProps>
>(({ children, className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("grid gap-x-2 gap-y-6 w-full sm:grid-cols-2", className)}
      {...props}
    >
      {children}
    </div>
  );
});

export default PageControls;
