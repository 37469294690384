import { FilterUsersProps } from "src/modules/user/utils/types";
import accounts from "../../data/accounts/accounts.json";

export const fetchUsers = async (filters: FilterUsersProps) => {
  const { id, firstName, lastName, email } = filters;

  const filterUsers = accounts.filter((user) => {
    const userFirstName = user.firstName.toLowerCase();
    const userLastName = user.lastName.toLowerCase();
    const userEmail = user.email.toLowerCase();
    const userID = user.id;
    return (
      (!firstName || userFirstName.includes(firstName.toLowerCase())) &&
      (!lastName || userLastName.includes(lastName.toLowerCase())) &&
      (!id || userID) &&
      (!email || userEmail.includes(email.toLowerCase()))
    );
  });
  return filterUsers;
};

export const logginIn = async (email: string, password: string) => {
  const users = accounts.filter(
    (user) => user.email === email && user.password === password
  );
  if (users.length > 0) return Array(users[0]);
  else return [];
};
