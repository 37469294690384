import { z } from "zod";

export const ProductFormSchema = z
	.object({
		sku: z
			.number()
			.min(1, "SKU cannot be empty")
			.max(50, "SKU must be less than 50 characters"),
		images: z
			.array(z.string().url("Each image must be a valid URL"))
			.max(10, "You can provide a maximum of 10 images")
			.optional(),
		colors: z
			.string()
			.regex(
				/^#(?:[0-9a-fA-F]{3}){1,2}$/,
				"Invalid color format (must be a hex code)"
			)
			.optional(),
		name: z
			.string()
			.min(3, "Name must be at least 3 characters long")
			.max(100, "Name must be less than 100 characters"),
		category: z.string().min(1, "Category cannot be empty"),
		description: z
			.string()
			.max(1000, "Description must be less than 1000 characters"),
		variations: z
			.number()
			.int("Variations must be an integer")
			.min(1, "Variations must be at least 1")
			.optional(),
		stocks: z
			.number()
			.int("Stocks must be an integer")
			.nonnegative("Stocks cannot be negative"),
		price: z.coerce.number().nonnegative("Price cannot be negative"),
		discounted_price: z.coerce
			.number()
			.nonnegative("Discounted price cannot be negative"),
		size: z
			.string()
			.regex(/^(XS|S|M|L|XL|XXL)$/, "Size must be one of XS, S, M, L, XL, XXL"),
		startDate: z
			.string()
			.regex(
				/^\d{4}-\d{2}-\d{2}$/,
				"Start date must be in the format YYYY-MM-DD"
			)
			.optional(),
		endDate: z
			.string()
			.regex(/^\d{4}-\d{2}-\d{2}$/, "End date must be in the format YYYY-MM-DD")
			.optional(),
	})
	.refine(
		(data) => {
			return data.discounted_price <= data.price;
		},
		{
			message: "Discounted price cannot exceed the regular price",
			path: ["discounted_price"],
		}
	)
	.refine(
		(data) => {
			if (data.startDate && data.endDate) {
				return new Date(data.endDate) > new Date(data.startDate);
			}
			return true;
		},
		{
			message: "End date must be later than start date",
			path: ["endDate"],
		}
	);

export const ImportProductSchema = z
	.object({
		sku: z.number({
			required_error: "SKU is empty.",
			invalid_type_error: "SKU must be a string.",
		}),
		images: z.string({
			required_error: "Image is empty.",
			invalid_type_error: "Image must be a string.",
		}),
		name: z
			.string({
				required_error: "Product name is empty.",
				invalid_type_error: "Product name must be a string.",
			})
			.min(3, {
				message: "Product name must be at least 3 characters.",
			})
			.max(100, {
				message: "Product name must not exceed 100 characters.",
			})
			.trim(),
		category: z
			.string({
				required_error: "Category is empty.",
				invalid_type_error: "Category must be a string.",
			})
			.min(1, {
				message: "Category cannot be empty.",
			})
			.trim(),
		description: z
			.string({
				required_error: "Description is empty.",
				invalid_type_error: "Description must be a string.",
			})
			.max(1000, {
				message: "Description must not exceed 1000 characters.",
			})
			.trim(),
		price: z.coerce
			.number({
				required_error: "Price is empty.",
				invalid_type_error: "Price must be a number.",
			})
			.nonnegative({
				message: "Price cannot be negative.",
			}),
		discounted_price: z.coerce
			.number({
				required_error: "Discounted price is empty.",
				invalid_type_error: "Discounted price must be a number.",
			})
			.nonnegative({
				message: "Discounted price cannot be negative.",
			}),
	})
	.strict();
