import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

const AuthenticatedRoute = ({ children }: { children: ReactNode }) => {
  const storedToken = localStorage.getItem("accesstoken");

  if (storedToken == null) {
    return <Navigate to="/auth" replace />;
  }

  return <>{children}</>;
};

export default AuthenticatedRoute;
