const LogoutIconSvg = ({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.9677 1.29032C10.9677 0.576613 10.3911 0 9.67742 0C8.96371 0 8.3871 0.576613 8.3871 1.29032V10.3226C8.3871 11.0363 8.96371 11.6129 9.67742 11.6129C10.3911 11.6129 10.9677 11.0363 10.9677 10.3226V1.29032ZM5.14113 4.8629C5.68952 4.40726 5.7621 3.59274 5.30645 3.04435C4.85081 2.49597 4.03629 2.42339 3.4879 2.87903C1.35887 4.65323 0 7.33065 0 10.3226C0 15.6653 4.33468 20 9.67742 20C15.0202 20 19.3548 15.6653 19.3548 10.3226C19.3548 7.33065 17.9919 4.65323 15.8629 2.87903C15.3145 2.42339 14.5 2.5 14.0444 3.04435C13.5887 3.58871 13.6653 4.40726 14.2097 4.8629C15.7782 6.16532 16.7702 8.12903 16.7702 10.3226C16.7702 14.2419 13.5927 17.4194 9.67339 17.4194C5.75403 17.4194 2.57661 14.2419 2.57661 10.3226C2.57661 8.12903 3.57258 6.16532 5.1371 4.8629H5.14113Z"
        fill={fill}
      />
    </svg>
  );
};

export default LogoutIconSvg;
