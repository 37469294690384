import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import userArray from "../../../data/user/users.json";
import { User, UserWithFullName } from "../utils/types";

export const usersApi = createApi({
	reducerPath: "usersApi",
	tagTypes: ["Users", "User"],
	baseQuery: fetchBaseQuery({ baseUrl: "/" }),
	endpoints: (builder) => ({
		getUsers: builder.query<UserWithFullName[], void>({
			async queryFn() {
				return new Promise<{ data: UserWithFullName[] }>((resolve, reject) => {
					try {
						setTimeout(() => {
							const usersWithFullName = userArray.map(
								(i: User, index: number) => {
									return {
										...i,
										name: `${i.firstName} ${i.lastName}`,
									};
								}
							);

							resolve({
								data: usersWithFullName,
							});
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			providesTags: ["Users"],
			keepUnusedDataFor: 0,
		}),
		getSelectedUser: builder.query<User, { id: number }>({
			async queryFn({ id }) {
				return new Promise<{ data: User }>((resolve, reject) => {
					try {
						setTimeout(() => {
							const selectedUser = userArray.find((user) => id === user.id);
							resolve({
								data: selectedUser as User,
							});
						}, 2000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			keepUnusedDataFor: 0,
		}),
		addUser: builder.mutation<void, User>({
			query: (newUser) => ({
				url: "/new",
				method: "POST",
				body: newUser,
			}),
			invalidatesTags: ["Users"],
		}),
		updateUser: builder.mutation<void, User>({
			query: (updatedUser) => ({
				url: `/update/${updatedUser.id}`,
				method: "PUT",
				body: updatedUser,
			}),
			invalidatesTags: ["Users"],
		}),
		deleteUser: builder.mutation<UserWithFullName[], number>({
			async queryFn(id) {
				return new Promise<{ data: UserWithFullName[] }>((resolve, reject) => {
					try {
						const updatedUsersArray = userArray.map((user) => {
							if (user.id == id) return { ...user, is_delete: true };

							return { ...user };
						});

						const usersWithFullName = updatedUsersArray.map((i: User) => {
							return {
								...i,
								name: `${i.firstName} ${i.lastName}`,
							};
						});
						resolve({ data: usersWithFullName });
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			// invalidatesTags: ["Users"], // Invalidate the cache to refresh data
		}),
	}),
});

export const {
	useGetUsersQuery,
	useGetSelectedUserQuery,
	useAddUserMutation,
	useUpdateUserMutation,
	useDeleteUserMutation,
} = usersApi;

// ! Dont delete yet, might use later
// ? Syntax if need to do something after receiving response from api
// getUsers: builder.query<User[], void>({
//   queryFn: async () => {
//     return new Promise<{ data: User[] }>((resolve, reject) => {
//       try {
//         setTimeout(() => {
//           resolve({ data: users as User[] });
//         }, 2000);
//       } catch (e) {
//         reject({
//           error:
//             e instanceof Error ? e.message : "An unknown error occurred",
//         });
//       }
//     });
//   },
//   providesTags: ["Users"],
// }),

// ! Dont delete yet, might use later on api calls when not using data via json
// getUsers: builder.query<User[], void>({
//   query: () => "/",
//   providesTags: ["Users"],
//   transformResponse: (response: { status: string; data: User[] }) => {
//     if (response.status !== "Success") {
//       throw new Error("Failed to fetch users");
//     }
//     return response.data;
//   },
// }),
