const ButtonEditIconSvg = ({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.5 9.63968V11.6663C0.5 11.853 0.646667 11.9997 0.833333 11.9997H2.86C2.94667 11.9997 3.03333 11.9663 3.09333 11.8997L10.3733 4.62634L7.87333 2.12634L0.6 9.39968C0.533333 9.46634 0.5 9.54634 0.5 9.63968ZM12.3067 2.69301C12.3685 2.63133 12.4175 2.55807 12.451 2.47743C12.4844 2.39678 12.5016 2.31032 12.5016 2.22301C12.5016 2.1357 12.4844 2.04924 12.451 1.96859C12.4175 1.88794 12.3685 1.81469 12.3067 1.75301L10.7467 0.19301C10.685 0.131207 10.6117 0.0821755 10.5311 0.0487212C10.4504 0.0152668 10.364 -0.00195312 10.2767 -0.00195312C10.1894 -0.00195312 10.1029 0.0152668 10.0223 0.0487212C9.9416 0.0821755 9.86834 0.131207 9.80667 0.19301L8.58667 1.41301L11.0867 3.91301L12.3067 2.69301Z"
        fill={fill}
      />
    </svg>
  );
};

export default ButtonEditIconSvg;
