const SidebarUserIconSvg = ({
  fill = "none",
  outline = "#292d32",
  className,
}: {
  fill?: string;
  outline?: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.25 13.4465C14.1289 13.4465 13.5898 14.0715 11.75 14.0715C9.91016 14.0715 9.375 13.4465 8.25 13.4465C5.35156 13.4465 3 15.7981 3 18.6965V19.6965C3 20.7317 3.83984 21.5715 4.875 21.5715H18.625C19.6602 21.5715 20.5 20.7317 20.5 19.6965V18.6965C20.5 15.7981 18.1484 13.4465 15.25 13.4465ZM11.75 12.8215C14.8555 12.8215 17.375 10.302 17.375 7.19653C17.375 4.09106 14.8555 1.57153 11.75 1.57153C8.64453 1.57153 6.125 4.09106 6.125 7.19653C6.125 10.302 8.64453 12.8215 11.75 12.8215Z"
        fill={fill}
        strokeWidth="0.3"
      />
      <path
        d="M15.25 13.4464C14.1289 13.4464 13.5898 14.0714 11.75 14.0714C9.91016 14.0714 9.375 13.4464 8.25 13.4464C5.35156 13.4464 3 15.798 3 18.6964V19.6964C3 20.7316 3.83984 21.5714 4.875 21.5714H18.625C19.6602 21.5714 20.5 20.7316 20.5 19.6964V18.6964C20.5 15.798 18.1484 13.4464 15.25 13.4464ZM18.625 19.6964H4.875V18.6964C4.875 16.837 6.39062 15.3214 8.25 15.3214C8.82031 15.3214 9.74609 15.9464 11.75 15.9464C13.7695 15.9464 14.6758 15.3214 15.25 15.3214C17.1094 15.3214 18.625 16.837 18.625 18.6964V19.6964ZM11.75 12.8214C14.8555 12.8214 17.375 10.3019 17.375 7.19641C17.375 4.09094 14.8555 1.57141 11.75 1.57141C8.64453 1.57141 6.125 4.09094 6.125 7.19641C6.125 10.3019 8.64453 12.8214 11.75 12.8214ZM11.75 3.44641C13.8164 3.44641 15.5 5.13 15.5 7.19641C15.5 9.26282 13.8164 10.9464 11.75 10.9464C9.68359 10.9464 8 9.26282 8 7.19641C8 5.13 9.68359 3.44641 11.75 3.44641Z"
        fill="#292D32"
      />
      <path
        d="M18.625 19.8964H18.825V19.6964V18.6964C18.825 16.7266 17.2198 15.1214 15.25 15.1214C15.0748 15.1214 14.886 15.1685 14.6879 15.2273C14.6034 15.2525 14.5191 15.279 14.4306 15.307C14.2975 15.349 14.1549 15.394 13.9874 15.4415C13.4501 15.5939 12.7373 15.7464 11.75 15.7464C10.7706 15.7464 10.0568 15.594 9.51676 15.4414C9.34861 15.3939 9.20507 15.3489 9.07118 15.3069C8.9821 15.279 8.89729 15.2524 8.81235 15.2273C8.61345 15.1685 8.42417 15.1214 8.25 15.1214C6.28017 15.1214 4.675 16.7266 4.675 18.6964V19.6964V19.8964H4.875H18.625ZM11.75 14.2714C12.6967 14.2714 13.3123 14.1098 13.8287 13.95C13.8923 13.9303 13.9539 13.9108 14.0139 13.8919C14.4415 13.7568 14.7912 13.6464 15.25 13.6464C18.038 13.6464 20.3 15.9084 20.3 18.6964V19.6964C20.3 20.6211 19.5497 21.3714 18.625 21.3714H4.875C3.9503 21.3714 3.2 20.6211 3.2 19.6964V18.6964C3.2 15.9084 5.46202 13.6464 8.25 13.6464C8.71098 13.6464 9.06121 13.7571 9.48898 13.8923C9.54856 13.9111 9.60965 13.9304 9.67275 13.95C10.1886 14.1098 10.8033 14.2714 11.75 14.2714ZM17.175 7.19641C17.175 10.1914 14.745 12.6214 11.75 12.6214C8.75499 12.6214 6.325 10.1914 6.325 7.19641C6.325 4.2014 8.75499 1.77141 11.75 1.77141C14.745 1.77141 17.175 4.2014 17.175 7.19641ZM15.7 7.19641C15.7 5.01955 13.9269 3.24641 11.75 3.24641C9.57314 3.24641 7.8 5.01955 7.8 7.19641C7.8 9.37327 9.57314 11.1464 11.75 11.1464C13.9269 11.1464 15.7 9.37327 15.7 7.19641Z"
        fill={outline}
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default SidebarUserIconSvg;
