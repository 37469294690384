import { useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import {
  closeSmallScreenNav,
  openLargeScreenNav,
} from "src/redux/slices/sidebarSlice";

type AppDispatch = ReturnType<typeof useDispatch>;

export const useWindowResize = (breakpoint: number = 1366): void => {
  const dispatch: AppDispatch = useDispatch();

  const handleResize = useCallback(() => {
    if (window.innerWidth > breakpoint) {
      dispatch(closeSmallScreenNav());
    }

    if (window.innerWidth < breakpoint) {
      dispatch(openLargeScreenNav());
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      handleResize();
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
};
