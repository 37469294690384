import React, { forwardRef, PropsWithChildren } from "react";
import { cn } from "src/lib/utils";

type FormHeader = React.HTMLProps<HTMLDivElement> & {
  className?: string;
};

const FormHeader = forwardRef<HTMLDivElement, PropsWithChildren<FormHeader>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        className={cn(
          "w-full mb-6 sm:mb-8 flex flex-col sm:flex-row gap-4 sm:gap-0 sm:justify-between sm:items-center",
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export default FormHeader;
