const TableDeleteIconSvg = ({
  fill,
  className,
}: {
  fill?: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.92 5.63636H16.68V4.54545C16.68 3.87036 16.4145 3.22291 15.9419 2.74555C15.4693 2.26818 14.8283 2 14.16 2H9.84C9.17165 2 8.53068 2.26818 8.05809 2.74555C7.5855 3.22291 7.32 3.87036 7.32 4.54545V5.63636H4.08C3.79357 5.63636 3.51886 5.7513 3.31632 5.95588C3.11379 6.16047 3 6.43795 3 6.72727C3 7.0166 3.11379 7.29408 3.31632 7.49866C3.51886 7.70325 3.79357 7.81818 4.08 7.81818H4.44V20.1818C4.44 20.664 4.62964 21.1265 4.96721 21.4675C5.30477 21.8084 5.76261 22 6.24 22H17.76C18.2374 22 18.6952 21.8084 19.0328 21.4675C19.3704 21.1265 19.56 20.664 19.56 20.1818V7.81818H19.92C20.2064 7.81818 20.4811 7.70325 20.6837 7.49866C20.8862 7.29408 21 7.0166 21 6.72727C21 6.43795 20.8862 6.16047 20.6837 5.95588C20.4811 5.7513 20.2064 5.63636 19.92 5.63636ZM9.48 4.54545C9.48 4.44901 9.51793 4.35652 9.58544 4.28832C9.65295 4.22013 9.74452 4.18182 9.84 4.18182H14.16C14.2555 4.18182 14.347 4.22013 14.4146 4.28832C14.4821 4.35652 14.52 4.44901 14.52 4.54545V5.63636H9.48V4.54545ZM17.4 19.8182H6.6V7.81818H17.4V19.8182ZM10.92 10.7273V16.5455C10.92 16.8348 10.8062 17.1123 10.6037 17.3168C10.4011 17.5214 10.1264 17.6364 9.84 17.6364C9.55357 17.6364 9.27886 17.5214 9.07632 17.3168C8.87379 17.1123 8.76 16.8348 8.76 16.5455V10.7273C8.76 10.4379 8.87379 10.1605 9.07632 9.95588C9.27886 9.7513 9.55357 9.63636 9.84 9.63636C10.1264 9.63636 10.4011 9.7513 10.6037 9.95588C10.8062 10.1605 10.92 10.4379 10.92 10.7273ZM15.24 10.7273V16.5455C15.24 16.8348 15.1262 17.1123 14.9237 17.3168C14.7211 17.5214 14.4464 17.6364 14.16 17.6364C13.8736 17.6364 13.5989 17.5214 13.3963 17.3168C13.1938 17.1123 13.08 16.8348 13.08 16.5455V10.7273C13.08 10.4379 13.1938 10.1605 13.3963 9.95588C13.5989 9.7513 13.8736 9.63636 14.16 9.63636C14.4464 9.63636 14.7211 9.7513 14.9237 9.95588C15.1262 10.1605 15.24 10.4379 15.24 10.7273Z"
        fill={fill}
        strokeWidth="1.8"
      />
    </svg>
  );
};

export default TableDeleteIconSvg;
