const PlatformInstagramIconSvg = ({ className }: { className?: string }) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_1746_8763)">
				<path
					d="M15.9995 12.0057C13.7881 12.0057 12.0052 13.8057 12.0052 16C12.0052 18.1943 13.8052 19.9943 15.9995 19.9943C18.1938 19.9943 19.9938 18.1943 19.9938 16C19.9938 13.8057 18.1938 12.0057 15.9995 12.0057ZM27.9995 16C27.9995 14.3371 27.9995 12.7086 27.9138 11.0457C27.8281 9.12571 27.3824 7.41143 25.9767 6.02286C24.5709 4.61714 22.8738 4.17143 20.9538 4.08571C19.2909 4 17.6624 4 15.9995 4C14.3367 4 12.7081 4 11.0452 4.08571C9.12523 4.17143 7.41094 4.61714 6.02237 6.02286C4.61665 7.42857 4.17094 9.12571 4.08523 11.0457C3.99951 12.7086 3.99951 14.3371 3.99951 16C3.99951 17.6629 3.99951 19.2914 4.08523 20.9543C4.17094 22.8743 4.61665 24.5886 6.02237 25.9771C7.42808 27.3829 9.12523 27.8286 11.0452 27.9143C12.7081 28 14.3367 28 15.9995 28C17.6624 28 19.2909 28 20.9538 27.9143C22.8738 27.8286 24.5881 27.3829 25.9767 25.9771C27.3824 24.5714 27.8281 22.8743 27.9138 20.9543C28.0167 19.3086 27.9995 17.6629 27.9995 16ZM15.9995 22.1543C12.5881 22.1543 9.84523 19.4114 9.84523 16C9.84523 12.5886 12.5881 9.84571 15.9995 9.84571C19.4109 9.84571 22.1538 12.5886 22.1538 16C22.1538 19.4114 19.4109 22.1543 15.9995 22.1543ZM22.4109 11.0286C21.6224 11.0286 20.9709 10.3943 20.9709 9.58857C20.9709 8.78286 21.6052 8.14857 22.4109 8.14857C23.2167 8.14857 23.8509 8.78286 23.8509 9.58857C23.8553 9.77635 23.821 9.96301 23.7502 10.137C23.6795 10.311 23.5737 10.4686 23.4395 10.6C23.3081 10.7342 23.1505 10.8399 22.9765 10.9107C22.8025 10.9815 22.6159 11.0158 22.4281 11.0114L22.4109 11.0286Z"
					fill="#B6B6B6"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1746_8763">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default PlatformInstagramIconSvg;
