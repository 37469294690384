const AuthHideIconSvg = ({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.0909 21L15.2727 17.2273C14.7424 17.3939 14.2085 17.5191 13.6709 17.6027C13.1333 17.6864 12.5764 17.7279 12 17.7273C9.71212 17.7273 7.67424 17.0948 5.88636 15.83C4.09848 14.5652 2.80303 12.9248 2 10.9091C2.31818 10.1061 2.7197 9.3597 3.20455 8.67C3.68939 7.9803 4.24242 7.36303 4.86364 6.81818L2.36364 4.27273L3.63636 3L20.3636 19.7273L19.0909 21ZM12 15C12.1667 15 12.3221 14.9924 12.4664 14.9773C12.6106 14.9621 12.7658 14.9318 12.9318 14.8864L8.02273 9.97727C7.97727 10.1439 7.94697 10.2994 7.93182 10.4436C7.91667 10.5879 7.90909 10.743 7.90909 10.9091C7.90909 12.0455 8.30697 13.0115 9.10273 13.8073C9.89848 14.603 10.8642 15.0006 12 15ZM18.6364 15.4091L15.75 12.5455C15.8561 12.2879 15.9394 12.0267 16 11.7618C16.0606 11.497 16.0909 11.2127 16.0909 10.9091C16.0909 9.77273 15.6933 8.80697 14.8982 8.01182C14.103 7.21667 13.137 6.81879 12 6.81818C11.697 6.81818 11.413 6.84849 11.1482 6.90909C10.8833 6.9697 10.6218 7.06061 10.3636 7.18182L8.04545 4.86364C8.66667 4.60606 9.30303 4.41303 9.95455 4.28455C10.6061 4.15606 11.2879 4.09152 12 4.09091C14.2879 4.09091 16.3258 4.72364 18.1136 5.98909C19.9015 7.25455 21.197 8.89455 22 10.9091C21.6515 11.803 21.193 12.6327 20.6245 13.3982C20.0561 14.1636 19.3933 14.8339 18.6364 15.4091ZM14.4318 11.2273L11.7045 8.5C12.1288 8.42424 12.5191 8.45849 12.8755 8.60273C13.2318 8.74697 13.5385 8.95515 13.7955 9.22727C14.0524 9.49939 14.2382 9.81394 14.3527 10.1709C14.4673 10.5279 14.4936 10.88 14.4318 11.2273Z"
        fill={fill}
      />
    </svg>
  );
};

export default AuthHideIconSvg;
