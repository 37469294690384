import { useNavigate } from "react-router-dom";

import { Product } from "src/modules/product/utils/types";
// Components
import { Button } from "src/shared/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
} from "src/shared/components/ui/dialog";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";

// Image
import product1 from "src/assets/images/product1.png";
import product2 from "src/assets/images/product2.png";

type PreviewProductModalProps = {
	product: Product;
	modalState: boolean;
	modalTrigger: () => void;
};

const PreviewProductModal = ({
	product,
	modalState,
	modalTrigger,
}: PreviewProductModalProps) => {
	const navigate = useNavigate();

	return (
		<Dialog open={modalState} onOpenChange={modalTrigger}>
			<DialogContent className="w-[90%] max-w-[380px] sm:w-full sm:max-w-[460px] lg:w-[35em] lg:max-w-[40em] lg:h-[30em] xl:w-[40em] xl:h-[35em] rounded-xl">
				<DialogHeader className="mt-4 xl:h-1">
					<Label className="w-full text-center xl:text-lg">
						Product Preview
					</Label>
				</DialogHeader>
				<div className="grid grid-cols-5 gap-y-2 gap-x-2 py-4 px-2 max-h-[20em] overflow-auto lg:h-[20em] lg:max-h-[40em] xl:h-full xl:overflow-visible lg:gap-y-4 lg:gap-x-4 pt-0">
					<div className="col-span-5 w-full">
						<div className="flex flex-col gap-1">
							<Label variant="form">Product Images</Label>
							<div className="grid grid-cols-5 gap-x-2">
								{Array.from({ length: 5 }, (_, index) => {
									const productImage = product.images?.[index] || product2;
									return (
										<div
											key={index}
											className="bg-cover bg-center bg-no-repeat aspect-square outline outline-1 rounded-[2px] shadow-inner"
											style={{ backgroundImage: `url('${productImage}')` }}
										/>
									);
								})}
							</div>
						</div>
					</div>
					<div className="col-span-3 h-10 w-full row-start-2 col-start-3 xl:col-span-1 xl:col-start-1">
						<div className="flex flex-col gap-1">
							<Label variant="form">SKU</Label>
							<Input
								disabled
								id="sku"
								value={product.sku}
								className="disabled:text-secondary disabled:opacity-100"
							/>
						</div>
					</div>
					<div className="col-span-5 h-10 w-full xl:col-span-2">
						<div className="flex flex-col gap-1">
							<Label variant="form">Name</Label>
							<Input
								disabled
								id="name"
								value={product.name}
								className="disabled:text-secondary disabled:opacity-100"
							/>
						</div>
					</div>
					<div className="col-span-5 h-10 w-full xl:col-span-2">
						<div className="flex flex-col gap-1">
							<Label variant="form">Category</Label>
							<Input
								disabled
								id="category"
								value={product.category}
								className="disabled:text-secondary disabled:opacity-100"
							/>
						</div>
					</div>
					<div className="col-span-5 h-10 w-full">
						<div className="flex flex-col gap-1">
							<Label variant="form">Description</Label>
							<Input
								disabled
								id="description"
								value={product.description}
								className="disabled:text-secondary disabled:opacity-100"
							/>
						</div>
					</div>
					<div className="col-span-2 row-start-2 h-10 w-full xl:col-span-1 xl:row-start-4">
						<div className="flex flex-col gap-1">
							<Label variant="form">Varations</Label>
							<Input
								disabled
								id="variations"
								value={product.variations}
								className="disabled:text-secondary disabled:opacity-100"
							/>
						</div>
					</div>
					<div className="col-span-5 h-10 w-full xl:col-span-2">
						<div className="flex flex-col gap-1">
							<Label variant="form">Sizes</Label>
							<Input
								disabled
								id="sizes"
								value={product.size}
								className="disabled:text-secondary disabled:opacity-100"
							/>
						</div>
					</div>
					<div className="col-span-5 h-10 w-full xl:col-span-2">
						<div className="flex flex-col gap-1">
							<Label variant="form">Colors</Label>
							{/* <Input disabled id="colors" />  */}

							<div className="flex w-full rounded-none border-b-[1px] border-t-0 border-x-0 border-stone-400 bg-background px-1 pt-1 pb-0 h-5 xl:h-[25px] mt-1 text-[0.65rem] lg:text-sm outline-none tracking-tight whitespace-nowrap text-ellipsis">
								{/* {product.colors?.map((color, index) => {
									return (
							
									);
								})} */}
								<div
									className={`h-3 w-3 rounded-full outline outline-1 ml-1 xl:mt-1`}
									style={{ backgroundColor: product.color }}
								/>
							</div>
						</div>
					</div>
					<div className="col-span-5 h-10 w-full">
						<div className="flex flex-col xl:flex-row gap-y-2 gap-x-2">
							<div className="md:flex-1 w-full h-10">
								<div className="flex flex-col gap-1">
									<Label variant="form">Price</Label>
									<Input
										disabled
										id="price"
										value={product.price}
										className="disabled:text-secondary disabled:opacity-100"
									/>
								</div>
							</div>
							<div className="md:flex-1 w-full h-10">
								<div className="flex flex-col gap-1">
									<Label variant="form">Discounted Price</Label>
									<Input
										disabled
										id="discounted_price"
										value={product.discounted_price}
										className="disabled:text-secondary disabled:opacity-100"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<DialogFooter className="flex flex-row gap-2 w-fit mx-auto">
					<Button
						size="sm"
						className="py-[2px] px-6 h-8"
						onClick={() => navigate(`/product/${product.id}`)}
					>
						Go To Product Details
					</Button>
					<Button
						size="sm"
						variant="secondary"
						className="py-[2px] px-4 h-8"
						onClick={modalTrigger}
					>
						Close
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default PreviewProductModal;
